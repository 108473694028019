import React from 'react'
import Link from 'next/link'
import { StyledAnchorTag, StyledEELink, TSize } from './styles'
import { isNilOrEmpty } from '~common'
import { useExpertEcosystemContext } from '~common/providers'

export interface CustomLinkProps extends React.HTMLAttributes<HTMLAnchorElement> {
  href: string
  type?: 'default' | 'custom'
  size?: TSize
  target?: string
  icon?: React.ReactNode
  rel?: string
  onLinkClick?: () => void
}

export const CustomLink = ({
  href,
  children,
  type = 'default',
  size = 'md',
  icon,
  onLinkClick,
  ...props
}: CustomLinkProps): JSX.Element => {
  const { router } = useExpertEcosystemContext() ?? {}
  const isExternalUrl = href && /^https?:\/\//.test(href)
  const externalLinkProps = isExternalUrl ? { target: '_blank', rel: 'noopener noreferrer' } : {}

  const innerHTMLChildrenWorkaround = icon ? (
    <>
      <span>{children}</span>
      {icon}
    </>
  ) : (
    children
  )

  const ExpertEcosystemLink = router?.Link

  if (!isNilOrEmpty(ExpertEcosystemLink)) {
    const isBasicLink = ['http:', 'https:', 'fax:', 'tel:', 'mailto:'].some((s) => (href ?? '').startsWith(s))
    return isBasicLink ? (
      <StyledAnchorTag
        onClick={(e) => {
          e.stopPropagation()
          onLinkClick && onLinkClick()
        }}
        href={href}
        $size={size}
        $isDefaultStyles={type === 'default'}
        $hasIcon={Boolean(icon)}
        {...props}
        {...externalLinkProps}
      >
        {innerHTMLChildrenWorkaround}
      </StyledAnchorTag>
    ) : (
      <StyledEELink
        as={ExpertEcosystemLink as React.ElementType}
        onClick={(e) => {
          e.stopPropagation()
          onLinkClick && onLinkClick()
        }}
        to={href}
        $size={size}
        $isDefaultStyles={type === 'default'}
        $hasIcon={Boolean(icon)}
        {...props}
        {...externalLinkProps}
      >
        {innerHTMLChildrenWorkaround}
      </StyledEELink>
    )
  }

  return (
    <Link passHref={true} href={href}>
      <StyledAnchorTag
        onClick={(e) => {
          e.stopPropagation()
          onLinkClick && onLinkClick()
        }}
        $size={size}
        $isDefaultStyles={type === 'default'}
        $hasIcon={Boolean(icon)}
        {...props}
        {...externalLinkProps}
      >
        {innerHTMLChildrenWorkaround}
      </StyledAnchorTag>
    </Link>
  )
}
