import styled from 'styled-components'
import { Flex, StyledSection } from '~shared/common'
import { CustomLink } from '~shared/ui/atoms/custom-link'
import { Text } from '~shared/ui/atoms/text'
import { Controlled } from '~shared/ui/controlled'

export const StyledTextButton = styled(CustomLink)`
  position: absolute;
  top: 0;
  right: 0;
`

export const StyledInput = styled(Controlled.Input)`
  > label {
    color: ${({ theme }) => theme.colors.text.textPrimary};
  }
`

export const StyledResetPasswordForm = styled(Flex).attrs({ as: 'form' })`
  background: ${({ theme }) => theme.colors.brand.backgroundSecondary};
  padding: 20px;
  border-radius: 1.5px;
  margin: auto;
  width: 400px;
  display: block;
`

export const StyledCodeInputSection = styled(StyledSection)`
  position: relative;
`

export const StyledPasswordHint = styled(Text)`
  ${({ theme }) => theme.textSizes.desktop.label};
  margin-top: 0.4em;
`
