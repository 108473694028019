import styled, { css } from 'styled-components'
import { browserTruncateMixin } from '~common'

export const StyledDropdown = styled.ul<{ isOpen: boolean; isFixedMaxWidth: boolean }>`
  background: ${({ theme }) => theme.colors.brand.backgroundSecondary};
  max-width: 100%;
  min-width: 10rem;
  position: absolute;
  border: ${({ theme, isOpen }) => `${isOpen ? '1px' : '0px'} solid ${theme.colors.neutrals.neutral10}`};
  overflow-y: scroll;
  transition: max-height 0.48s ease-in-out, border 0.48s ease-in-out;
  max-height: ${({ isOpen }) => (isOpen ? 'calc(2rem * 8.5)' : '0rem')};
  z-index: 998;

  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
    min-width: 15rem;
  }

  ${({ isFixedMaxWidth }) =>
    isFixedMaxWidth
      ? css`
          @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
            max-width: 32rem;
          }
        `
      : css`
          @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
            width: 100%;
          }
        `}
`

export const StyledDropdownItem = styled.li<{ hasNoAction?: boolean; isDisabled?: boolean }>`
  ${({ theme }) => theme.textSizes.desktop.label}
  ${({ hasNoAction }) => hasNoAction && 'padding: 0.5rem'};
  ${browserTruncateMixin};
  color: ${({ hasNoAction, isDisabled, theme }) => {
    if (isDisabled) {
      return theme.colors.states.inactivePrimary
    }
    if (hasNoAction) {
      return theme.colors.text.textPrimary
    }
  }};
`

export const StyledDropdownWithCheckboxesItem = styled(StyledDropdownItem)`
  & > div > label {
    transition: color 0.24s ease-in-out 0.16s, padding 0.24s ease-out 0.16s, background-color 0.24s ease-in-out;
    padding: 0.5rem;
    width: 100%;
    cursor: pointer;
    &:hover {
      background: ${({ theme }) => theme.colors.states.hoverPrimary};
    }
    & > span {
      width: 100%;
      display: block;
      ${browserTruncateMixin};
    }
  }
`

export const StyledDropdownWithLinksItem = styled(StyledDropdownItem)`
  & > a {
    ${browserTruncateMixin};
    padding: 0.5rem;
    width: 100%;
    display: block;
    transition: color 0.24s ease-in-out 0.16s, padding 0.24s ease-out 0.16s, background-color 0.24s ease-in-out;
    &:hover {
      background: ${({ theme }) => theme.colors.states.hoverPrimary};
    }
    &:focus-visible {
      background: ${({ theme }) => theme.colors.states.hoverPrimary};
    }
  }
`

export const StyledDropdownWithOptionsItem = styled(StyledDropdownItem)`
  ${({ theme }) => theme.textSizes.desktop.label};
  padding: 0.5rem;
  width: 100%;
  display: block;
  transition: color 0.24s ease-in-out 0.16s, padding 0.24s ease-out 0.16s, background-color 0.24s ease-in-out;
  & > button {
    width: 100%;
  }
  cursor: ${({ hasNoAction }) => (!hasNoAction ? 'pointer' : 'default')};
  &:hover {
    background: ${({ theme, hasNoAction }) => !hasNoAction && theme.colors.states.hoverPrimary};
  }
  &:focus-visible {
    background: ${({ theme, hasNoAction }) => !hasNoAction && theme.colors.states.hoverPrimary};
  }
`

export const StyledDropdownWithAutocompleteItem = styled(StyledDropdownItem)<{
  isGroupTitle?: boolean
}>`
  ${({ theme, isGroupTitle }) => isGroupTitle && theme.textSizes.desktop.labelBold};
  padding: 0.5rem;
  width: 100%;
  display: block;
  background: ${({ isGroupTitle, theme }) => isGroupTitle && theme.colors.brand.backgroundPrimary};
  cursor: ${({ isGroupTitle }) => !isGroupTitle && 'pointer'};
  transition: color 0.24s ease-in-out 0.16s, padding 0.24s ease-out 0.16s, background-color 0.24s ease-in-out;
  color: ${({ theme, isGroupTitle }) => isGroupTitle && theme.colors.text.textPrimary};
  &:hover {
    background: ${({ theme, isGroupTitle }) => !isGroupTitle && theme.colors.states.hoverPrimary};
  }
  &:focus-visible {
    background: ${({ theme, isGroupTitle }) => !isGroupTitle && theme.colors.states.hoverPrimary};
  }
  & > span {
    color: ${({ theme }) => theme.colors.text.textHighlight};
    font-weight: 600;
  }
`

export const StyledDropdownWithSearchFiltersOptionsItem = styled(StyledDropdownWithAutocompleteItem)`
  ${({ theme }) => theme.textSizes.desktop.label};
  display: ${({ hasNoAction }) => !hasNoAction && 'flex'};
  display: ${({ hasNoAction }) => !hasNoAction && '-ms-flex'};
  align-items: center;
  & > p {
    ${({ theme }) => theme.textSizes.desktop.bodyS};
    margin-right: 0.5rem;
    flex-shrink: 0;
    ${browserTruncateMixin}
  }
`

export const StyledDropdownWithSearchFiltersOptionLabel = styled.p<{ withFilters: boolean }>`
  ${({ withFilters }) =>
    !withFilters &&
    css`
      width: 100%;
    `}
`
