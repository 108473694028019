import styled from 'styled-components'
import { Flex, flexMixin } from '~common'
import { CustomLink } from '~components/ui/atoms/custom-link'
import { Heading } from '~components/ui/atoms/heading'

export const StyledCard = styled(Flex)`
  width: 100%;
  background: ${({ theme }) => theme.colors.brand.backgroundSecondary};
  flex-direction: column;
  transition: all 0.24s ease-in-out;
  margin: 1rem auto;
  padding: 0.5rem;
  cursor: pointer;
  border-left: 0.5rem solid ${({ theme }) => theme.colors.responsive.rankHeaderBackground};
  &:hover {
    background: ${({ theme }) => theme.colors.states.hoverPrimary};
  }
  &:focus-visible {
    background: ${({ theme }) => theme.colors.states.hoverPrimary};
  }

  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.desktopSmall)}) {
    max-width: ${({ theme }) => theme.pxsToEms(1236)};
  }

  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.desktopMedium)}) {
    padding: 1rem;
  }
`

export const StyledCardHeader = styled.header`
  ${flexMixin};
  align-items: flex-start;
`

export const StyledTitle = styled(Heading)`
  ${({ theme }) => theme.textSizes.desktop.buttonL};
  display: inline-block;
`

export const StyledCustomLink = styled(CustomLink)`
  flex-grow: 1;
`
