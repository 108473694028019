import React, { useEffect, useState } from 'react'
import { ButtonWithNoStyle, flexMixin } from '~common'
import { NavigationItem } from '~components/header/navigation-item'
import { UserSvg } from '~common/svgs'
import { paths } from '~helpers'
import styled from 'styled-components'
import { useContext } from 'react'
import { UserContext } from '~contexts/UserContext'

const StyledLatestMyAccountList = styled.ul`
  ${flexMixin}
  flex-direction: column;

  @media (max-width: ${({ theme }) => theme.pxsToEms(600)}) {
    margin-top: 0.625rem;
  }
`

const StyledLatestNavigationItemWithMarginTop = styled(NavigationItem)`
  margin-top: 0.5rem;
  button {
    align-items: flex-start;
  }
`

interface MyAccountListProps {
  isMyAccountListVisible: boolean
  onLogout: () => void
  isTouchable: boolean
}

export const MyAccountList = ({ isMyAccountListVisible, onLogout, isTouchable }: MyAccountListProps): JSX.Element => {
  const [userName, setUserName] = useState('User')
  const { userAttributes } = useContext(UserContext)
  const [changePasswordEnabled, setChangePasswordEnabled] = useState(false)

  useEffect(() => {
    if (userAttributes?.name) setUserName(userAttributes.name)
    else if (userAttributes?.given_name && userAttributes?.family_name)
      setUserName(`${userAttributes.given_name} ${userAttributes.family_name}`)
    else if (userAttributes?.email) setUserName(userAttributes.email)
    setChangePasswordEnabled(!userAttributes?.ssoProvider || false)
  }, [userAttributes])

  return (
    <StyledLatestMyAccountList>
      <NavigationItem hasNoAction={true} isInlineSmall={true} isTouchable={isTouchable}>
        <>
          <UserSvg />
          {userName}
        </>
      </NavigationItem>

      <NavigationItem
        linkTo={paths.zendesk}
        hasIcon={false}
        isInlineSmall={true}
        tabIndex={isMyAccountListVisible ? 0 : -1}
        isTouchable={isTouchable}
      >
        Help center
      </NavigationItem>

      {changePasswordEnabled && (
        <NavigationItem
          linkTo={paths.changePassword}
          hasIcon={false}
          isInlineSmall={true}
          tabIndex={isMyAccountListVisible ? 0 : -1}
          isTouchable={isTouchable}
        >
          Change password
        </NavigationItem>
      )}
      <StyledLatestNavigationItemWithMarginTop hasIcon={false} isInlineSmall={true} isTouchable={isTouchable}>
        <ButtonWithNoStyle type="button" onClick={onLogout} tabIndex={isMyAccountListVisible ? 0 : -1}>
          Sign out
        </ButtonWithNoStyle>
      </StyledLatestNavigationItemWithMarginTop>
    </StyledLatestMyAccountList>
  )
}
