import React, { RefObject } from 'react'
import { Button } from '~components/ui/atoms/button'
import { StyledDropdownWithOptionsItem, StyledDropdown } from '../styles'

export interface DropdownOption {
  id: string
  label: string | JSX.Element
  onClick?: (e: React.UIEvent) => void
}

export interface DropdownWithOptionsProps extends React.HTMLAttributes<HTMLUListElement> {
  isOpen: boolean
  dropdownOptions: DropdownOption[]
  ariaLabel: string
  onSelectOption: (option: DropdownOption) => void
  dropdownTitle?: string | JSX.Element
  isDisabled?: boolean
  dropdownWithOptionsRef?: RefObject<HTMLUListElement>
  actionButton?: {
    text: string
    onClick: () => void
  }
}

export const DropdownWithOptions = ({
  dropdownTitle,
  dropdownOptions,
  ariaLabel,
  isOpen,
  onSelectOption,
  actionButton,
  isDisabled,
  dropdownWithOptionsRef,
  ...rest
}: DropdownWithOptionsProps): JSX.Element => (
  <StyledDropdown
    tabIndex={-1}
    aria-label={ariaLabel}
    aria-hidden={!isOpen}
    isFixedMaxWidth={true}
    isOpen={isOpen}
    ref={dropdownWithOptionsRef}
    {...rest}
  >
    {dropdownTitle && (
      <StyledDropdownWithOptionsItem hasNoAction={true} onClick={(e) => e.stopPropagation()}>
        {dropdownTitle}
      </StyledDropdownWithOptionsItem>
    )}
    {actionButton && (
      <StyledDropdownWithOptionsItem>
        <Button
          variant="link"
          size="sm"
          onClick={(e) => {
            e.stopPropagation()
            actionButton.onClick()
          }}
        >
          {`+ ${actionButton.text}`}
        </Button>
      </StyledDropdownWithOptionsItem>
    )}
    {dropdownOptions?.map((option: DropdownOption) => (
      <StyledDropdownWithOptionsItem
        key={option.id}
        tabIndex={isOpen ? 0 : -1}
        hasNoAction={isDisabled}
        isDisabled={isDisabled}
        onClick={(e) => {
          if (!isDisabled) {
            e.stopPropagation()
            onSelectOption(option)
          }
        }}
        onKeyPress={(e) => {
          e.stopPropagation()
          if (!isDisabled && e.key === 'Enter') {
            onSelectOption(option)
          }
        }}
      >
        {option.label}
      </StyledDropdownWithOptionsItem>
    ))}
  </StyledDropdown>
)
