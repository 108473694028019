import { useSearchParams } from 'react-router-dom'
import { InvalidSSOConfiguration } from '~components/error/invalid-sso-configuration'
import { Logger } from '~helpers/Logger'
import { useEffect, useState } from 'react'

export function LoginWithCode() {
  const [params] = useSearchParams()
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    const error = params.get('error')
    if (error) {
      const errorDescription = params.get('error_description')
      Logger.error(new Error(errorDescription || 'An unknown error occurred during signin process'))
    }
    setIsError(!!error)
  }, [params])

  if (isError) {
    return <InvalidSSOConfiguration />
  }

  return <></>
}
