import { getAllowedRedirectUrls } from './allowedRedirectUrls'

export const paths = {
  home: '/',
  nonSSO: '/nonsso',
  login: '/login',
  products: '/products',
  changePassword: '/changepassword',
  zendesk: '/zendesk',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  logout: '/logout',
}

export const buildUrl = (path: string, params: { id: string; value: string }[]): string => {
  const url = new URL(path, window.location.href)
  for (let param of params) {
    url.searchParams.append(param.id, param.value)
  }
  return url.pathname + url.search.toString()
}

export const isSafeRedirect = (url: string): boolean => {
  const allowedRedirectUrls = getAllowedRedirectUrls()
  return allowedRedirectUrls.some((allowedUrl) => {
    return isSameDomain(url, allowedUrl)
  })
}

export const isSameDomain = (url1: string, url2: string): boolean => {
  try {
    url1 = url1.toLowerCase()
    url2 = url2.toLowerCase()

    if (!/^https?:\/\//i.test(url1)) {
      url1 = `http://${url1}`
    }
    const url1Obj = new URL(url1)

    if (!/^https?:\/\//i.test(url2)) {
      url2 = `http://${url2}`
    }
    const url2Obj = new URL(url2)

    return url1Obj.hostname === url2Obj.hostname
  } catch (error) {
    console.error('Error comparing URL domains:', error)
    return false
  }
}
