import React, { RefObject } from 'react'
import { StyledButton } from './styles'

export type Size = 'xs' | 'sm' | 'md' | 'lg'

export type Variant = 'primary' | 'secondary' | 'tertiary' | 'neutral' | 'link'

export type Position = 'left' | 'right'

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  size: Size
  type?: 'button' | 'submit' | 'reset'
  active?: boolean
  variant?: Variant
  isDisabled?: boolean
  icon?: React.ReactNode
  iconPosition?: Position
  buttonRef?: RefObject<HTMLButtonElement>
}

export const Button: React.FC<ButtonProps> = ({
  size,
  isDisabled,
  icon,
  iconPosition = 'left',
  variant = 'primary',
  children,
  type = 'button',
  active = false,
  buttonRef,
  ...restProps
}) => (
  <StyledButton
    {...restProps}
    size={size}
    active={active}
    variant={variant}
    disabled={isDisabled}
    type={type}
    iconPosition={iconPosition}
    hasChildren={!!children}
    aria-label={!children && !restProps['aria-label'] ? 'action' : restProps['aria-label']}
    ref={buttonRef}
  >
    {icon && icon}
    {children}
  </StyledButton>
)
