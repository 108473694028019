import styled from 'styled-components'
import { Flex } from '~common'
import { PharmaspectraBrandLogo } from '~shared/ui/molecules/brand-logo'

export const ChangePasswordLayout = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  & > a {
    justify-content: center;
    margin-top: 10%;
  }
`

export const ChangePasswordForm = styled(Flex).attrs({ as: 'form' })`
  background: ${({ theme }) => theme.colors.brand.backgroundSecondary};
  padding: 20px;
  border-radius: 1.5px;
  margin: auto;
  margin-top: 1.25rem;
  width: 400px;
  display: block;
`

export const ErrorLabel = styled.p`
  color: #d32f2f;
  &:empty {
    display: none;
  }
`

export const PharmaspectraBrandLogoWithLeftPadding = styled(PharmaspectraBrandLogo)`
  padding-left: 1rem;
`
