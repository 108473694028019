import React from 'react'
import { FormItem } from '~components/ui/atoms/form-item'
import { Badge, BadgeProps } from '~components/ui/atoms/badge'
import { StyledHiddenRadio, StyledRadioLabel, StyledTooltip } from './styles'

export type RadioContainedButtonProps = React.HTMLAttributes<HTMLInputElement> &
  Omit<BadgeProps, 'isSelected'> & {
    id: string
    label: string
    isChecked?: boolean
    value?: string
    name?: string
    isDisabled?: boolean
    hideTooltipOnDisabled?: boolean
  }

export const RadioContainedButton = ({
  id,
  name,
  label,
  style,
  value,
  isChecked = false,
  onChange,
  isDisabled = false,
  hideTooltipOnDisabled = false,
  rounded,
  icon,
  tabIndex,
  iconPosition,
  ...rest
}: RadioContainedButtonProps): JSX.Element => (
  <FormItem formItemSize="md" htmlFor={`radio-contained-button-${id}`} style={style} {...rest}>
    <StyledRadioLabel isChecked={isChecked} isDisabled={isDisabled} aria-labelledby={`radio-contained-button-${id}`}>
      <StyledHiddenRadio
        checked={isChecked}
        type="radio"
        value={value}
        name={name}
        onChange={onChange}
        disabled={isDisabled}
        aria-label={name}
        tabIndex={tabIndex}
      />
      {isDisabled ? (
        <StyledTooltip content={hideTooltipOnDisabled ? '' : 'Coming soon'}>
          <Badge
            isSelected={isChecked}
            isDisabled={isDisabled}
            rounded={rounded}
            icon={icon}
            iconPosition={iconPosition}
            className={rest.className}
          >
            {label}
          </Badge>
        </StyledTooltip>
      ) : (
        <Badge
          isSelected={isChecked}
          isDisabled={isDisabled}
          rounded={rounded}
          icon={icon}
          iconPosition={iconPosition}
          className={rest.className}
        >
          {label}
        </Badge>
      )}
    </StyledRadioLabel>
  </FormItem>
)
