import React from 'react'
import { AmbulanceSvg } from '~common/svgs'
import { StyledErrorPageBlock } from './styles'

export const ServerError = (): JSX.Element => (
  <StyledErrorPageBlock
    errorMessage="We're having trouble loading this content right now, please try refreshing in a few moments"
    icon={<AmbulanceSvg />}
  />
)
