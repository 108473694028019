import React from 'react'
import { StyledBadge, StyledFlex, StyledChildren } from './styles'

type Rounded = 'allaround' | 'left' | 'right'
export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  isSelected: boolean
  isDisabled?: boolean
  rounded?: Rounded
  icon?: React.ReactNode
  iconPosition?: 'left' | 'right'
}

export const Badge: React.FC<BadgeProps> = ({
  children,
  isSelected,
  isDisabled,
  rounded = 'allaround',
  icon,
  iconPosition = 'left',
  ...rest
}): JSX.Element => (
  <StyledBadge isSelected={isSelected} isDisabled={isDisabled} tabIndex={0} rounded={rounded} {...rest}>
    {icon ? (
      <StyledFlex>
        {iconPosition === 'left' && icon}
        <StyledChildren iconPosition={iconPosition}>{children}</StyledChildren>
        {iconPosition === 'right' && icon}
      </StyledFlex>
    ) : (
      children
    )}
  </StyledBadge>
)
