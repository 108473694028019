import React from 'react'
import { StyledDivider } from './styles'
import { TBorderColor, TBorderDirection, TDividerType } from './types'

export interface DividerProps {
  isWithMargin?: boolean
  borderType?: TDividerType
  borderColor?: TBorderColor
  asTag?: string
  borderDirection?: TBorderDirection
  className?: string
}

export const Divider = ({
  borderType = 'solid',
  borderColor = 'muted',
  borderDirection = 'horizontal',
  className,
  isWithMargin = true,
}: DividerProps): JSX.Element => (
  <StyledDivider
    as={(borderDirection === 'horizontal' ? 'hr' : 'div') as never}
    borderType={borderType}
    borderColor={borderColor}
    aria-hidden={true}
    borderDirection={borderDirection}
    isWithMargin={isWithMargin}
    className={className}
  />
)
