import React from 'react'
import { MissingFileSvg } from '~common/svgs'
import { ErrorPageBlock } from '~components/ui/molecules/error-page-block'

export const NoProducts: React.FC = () => (
  <ErrorPageBlock
    errorTitle="No Products"
    errorMessage="There are no products associated with your account, please contact your account manager"
    icon={<MissingFileSvg />}
  />
)
