import * as React from 'react'
import { useContext } from 'react'
import { ProductList } from '~components/product-list'
import { Header } from '~components/header'
import usePageTitle from '~hooks/use-page-title'
import { UserContext } from '~contexts/UserContext'

const regeneronClientId = 51

export function Products() {
  usePageTitle('Pharmaspectra')
  const { clientId } = useContext(UserContext)

  const isRegeneronClient = clientId === regeneronClientId

  return (
    <>
      <Header isRegeneronClient={isRegeneronClient} />
      <ProductList isRegeneronClient={isRegeneronClient} />
    </>
  )
}
