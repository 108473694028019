import React, { useEffect, useState } from 'react'
import useGoogleAnalytics from '~hooks/use-google-analytics'
import { useLocation } from 'react-router-dom'
import { useContext } from 'react'
import { UserContext } from '~contexts/UserContext'

export interface IGoogleAnalytics {
  clientId?: number
  children: any
}

export function GoogleAnalytics(props: IGoogleAnalytics) {
  const { children } = props

  const [currentLocation, setCurrentLocation] = useState('')

  const location = useLocation()

  const { clientId } = useContext(UserContext)

  const googleAnalytics = useGoogleAnalytics({ clientId })

  useEffect(() => {
    if (currentLocation !== location.pathname) {
      setCurrentLocation(location.pathname)
      googleAnalytics.setConfig({
        page_path: location.pathname,
        page_location: global.location.href,
      })
      googleAnalytics.trackEvent({ name: 'pageview' })
    }
  }, [location, currentLocation, googleAnalytics])

  return <>{children}</>
}
