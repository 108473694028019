import React from 'react'
import { Controller } from 'react-hook-form'
import { Select, SelectWithSearchFiltersOptionsDropdownProps } from '~components/ui/molecules/select'
import { DropdownSearchFiltersOption } from '~components/ui/molecules/dropdown'
import { ControlledProps } from '../types'
import { SelectError } from './styles'

export interface ControlledSelectWithSearchFiltersOptionsDropdownProps<TFormValues>
  extends ControlledProps<TFormValues>,
    SelectWithSearchFiltersOptionsDropdownProps {
  defaultSelectValue?: DropdownSearchFiltersOption
}

export const ControlledSelectWithSearchFiltersOptionsDropdown = <TFormValues extends Record<string, unknown>>({
  rules,
  control,
  name,
  options,
  errors,
  defaultSelectValue,
  ...selectProps
}: ControlledSelectWithSearchFiltersOptionsDropdownProps<TFormValues>): JSX.Element => (
  <Controller
    rules={rules}
    control={control}
    name={name}
    defaultValue={defaultSelectValue}
    render={({ field }) => (
      <>
        <Select.WithSearchFiltersOptionsDropdown
          {...selectProps}
          options={options}
          onChange={field.onChange}
          onBlur={field.onBlur}
          value={options.find((option) => field.value === option.id || defaultSelectValue)}
        />
        {errors?.[name] && <SelectError>{errors?.[name]?.message}</SelectError>}
      </>
    )}
  />
)
