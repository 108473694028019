import * as React from 'react'
import { useCallback } from 'react'
import { useCheckInmemeRegister } from '~hooks/use-check-inmeme-register'
import { useConfig } from '~hooks/use-config'
import { StyledButton } from './styles'

export function InmemeRegisterButton() {
  const { isAllowed } = useCheckInmemeRegister()
  const { INSIGHTMEME_URL } = useConfig()

  const onInmemeRegisterRequested = useCallback(() => {
    if (isAllowed) {
      window.location.href = `${INSIGHTMEME_URL}/register.aspx`
    }
  }, [isAllowed, INSIGHTMEME_URL])

  return (
    <>
      {isAllowed && (
        <StyledButton data-testid="inmeme-register" onClick={onInmemeRegisterRequested} size="sm" variant="secondary">
          Register to Insightmeme
        </StyledButton>
      )}
    </>
  )
}
