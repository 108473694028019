import * as React from 'react'
import { PharmaspectraBrandLogo } from '~shared/ui/molecules/brand-logo'
import { RequestDemo } from '~components/request-demo'
import { LoginTemplateContainer, Column, StyledHeader, Row } from './styles'

export interface ILoginTemplateProps {
  children: any
}

export function LoginTemplate(props: ILoginTemplateProps) {
  return (
    <LoginTemplateContainer>
      <Row>
        <Column>
          <StyledHeader isExpanded={false}>
            <PharmaspectraBrandLogo isTouchable={false} logoSize="lg" logoVariant="primary" withNextLink={false} />
          </StyledHeader>
        </Column>
        <Column></Column>
        <Column>{props.children}</Column>
        <Column>
          <RequestDemo />
        </Column>
      </Row>
    </LoginTemplateContainer>
  )
}
