import React from 'react'
import { ErrorBoundary as ErrorBoundaryComponent } from 'react-error-boundary'
import { datadogRum } from '@datadog/browser-rum'
import { ServerError } from './server-error'

export const ErrorBoundary = ({ children }: { children: React.ReactNode }): JSX.Element => (
  <ErrorBoundaryComponent onError={(err, info) => datadogRum.addError(err, info)} FallbackComponent={ServerError}>
    {children}
  </ErrorBoundaryComponent>
)
