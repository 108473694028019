import React from 'react'
import { Link } from 'react-router-dom'

type TSize = 'sm' | 'md'

export interface NavigationLinkProps extends React.HTMLAttributes<HTMLAnchorElement> {
  href: string
  type?: 'default' | 'custom'
  size?: TSize
  target?: string
  rel?: string
}

export const NavigationLink = ({
  href,
  children,
  type = 'default',
  size = 'md',
  target,
  ...props
}: NavigationLinkProps): JSX.Element => (
  <Link to={href} target={target} {...props}>
    {children}
  </Link>
)
