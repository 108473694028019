import React from 'react'
import { ThemeProvider } from 'styled-components'
import { Mode } from '~common/types'

export const sosvProductColours = [
  '#39A34F',
  '#95A8FB',
  '#819CFE',
  '#6B90FF',
  '#5181FF',
  '#3074FF',
  '#0269EE',
  '#035ED4',
  '#0451B9',
  '#0348A0',
  '#0A3C87',
  '#0F316F',
]

export const sosvIqviaProductColours = [
  '#00C221',
  '#ACD5E5',
  '#80C8E4',
  '#5FBFE3',
  '#40B6E2',
  '#21ACE1',
  '#01A3E0',
  '#0195D0',
  '#0186BF',
  '#0179AE',
  '#016B9E',
  '#005684',
]

export type ThemeBrand = 'iqvia' | 'pharmaspectra'

export const getSoSVProductColors = (brand: ThemeBrand): string[] => {
  const sosvColours = {
    pharmaspectra: sosvProductColours,
    iqvia: sosvIqviaProductColours,
  }
  return sosvColours[brand]
}

export const breakPoints = {
  phoneSmall: 320,
  phoneMedium: 400,
  tabletSmall: 640,
  tabletMedium: 768,
  tabletLarge: 960,
  tabletLarger: 1024,
  desktopSmall: 1280,
  desktopMedium: 1600,
  desktopLarge: 2372,
}

export const textSizes = {
  desktop: {
    h1: {
      fontSize: '1.75rem',
      lineHeight: '2.5rem',
      fontWeight: 500,
    },
    h2: {
      fontSize: '1.5rem',
      lineHeight: '2rem',
      fontWeight: 500,
    },
    h3: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: 600,
    },
    h4: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      fontWeight: 600,
    },
    statCallout: {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
      fontWeight: 700,
    },
    bodyS: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      fontWeight: 400,
    },
    body: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: 400,
    },
    bodyL: {
      fontSize: '1.125rem',
      lineHeight: '1.75rem',
      fontWeight: 500,
    },
    buttonS: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
      fontWeight: 500,
    },
    buttonM: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      fontWeight: 500,
    },
    buttonL: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: 500,
    },
    subscription: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      fontWeight: 400,
    },
    placeholderS: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
      fontWeight: 400,
    },
    placeholderL: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      fontWeight: 400,
    },
    navigation: {
      fontSize: '0.875rem',
      lineHeight: '1rem',
      fontWeight: 600,
    },
    searchStrong: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
      fontWeight: 500,
    },
    label: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
      fontWeight: 400,
    },
    labelBold: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
      fontWeight: 600,
    },
    labelBoldWithHighlight: {
      fontSize: '0.75rem',
      lineHeight: '1.25rem',
      fontWeight: 600,
    },
    pill: {
      fontSize: '0.75rem',
      lineHeight: '0.75rem',
      fontWeight: 500,
    },
    dataAxis: {
      fontSize: '0.625rem',
      lineHeight: '1rem',
      fontWeight: 400,
    },
    rankXs: {
      fontSize: '0.875rem',
      lineHeight: '0.75rem',
      fontWeight: 700,
    },
    rankS: {
      fontSize: '1rem',
      lineHeight: '0.75rem',
      fontWeight: 700,
    },
    rankM: {
      fontSize: '1.25rem',
      lineHeight: '1rem',
      fontWeight: 700,
    },
    rankL: {
      fontSize: '2rem',
      lineHeight: '1.5rem',
      fontWeight: 700,
    },
  },
  mobile: {
    h1: {
      fontSize: '1.3125rem',
      lineHeight: '2rem',
      fontWeight: 500,
    },
    h2: {
      fontSize: '1.3125rem',
      lineHeight: '2rem',
      fontWeight: 500,
    },
    navigation: {
      fontSize: '0.625rem',
      lineHeight: '1rem',
      fontWeight: 600,
    },
  },
}

const iqviaTextSizes = {
  ...textSizes,
  desktop: {
    ...textSizes.desktop,
    h3: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: 400,
    },
    statCallout: {
      fontSize: '1.4rem',
      lineHeight: '1.5rem',
      fontWeight: 500,
    },
  },
}

export const lightModeColors = {
  always: {
    alwaysLight: '#F9F8F7',
    alwaysOverlay: 'rgba(0, 0, 0, 0.4)',
  },
  badges: {
    badgeBackground: '#F9F8F7',
    badgeBackgroundActive: '#5A6BAD',
    badgeBackgroundHover: '#394B84',
  },
  buttons: {
    link: '#5A6BAD',
    textLinkActive: '#242220',
    buttonBackgrondPrimary: '#007D26',
    buttonBackgroundPrimaryHover: '#006A20',
    buttonBackgroundPrimaryActive: '#005E1D',
    buttonBackgroundSecondary: '#FFFFFF',
    buttonBackgroundNeutral: '#FFFFFF',
    buttonBackgroundTertiary: '#F5F7FF',
    buttonBackgroundTertiaryHover: '#E5EAFF',
    buttonBackgroundTertiaryActive: '#CDD5FF',
  },
  brand: {
    bluePrimary: '#394B84',
    blueSecondary: '#5A6BAD',
    purplePrimary: '#501042',
    purpleSecondary: '#F6E9F3',
    backgroundPrimary: '#F6F7FB',
    backgroundSecondary: '#FFFFFF',
    backgroundTertiary: '#F2F2F2',
  },
  detail: {
    dividerPrimary: '#D9A94D',
    searchHighlight: '#E7C7E0',
  },
  dataVisualisation: {
    tooltipBackground: '#3D3B37',
    regressionUp: '#006A20',
    regressionDown: '#D32F2F',
    regressionStatic: '#67625D',
    regressionSearch: '#805400',
    barGraph: '#39A34F',
    barGraphSecondary: '#D9A94D',
    lineGraphFill: '#E5EAFF',
    lineGraphAccent: '#99AAFF',
    lineGraphSearch: '#A36B01',
    clinicalPhase0: '#007D26',
    clinicalPhase1: '#72C77D',
    clinicalPhase2: '#FFC75B',
    clinicalPhase3: '#E24240',
    clinicalPhase4: '#A02084',
    clinicalPhaseNa: '#BAB2A8',
    scale20: '#B858A3',
    scale40: '#A02084',
    scale60: '#781863',
    scale80: '#501042',
    scale100: '#10030D',
  },
  forms: {
    iconOnPrimary: '#F9F8F7',
    iconOnInactive: '#67625D',
    checkboxBackground: '#FFFFFF',
  },
  loading: {
    loadingPrimary: '#5A6BAD',
    loadingSecondary: '#99AAFF',
    loadingTertiary: '#E5EAFF',
  },
  navigation: {
    navPrimary: '#192B5A',
    navSecondary: '#394B84',
    crumbBackground: '#394B84',
    iconOnNav: '#CDD5FF',
    navHover: '#5A6BAD',
  },
  neutrals: {
    neutral100: 'rgba(0,0,0,1)',
    neutral90: 'rgba(0,0,0,0.9)',
    neutral80: 'rgba(0,0,0,0.8)',
    neutral60: 'rgba(0,0,0,0.6)',
    neutral40: 'rgba(0,0,0,0.4)',
    neutral20: 'rgba(0,0,0,0.2)',
    neutral10: 'rgba(0,0,0,0.1)',
    neutral5: 'rgba(0,0,0,0.05)',
    neutral0: 'rgba(0,0,0,0)',
  },
  pill: {
    pillBackgroundPrimary: '#394B84',
    pillBackgroundSecondary: '#5A6BAD',
    pillBackgroundHover: '#273560',
    pillInactive: '#3D3B37',
    linkOnPill: '#CDD5FF',
  },
  responsive: {
    textOnRankHeader: '#F9F8F7',
    successOnRankHeader: '#72C77D',
    regressionOnRankHeader: '#EF5350',
    neutralOnRankHeader: '#DDD9D4',
    rankHeaderBackground: '#394B84',
  },
  states: {
    hoverPrimary: '#EFF2FF',
    hoverAccent: '#5A6BAD',
    active: '#E5EAFF',
    activeAccent: '#394B84',
    visited: '#273560',
    inactivePrimary: '#BAB2A8',
    inactiveSecondary: '#EEECE9',
    inactiveTertiary: '#67625D',
  },
  social: {
    twitterPrimary: '#1DA1F2',
    linkedinPrimary: '#2867B2',
  },
  text: {
    textPrimary: '#3D3B37',
    textSecondary: '#67625D',
    textHighlight: '#5A6BAD',
    textHoverPrimary: '#781863',
    textHoverSecondary: '#E7C7E0',
  },
  textOn: {
    textOnPrimary: '#F9F8F7',
    textOnPill: '#FFFFFF',
    textOnInversePrimary: '#F9F8F7',
    textOnInverseSecondary: '#DDD9D4',
    textOnButtonPrimary: '#F9F8F7',
    textOnButtonSecondary: '#007D26',
    textOnButtonNeutral: '#242220',
    textOnButtonNeutralHover: '#131211',
    textOnButtonNeutralActive: '#000000',
    textOnButtonTertiary: '#5A6BAD',
    textOnButtonTertiaryHover: '#5A6BAD',
    textOnSearchHighlight: '#3D3B37',
    textOnCrumb: '#CDD5FF',
    textOnButtonTertiaryActive: '#394B84',
  },
  warnings: {
    success: '#007D26',
    successBackground: '#B9E4BF',
    warning: '#FFC75B',
    warningBackground: '#FFF1D6',
    alert: '#D32F2F',
    alertBackground: '#FBD4D3',
  },
}

export const lightModeIqviaColors = {
  always: {
    alwaysLight: '#F5F5F5',
    alwaysOverlay: 'rgba(163, 168, 172, 0.4)',
  },
  badges: {
    badgeBackground: '#F9F8F7',
    badgeBackgroundActive: '#595959',
    badgeBackgroundHover: '#444444',
  },
  buttons: {
    link: '#0768FD',
    textLinkActive: '#0557D5',
    buttonBackgrondPrimary: '#0768FD',
    buttonBackgroundPrimaryHover: '#0557D5',
    buttonBackgroundPrimaryActive: '#0768FD',
    buttonBackgroundSecondary: '#FFFFFF',
    buttonBackgroundNeutral: '#FFFFFF',
    buttonBackgroundTertiary: '#CDDDF7',
    buttonBackgroundTertiaryHover: '#9BBCEE',
    buttonBackgroundTertiaryActive: '#9BBCEE',
  },
  brand: {
    bluePrimary: '#0557D5',
    blueSecondary: '#0768FD',
    purplePrimary: '#A1418B',
    purpleSecondary: '#E2BED8',
    backgroundPrimary: '#F8F9FB',
    backgroundSecondary: '#FFFFFF',
    backgroundTertiary: '#EEEEEE',
  },
  detail: {
    dividerPrimary: '#D9D9D9',
    searchHighlight: '#CDDDF7',
  },
  dataVisualisation: {
    tooltipBackground: '#2C3A43',
    regressionUp: '#027123',
    regressionDown: '#DA2A1D',
    regressionStatic: '#2C3A43',
    regressionSearch: '#805400',
    barGraph: '#10558A',
    barGraphSecondary: '#639BF3',
    lineGraphFill: '#ECF3FF',
    lineGraphAccent: '#ECF3FF',
    lineGraphSearch: '#A36B01',
    clinicalPhase0: '#027123',
    clinicalPhase1: '#43AF2B',
    clinicalPhase2: '#F0B323',
    clinicalPhase3: '#DA2A1D',
    clinicalPhase4: '#A1418B',
    clinicalPhaseNa: '#969CA1',
    scale20: '#B858A3',
    scale40: '#A02084',
    scale60: '#781863',
    scale80: '#501042',
    scale100: '#10030D',
  },
  forms: {
    iconOnPrimary: '#F5F5F5',
    iconOnInactive: '#303030',
    checkboxBackground: '#FFFFFF',
  },
  loading: {
    loadingPrimary: '#4080A5',
    loadingSecondary: '#BDE8F7',
    loadingTertiary: '#80D0EE',
  },
  navigation: {
    navPrimary: '#250056',
    navSecondary: '#0557D5',
    crumbBackground: '#0557D5',
    iconOnNav: '#F0F4FE',
    navHover: '#0768FD',
  },
  neutrals: {
    neutral100: 'rgba(0,0,0,1)',
    neutral90: 'rgba(0,0,0,0.9)',
    neutral80: 'rgba(0,0,0,0.8)',
    neutral60: 'rgba(0,0,0,0.6)',
    neutral40: 'rgba(0,0,0,0.4)',
    neutral20: 'rgba(0,0,0,0.2)',
    neutral10: 'rgba(0,0,0,0.1)',
    neutral5: 'rgba(0,0,0,0.05)',
    neutral0: 'rgba(0,0,0,0)',
  },
  pill: {
    pillBackgroundPrimary: '#0768FD',
    pillBackgroundSecondary: '#0768FD',
    pillBackgroundHover: '#0557D5',
    pillInactive: '#616161',
    linkOnPill: '#CDDDF7',
  },
  responsive: {
    textOnRankHeader: '#F5F5F5',
    successOnRankHeader: '#00C221',
    regressionOnRankHeader: '#E20000',
    neutralOnRankHeader: '#C9CED0',
    rankHeaderBackground: '#0557D5',
  },
  states: {
    hoverPrimary: '#CDDDF7',
    hoverAccent: '#9BBCEE',
    active: '#E6EEFB',
    activeAccent: '#699AE6',
    visited: '#699AE6',
    inactivePrimary: '#999999',
    inactiveSecondary: '#D9D9D9',
    inactiveTertiary: '#444444',
  },
  social: {
    twitterPrimary: '#1DA1F2',
    linkedinPrimary: '#2867B2',
  },
  text: {
    textPrimary: '#444444',
    textSecondary: '#595959',
    textHighlight: '#0768FD',
    textHoverPrimary: '#0557D5',
    textHoverSecondary: '#CDDDF7',
  },
  textOn: {
    textOnPrimary: '#F2F2F2',
    textOnPill: '#FFFFFF',
    textOnInversePrimary: '#F5F5F5',
    textOnInverseSecondary: '#C9CED0',
    textOnButtonPrimary: '#FFFFFF',
    textOnButtonSecondary: '#00C221',
    textOnButtonNeutral: '#595959',
    textOnButtonNeutralHover: '#444444',
    textOnButtonNeutralActive: '#000000',
    textOnButtonTertiary: '#0557D5',
    textOnButtonTertiaryHover: '#0768FD',
    textOnSearchHighlight: '#000000',
    textOnCrumb: '#CDD5FF',
    textOnButtonTertiaryActive: '#0557D5',
  },
  warnings: {
    success: '#00C221',
    successBackground: '#CCF3D3',
    warning: '#FF9300',
    warningBackground: '#FFE9CC',
    alert: '#E20000',
    alertBackground: '#F9CCCC',
  },
}

export const darkModeColors = {
  always: {
    alwaysLight: '#F9F8F7',
    alwaysOverlay: 'rgba(0, 0, 0, 0.2)',
  },
  badges: {
    badgeBackground: '#242220',
    badgeBackgroundActive: '#E5EAFF',
    badgeBackgroundHover: '#F5F7FF',
  },
  buttons: {
    link: '#CDD5FF',
    textLinkActive: '#F9F8F7',
    buttonBackgrondPrimary: '#72C77D',
    buttonBackgroundPrimaryHover: '#96D59E',
    buttonBackgroundPrimaryActive: '#B9E4BF',
    buttonBackgroundSecondary: '#000000',
    buttonBackgroundNeutral: '#000000',
    buttonBackgroundTertiary: '#F5F7FF',
    buttonBackgroundTertiaryHover: '#5A6BAD',
    buttonBackgroundTertiaryActive: '#394B84',
  },
  brand: {
    bluePrimary: '#192B5A',
    blueSecondary: '#F5F7FF',
    purplePrimary: '#781863',
    purpleSecondary: '#F6E9F3',
    backgroundPrimary: '#242220',
    backgroundSecondary: '#000000',
    backgroundTertiary: '#343434',
  },
  detail: {
    dividerPrimary: '#D9A94D',
    searchHighlight: '#781863',
  },
  dataVisualisation: {
    tooltipBackground: '#EEECE9',
    regressionUp: '#006A20',
    regressionDown: '#B32828',
    regressionStatic: '#EEECE9',
    regressionSearch: '#FFE4AE',
    barGraph: '#39A34F',
    barGraphSecondary: '#D9A94D',
    lineGraphFill: '#394B84',
    lineGraphAccent: '#99AAFF',
    lineGraphSearch: '#A36B01',
    clinicalPhase0: '#007D26',
    clinicalPhase1: '#72C77D',
    clinicalPhase2: '#FFC75B',
    clinicalPhase3: '#E24240',
    clinicalPhase4: '#A02084',
    clinicalPhaseNa: '#BAB2A8',
    scale20: '#D090C2',
    scale40: '#B858A3',
    scale60: '#A02084',
    scale80: '#781863',
    scale100: '#501042',
  },
  forms: {
    iconOnPrimary: '#E5EAFF',
    iconOnInactive: '#67625D',
    checkboxBackground: '#131211',
  },
  loading: {
    loadingPrimary: '#5A6BAD',
    loadingSecondary: '#99AAFF',
    loadingTertiary: '#E5EAFF',
  },
  navigation: {
    navPrimary: '#131211',
    navSecondary: '#3D3B37',
    crumbBackground: '#3D3B37',
    iconOnNav: '#CDD5FF',
    navHover: '#67625D',
  },
  neutrals: {
    neutral100: 'rgba(255,255,255,1)',
    neutral90: 'rgba(255,255,255,0.9)',
    neutral80: 'rgba(255,255,255,0.8)',
    neutral60: 'rgba(255,255,255,0.6)',
    neutral40: 'rgba(255,255,255,0.4)',
    neutral20: 'rgba(255,255,255,0.2)',
    neutral10: 'rgba(255,255,255,0.1)',
    neutral5: 'rgba(255,255,255,0.05)',
    neutral0: 'rgba(255,255,255,0)',
  },
  pill: {
    pillBackgroundPrimary: '#E5EAFF',
    pillBackgroundHover: '#CDD5FF',
    pillBackgroundSecondary: '#F5F7FF',
    pillInactive: '#EEECE9',
    linkOnPill: '#5A6BAD',
  },
  responsive: {
    textOnRankHeader: '#3D3B37',
    successOnRankHeader: '#007D26',
    regressionOnRankHeader: '#D32F2F',
    neutralOnRankHeader: '#67625D',
    rankHeaderBackground: '#E5EAFF',
  },
  states: {
    hoverPrimary: '#273560',
    hoverAccent: '#394B84',
    active: '#394B84',
    activeAccent: '#5A6BAD',
    visited: '#e5eaff',
    inactivePrimary: '#EEECE9',
    inactiveSecondary: '#BAB2A8',
    inactiveTertiary: '#DDD9D4',
  },
  social: {
    twitterPrimary: '#1DA1F2',
    linkedinPrimary: '#2867B2',
  },
  text: {
    textPrimary: '#F9F8F7',
    textSecondary: '#BAB2A8',
    textHighlight: '#CDD5FF',
    textHoverPrimary: '#E7C7E0',
    textHoverSecondary: '#E7C7E0',
  },
  textOn: {
    textOnPrimary: '#F9F8F7',
    textOnPill: '#131211',
    textOnInversePrimary: '#131211',
    textOnInverseSecondary: '#3D3B37',
    textOnButtonPrimary: '#131211',
    textOnButtonSecondary: '#72C77D',
    textOnButtonNeutral: '#EEECE9',
    textOnButtonNeutralHover: '#F9F8F7',
    textOnButtonNeutralActive: '#FFFFFF',
    textOnButtonTertiary: '#5A6BAD',
    textOnButtonTertiaryHover: '#E5EAFF',
    textOnSearchHighlight: '#F9F8F7',
    textOnCrumb: '#CDD5FF',
    textOnButtonTertiaryActive: '#F5F7FF',
  },
  warnings: {
    success: '#72C77D',
    successBackground: '#006A20',
    warning: '#FFF1D6',
    warningBackground: '#A36B01',
    alert: '#EF5350',
    alertBackground: '#D32F2F',
  },
}

const config = {
  fontFamily: "'Inter var', sans-serif" as string,
  spacing: (x: number) => `${10 * x}px`,
  pxsToEms: (x: number) => `${(x / 16) * 1}em`,
  breakPoints,
  textSizes,
}

const lightPharmaspectraTheme = {
  ...config,
  colors: { ...lightModeColors },
} as const

const lightIqviaTheme = {
  ...config,
  textSizes: iqviaTextSizes,
  fontFamily: "'Proxima Nova', sans-serif" as string,
  colors: { ...lightModeIqviaColors },
} as const

const darkTheme = {
  ...config,
  colors: {
    ...darkModeColors,
  },
} as const

const theme = lightIqviaTheme || lightPharmaspectraTheme || darkTheme

export type Theme = typeof theme

const lightModeThemes = {
  iqvia: lightIqviaTheme,
  pharmaspectra: lightPharmaspectraTheme,
}

export const getThemes = (mode: Mode, brand: 'pharmaspectra' | 'iqvia'): Theme => {
  const modes = {
    light: lightModeThemes[brand],
    dark: darkTheme,
  }
  return modes[mode]
}

export const LinkThemeProvider: React.FC<{ mode?: Mode; brand?: 'pharmaspectra' | 'iqvia' }> = ({
  children,
  mode = 'light',
  brand = 'pharmaspectra',
}) => <ThemeProvider theme={getThemes(mode, brand)}>{children}</ThemeProvider>
