import React from 'react'
import { Controller } from 'react-hook-form'
import { Input, InputProps } from '~components/ui/molecules/input'
import { ControlledProps } from '../types'

export interface ControlledInputProps<TFormValues> extends ControlledProps<TFormValues>, InputProps {
  name: string
}

export const ControlledInput = <TFormValues extends Record<string, unknown>>({
  name,
  control,
  label,
  errors,
  inputSize,
  placeholder,
  rules = {},
  type = 'text',
  defaultValue = '',
  ...rest
}: ControlledInputProps<TFormValues>): JSX.Element => (
  <Controller
    defaultValue={defaultValue}
    rules={rules}
    control={control}
    name={name}
    render={({ field }) => (
      <Input
        {...rest}
        type={type}
        labelPosition={rest.labelPosition}
        inputSize={inputSize}
        label={label}
        placeholder={placeholder}
        onChange={field.onChange}
        onBlur={field.onBlur}
        id={name}
        value={field.value ?? defaultValue}
        error={{
          isError: !!errors?.[name],
          message: errors?.[name]?.message || null,
        }}
      />
    )}
  />
)
