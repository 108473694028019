import * as React from 'react'
import { PharmaspectraBrandLogo } from '~shared/ui/molecules/brand-logo'
import { CenteredFlex } from '~shared/common'

import { ResetPasswordTemplateContainer, Column, Row, ColumnFullWidth, StyledLogoSection } from './styles'

export interface IResetPasswordProps {
  children: React.ReactElement
}

export function ResetPasswordTemplate(props: IResetPasswordProps) {
  return (
    <ResetPasswordTemplateContainer>
      <Row>
        <ColumnFullWidth>
          <StyledLogoSection>
            <CenteredFlex>
              <PharmaspectraBrandLogo isTouchable={false} logoSize="lg" logoVariant="primary" withNextLink={false} />
            </CenteredFlex>
          </StyledLogoSection>
        </ColumnFullWidth>
        <Column>{props.children}</Column>
      </Row>
    </ResetPasswordTemplateContainer>
  )
}
