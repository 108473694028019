const countryMapping: Record<string, string> = {
  'Aland Islands': 'Åland Islands',
  'Bosnia and Herzegovina': 'Bosnia',
  'Virgin Islands (British)': 'British Virgin Islands',
  'Cape Verde': 'Cabo Verde',
  'Bonaire, Sint Eustatius and Saba': 'Caribbean Netherlands',
  'Republic of the Congo': 'Congo - Brazzaville',
  'Democratic Republic of the Congo': 'Congo - Kinshasa',
  'Ivory Coast': "Côte d'Ivoire",
  Curacao: 'Curaçao',
  'Heard and Mc Donald Islands': 'Heard Island and McDonald Islands',
  'Palestinian National Authority': 'Palestine',
  'Pitcairn Island': 'Pitcairn',
  Reunion: 'Réunion',
  'St Pierre and Miquelon': 'Saint Pierre and Miquelon',
  'Svalbard and Jan Mayen': 'Svalbard and Jan Mayen Islands',
  Syria: 'Syrian Arab Republic',
  'East Timor': 'Timor-Leste',
  UAE: 'United Arab Emirates',
  UK: 'United Kingdom',
  Tanzania: 'United Republic of Tanzania',
  USA: 'United States',
  'US Minor Outlying Islands': 'United States Minor Outlying Islands',
  'Virgin Islands (U.S)': 'United States Virgin Islands',
}

export const psToEE = (country: string): string => countryMapping[country] || country

export const eeToPS = (country: string): string =>
  Object.keys(countryMapping).find((key) => countryMapping[key] === country) || country
