import { Config } from './types/common-types'

export const getConfig = (): Config => {
  const config: Config = {
    GRAPHQL_ENDPOINT: (process.env.REACT_APP_GRAPHQL_ENDPOINT || process.env.GRAPHQL_ENDPOINT) as string,
    EE_GRAPHQL_ENDPOINT: (process.env.EE_GRAPHQL_ENDPOINT || process.env.GRAPHQL_ENDPOINT) as string,
    GRAPHQL_REGION: (process.env.REACT_APP_GRAPHQL_REGION || process.env.GRAPHQL_REGION) as string,
    USER_POOL_ID: (process.env.REACT_APP_USER_POOL_ID || process.env.USER_POOL_ID) as string,
    USER_POOL_WEB_CLIENT_ID: (process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID ||
      process.env.USER_POOL_WEB_CLIENT_ID) as string,
    CONTRIBUTIONS_API_URL: (process.env.REACT_APP_CONTRIBUTIONS_API_URL || process.env.CONTRIBUTIONS_API_URL) as string,
    CONTRIBUTIONS_API_KEY: (process.env.REACT_APP_CONTRIBUTIONS_API_KEY || process.env.CONTRIBUTIONS_API_KEY) as string,
    RUM_CLIENT_TOKEN: (process.env.REACT_APP_RUM_CLIENT_TOKEN || process.env.RUM_CLIENT_TOKEN) as string,
    RUM_APP_ID: (process.env.REACT_APP_RUM_APP_ID || process.env.RUM_APP_ID) as string,
    GOOGLE_ANALYTICS_UA_ID: (process.env.REACT_APP_GOOGLE_ANALYTICS_UA_ID ||
      process.env.GOOGLE_ANALYTICS_UA_ID) as string,
    GOOGLE_ANALYTICS_GA4_ID: (process.env.REACT_APP_GOOGLE_ANALYTICS_GA4_ID ||
      process.env.GOOGLE_ANALYTICS_GA4_ID) as string,
    GIT_HASH: (process.env.REACT_APP_GIT_HASH || process.env.GIT_HASH) as string,
    ENV: (process.env.REACT_APP_ENV || process.env.ENV) as string,
    LOG_LEVEL: (process.env.REACT_APP_LOG_LEVEL || process.env.LOG_LEVEL) as string,
    EE_REDIRECT_URL: (process.env.REACT_APP_EE_REDIRECT_URL || process.env.EE_REDIRECT_URL) as string,
  }
  return { ...config }
}
