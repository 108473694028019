import styled, { css } from 'styled-components'
import { Button } from '~shared/ui/atoms/button'
import { Checkbox } from '~shared/ui/atoms/checkbox'
import { Controlled } from '~shared/ui/controlled'

export const flexMixin = css`
  display: flex;
  display: -ms-flexbox;
`

export const Flex = styled.div`
  ${flexMixin}
`

export const LoginNonSSOForm = styled(Flex).attrs({ as: 'form' })`
  background: ${({ theme }) => theme.colors.brand.backgroundSecondary};
  padding: 20px;
  border-radius: 1.5px;
  margin: auto;
  display: block;
`

export const Row = styled.div`
  display: flex;
  flex-flow: wrap;
  position: absolute;
`

export const Column = styled.div`
  flex: 50%;
`

export const EmailInput = styled(Controlled.Input)`
  display: block;
  width: 100%;
  margin-top: 0.625rem;

  &:focus {
    outline: none;
    box-shadow: 0px 0px 2px red;
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.4);
    font-size: 14px;
    font-style: italic;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(0, 0, 0, 0.4);
    font-size: 14px;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgba(0, 0, 0, 0.4);
    font-size: 14px;
  }

  > div {
    margin: 0.625rem 0;
  }

  > label {
    color: ${({ theme }) => theme.colors.text.textPrimary};
  }
`

export const PasswordInput = styled(Controlled.Input)`
  margin-top: 0.625rem;
  display: block;
  width: 100%;

  &:focus {
    outline: none;
    box-shadow: 0px 0px 2px red;
  }

  > div {
    margin: 0.625rem 0;
  }

  > label {
    color: ${({ theme }) => theme.colors.text.textPrimary};
  }

  &:invalid {
    border: 1px solid #d32f2f;
  }

  > div {
    margin: 0.625rem 0;
  }

  > label {
    color: ${({ theme }) => theme.colors.text.textPrimary};
  }
`

export const ContinueButton = styled(Button)`
  width: max-content;
  margin: 15px auto 0px auto;
`

export const KeepMeLoggedInCheckbox = styled(Checkbox)`
  margin-top: 15px;
`

export const LinksContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 15px;
`

export const Divider = styled.span`
  margin-left: 10px;
  margin-right: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
`

export const StyledCustomLink = styled.a`
  color: ${({ theme }) => theme.colors.text.textPrimary};

  &:hover {
    cursor: pointer;
  }
  &:focus {
    text-decoration: none;
  }
  &:visited {
    color: ${({ theme }) => theme.colors.states.visited};
  }
  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.text.textHoverPrimary};
  }
`
