import log, { LogLevelDesc } from 'loglevel'

const logLevels: { [key: string]: LogLevelDesc } = {
  TRACE: log.levels.TRACE,
  DEBUG: log.levels.DEBUG,
  INFO: log.levels.INFO,
  WARN: log.levels.WARN,
  ERROR: log.levels.ERROR,
  SILENT: log.levels.SILENT,
}

export class Logger {
  public readonly log

  constructor(name: string, logLevel?: string) {
    if (!logLevel) {
      logLevel = process.env.LOG_LEVEL
    }

    if (logLevel && logLevels[logLevel]) {
      log.setLevel(logLevels[logLevel])
    } else {
      log.setLevel(log.levels.INFO)
    }
    this.log = log.getLogger(name)
  }
}
