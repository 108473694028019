import styled, { css, keyframes } from 'styled-components'
import { Scale } from '~common/types'
import { Heading } from '~components/ui/atoms/heading'

export const flexMixin = css`
  display: flex;
  display: -ms-flexbox;
`

export const Flex = styled.div`
  ${flexMixin}
`

export const StyledRelativeDiv = styled.div`
  position: relative;
`

export const CenteredFlex = styled(Flex)`
  justify-content: center;
  align-items: center;
`

export const StyledSection = styled.section`
  margin-top: 1rem;
`

export const CenteredFlexColumn = styled(CenteredFlex)`
  flex-direction: column;
`

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
`

export const RelativeWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

export const ErrorBanner = styled.div`
  ${({ theme }) => theme.textSizes.desktop.body}
  text-align: center;
  font-weight: bold;
  padding: ${({ theme }) => theme.spacing(1)};
  margin: ${({ theme }) => theme.spacing(1)} 0;
  color: ${({ theme }) => theme.colors.textOn.textOnPrimary};
  background: #f5083e;
`

export const ButtonWithNoStyle = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  appearance: none;
  margin: 0;
`

export const StyledTableSection = styled(StyledSection)`
  padding: 1rem;
  max-width: ${({ theme }) => theme.pxsToEms(512)};
  margin: 0 auto;
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
    max-width: none;
  }
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletMedium)}) {
    padding: 1.5rem;
    max-width: ${({ theme }) => theme.pxsToEms(2040)};
  }
`

export const StyledInBoxGridList = styled.ul`
  max-width: ${({ theme }) => theme.pxsToEms(512)};
  margin: 0 auto;
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
    max-width: none;
  }
`

export const StyledReadingPaneTitle = styled(Heading)`
  ${({ theme }) => theme.textSizes.mobile.h2}
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
    ${({ theme }) => theme.textSizes.desktop.h2}
  }
`

export const shineAnimation = keyframes`
 to {
   background-position-x: -200%;
 }
`

export const browserTruncateMixin = css`
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`

export const StyledStatsDL = styled(Flex).attrs({ as: 'dl' })<{ isHighlighted?: boolean }>`
  flex-direction: column;
  justify-content: space-around;
  padding: 0.25rem;
  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      background-color: ${({ theme }) => theme.colors.detail.searchHighlight};
    `};
`

export const StyledSoSVStatsDL = styled(StyledStatsDL)`
  padding: 0;
`

export const StyledStatsDT = styled.dt<{ isHighlighted?: boolean }>`
  ${({ theme }) => theme.textSizes.desktop.label};
  color: ${({ theme }) => theme.colors.text.textSecondary};
  white-space: nowrap;
  text-wrap: wrap;
`

export const StyledStatsDD = styled(Flex).attrs({ as: 'dd' })<{ isHighlighted?: boolean }>`
  ${({ theme }) => theme.textSizes.desktop.statCallout};
  color: ${({ theme }) => theme.colors.text.textPrimary};
  align-items: center;
  margin-top: 0.25rem;
  width: 100%;
`

export const StyledScreenReadersOnly = styled.figcaption`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`

export const loadingStateBackgroundMixin = css`
  background: ${({ theme }) => theme.colors.neutrals.neutral10};
  background: ${({ theme }) =>
    `linear-gradient(110deg, ${theme.colors.neutrals.neutral10} 8%, ${theme.colors.neutrals.neutral5} 18%, ${theme.colors.neutrals.neutral10} 33%)`};
  background-size: 200% 100%;
  animation: 1s ${shineAnimation} linear infinite;
`

export const StyledSeparatorWithText = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 1rem 0;
  ${({ theme }) => theme.textSizes.desktop.label};
  color: ${({ theme }) => theme.colors.text.textSecondary};
  &:before,
  &:after {
    content: '';
    flex: 1;
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutrals.neutral20};
  }
  &:not(:empty)::before {
    margin-right: 1rem;
  }
  :not(:empty)::after {
    margin-left: 1rem;
  }
`

export const scaleColor = css<{ scale: Scale }>`
  ${({ theme, scale }) => {
    if (scale === 'lowest') return theme.colors.dataVisualisation.scale20
    if (scale === 'low') return theme.colors.dataVisualisation.scale40
    if (scale === 'medium') return theme.colors.dataVisualisation.scale60
    if (scale === 'high') return theme.colors.dataVisualisation.scale80
    if (scale === 'highest') return theme.colors.dataVisualisation.scale100
  }}
`

export const StyledExpertsDetailsSection = styled.section`
  max-width: ${({ theme }) => theme.pxsToEms(512)};
  margin: 1rem auto;
  padding: 0 1rem;
  position: relative;
  height: 100%;
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
    max-width: none;
  }

  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletMedium)}) {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.desktopSmall)}) {
    max-width: ${({ theme }) => theme.pxsToEms(1236 + 24 + 24)};
  }
`

export const StyledRadioContainedButtonGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 0.5rem;
  & > span {
    ${({ theme }) => theme.textSizes.desktop.searchStrong}
  }
`
