import styled from 'styled-components'
import { Flex } from '~common'
import { Text } from '~components/ui/atoms/text'

export const StyledInputWrapper = styled.div`
  position: relative;
  width: 100%;
`

export const StyledTextarea = styled.textarea<{
  isError?: boolean
}>`
  border: 1px solid ${({ theme, isError }) => (isError ? theme.colors.warnings.alert : theme.colors.neutrals.neutral40)};
  font: inherit;
  width: 100%;
  color: ${({ theme, isError }) => (isError ? theme.colors.warnings.alert : theme.colors.text.textSecondary)};
  appearance: none;
  margin: 0;
  transition: all 0.24s ease-in-out;
  background-color: ${({ theme }) => theme.colors.brand.backgroundSecondary};
  padding: 0.375rem 2.5625rem 0.375rem 0.5rem;
  &::placeholder {
    color: ${({ theme, isError }) => (isError ? theme.colors.warnings.alert : theme.colors.text.textSecondary)};
    font-style: italic;
  }
  &:hover,
  &:active,
  &:focus-visible {
    border: 1px solid ${({ theme, isError }) => !isError && theme.colors.text.textSecondary};
  }
  &:disabled {
    border: 1px solid ${({ theme }) => theme.colors.neutrals.neutral40};
    cursor: default;
    opacity: 0.2;
  }
`

export const StyledErrorWrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
  & > *:not(:last-child) {
    margin-bottom: 0.25rem;
  }
`

export const StyledErrorMessage = styled(Text)`
  ${({ theme }) => theme.textSizes.desktop.placeholderS}
  text-align: left;
  width: 100%;
  color: ${({ theme }) => theme.colors.warnings.alert};
`
