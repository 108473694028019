import styled, { css } from 'styled-components'
import { Flex, Status } from '~common'
import { IconButton } from '~components/ui/atoms/icon-button'
import { Text } from '~components/ui/atoms/text'
import { ToastStatus } from './Toast'

export const StyledToastIconWrapper = styled.div<{ status: ToastStatus }>`
  margin-right: 1rem;
  width: 1rem;
  height: 1rem;
  & > svg {
    fill: ${({ theme }) => theme.colors.neutrals.neutral80};
  }
`

export const StyledToastItem = styled(Flex)<{ status: ToastStatus }>`
  padding: 1rem;
  align-items: flex-start;
  border: 1px solid;
  color: ${({ theme }) => theme.colors.text.textPrimary};
  ${({ status, theme }) => {
    if (status === Status.NOTIFICATION) {
      return css`
        background-color: ${theme.colors.brand.backgroundSecondary};
        border-color: ${theme.colors.neutrals.neutral10};
      `
    }
    if (status === Status.WARNING) {
      return css`
        background-color: ${theme.colors.warnings.warningBackground};
        border-color: ${theme.colors.neutrals.neutral20};
      `
    }

    if (status === Status.ERROR) {
      return css`
        background-color: ${theme.colors.warnings.alertBackground};
        border-color: ${theme.colors.neutrals.neutral20};
      `
    }

    if (status === Status.SUCCESS) {
      return css`
        background-color: ${theme.colors.warnings.successBackground};
        border-color: ${theme.colors.neutrals.neutral20};
      `
    }
  }};
`

export const StyledRemoveIconButton = styled(IconButton)<{ status: ToastStatus }>`
  margin-left: 1rem;
  width: 0.875rem;
  height: 0.875rem;
  flex-shrink: 0;
  & > svg {
    vertical-align: top;
    width: 0.875rem;
    height: 0.875rem;
    fill: ${({ theme }) => theme.colors.neutrals.neutral80};
  }
`

export const StyledToastContent = styled.div`
  flex-grow: 1;
`

export const StyledToastTitle = styled(Text)`
  ${({ theme }) => theme.textSizes.desktop.searchStrong}
  margin-bottom: 0.25rem;
`

export const StyledToastDescription = styled(Text)`
  ${({ theme }) => theme.textSizes.desktop.label}
`
