import styled from 'styled-components'
import { Flex } from '~common'
import { Text } from '~components/ui/atoms/text'

export const StyledInputWrapper = styled.div`
  position: relative;
  width: 100%;
`

export const StyledInput = styled.input<{
  inputSize: 'xs' | 'sm' | 'md' | 'lg'
  isError?: boolean
  isLoading?: boolean
}>`
  border: 1px solid ${({ theme, isError }) => (isError ? theme.colors.warnings.alert : theme.colors.neutrals.neutral40)};
  font: inherit;
  width: 100%;
  color: ${({ theme, isError }) => (isError ? theme.colors.warnings.alert : theme.colors.text.textSecondary)};
  appearance: none;
  margin: 0;
  white-space: nowrap;
  transition: all 0.24s ease-in-out;
  background-color: ${({ theme }) => theme.colors.brand.backgroundSecondary};
  padding: ${({ inputSize }) =>
    inputSize === 'md' || inputSize === 'lg'
      ? `0.375rem 2.5625rem 0.375rem 0.5rem`
      : `0.25rem 2.5625rem 0.25rem 0.5rem`};
  min-height: ${({ inputSize }) => (inputSize === 'xs' ? '1.5rem' : '2.125rem')};
  &::placeholder {
    color: ${({ theme, isError }) => (isError ? theme.colors.warnings.alert : theme.colors.neutrals.neutral40)};
    font-style: italic;
  }
  &:hover,
  &:active,
  &:focus-visible {
    border: 1px solid ${({ theme, isError }) => !isError && theme.colors.text.textSecondary};
  }
  &:disabled {
    border: 1px solid ${({ theme }) => theme.colors.neutrals.neutral40};
    cursor: default;
    opacity: 0.2;
  }
`

export const StyledMaxCharAndErrorWrapper = styled(Flex)`
  width: 100%;
`

export const StyledErrorMessage = styled(Text)<{ isAlignedRight: boolean }>`
  ${({ theme }) => theme.textSizes.desktop.placeholderS}
  text-align: ${({ isAlignedRight }) => (isAlignedRight ? 'right' : 'left')};
  width: 100%;
  color: ${({ theme }) => theme.colors.warnings.alert};
`

export const StyledMaxCharLimit = styled.span`
  ${({ theme }) => theme.textSizes.desktop.label}
  margin-right: 0.625rem;
  color: ${({ theme }) => theme.colors.text.textSecondary};
`

export const StyledLoadingIndicatorWrapper = styled.div`
  position: absolute;
  right: 1rem;
  bottom: 0.8125rem;
`
