import React from 'react'
import { Status } from '~common'
import { CloseSvg } from '~common/svgs'
import {
  StyledRemoveIconButton,
  StyledToastContent,
  StyledToastIconWrapper,
  StyledToastItem,
  StyledToastTitle,
  StyledToastDescription,
} from './styles'

export interface IToast {
  id: string
  title: string
  description: string | React.ReactNode
  status: ToastStatus
  icon: React.ReactNode
  isRemovable: boolean
}

export type ToastStatus = Status.NOTIFICATION | Status.WARNING | Status.ERROR | Status.SUCCESS

export interface ToastProps extends IToast {
  onRemoveItem?: (id: string) => void
  className?: string
  asTag?: string
}

export const Toast = ({
  id,
  title,
  description,
  status,
  icon,
  onRemoveItem,
  isRemovable,
  className,
  asTag = 'div',
}: ToastProps): JSX.Element => (
  <StyledToastItem status={status} className={className} as={asTag as never}>
    <StyledToastIconWrapper status={status}>{icon}</StyledToastIconWrapper>
    <StyledToastContent>
      <StyledToastTitle>{title}</StyledToastTitle>
      <StyledToastDescription>{description}</StyledToastDescription>
    </StyledToastContent>
    {isRemovable && onRemoveItem && (
      <StyledRemoveIconButton onClick={() => onRemoveItem(id)} a11yTitle="Remove" icon={<CloseSvg />} status={status} />
    )}
  </StyledToastItem>
)
