import styled from 'styled-components'

import { Flex } from '~shared/common'
import { Controlled } from '~shared/ui/controlled'

export const StyledForgotPasswordForm = styled(Flex).attrs({ as: 'form' })`
  background: ${({ theme }) => theme.colors.brand.backgroundSecondary};
  padding: 20px;
  border-radius: 1.5px;
  margin: auto;
  width: 400px;
  display: block;
`

export const StyledInput = styled(Controlled.Input)`
  > label {
    color: ${({ theme }) => theme.colors.text.textPrimary};
  }
`
