import * as React from 'react'
import { useCallback, useState } from 'react'
import { LoginTemplate } from '~components/login-template'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Toast } from '~shared/ui/molecules/toast'
import { ContinueButton, EmailInput, LinksContainer, LoginNonSSOForm, PasswordInput, StyledCustomLink } from './styles'
import { Auth } from 'aws-amplify'
import { Status } from '~common'
import { WarningSvg } from '~common/svgs'
import { useForm } from 'react-hook-form'
import { Heading } from '~shared/ui/atoms/heading'
import { buildUrl, paths } from '~helpers'
import { Logger } from '~helpers/Logger'
import usePageTitle from '~hooks/use-page-title'
import { useDebounce } from '~common/hooks'
import { useLoginSettings } from '~hooks'

interface FormValues {
  email: string
  password: string
}

interface SignInError {
  message: string
  description: string
}

function getErrorDescription(message: string){
  switch(message) {
    case "Password attempts exceeded":
      return "Please try again later."
    case "Incorrect username or password.":
      return "Please try again."
    default:
      return "Please contact administrator."
  }
}

export function LoginNonSSO() {
  const [searchParams] = useSearchParams()
  const [error, setError] = useState<SignInError | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const navigate = useNavigate()

  const defaultValues: FormValues = {
    email: searchParams.get('email') || '',
    password: '',
  }

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<FormValues>({ defaultValues })

  const emailWatch = watch('email')
  const passwordWatch = watch('password')

  //keep the login settings (session timeout) up to date with the email that is entered here,
  //in case the user changes the email on this page
  useLoginSettings(useDebounce(emailWatch, 500))

  const onSubmitHandler = (values: FormValues) => {
    return new Promise<boolean>(async (res, rej) => {
      setError(null)
      setLoading(true)
      try {
        await Auth.signIn({ username: values.email, password: values.password })
        res(true)
        setLoading(false)
      } catch (error) {
        Logger.error(error)
        setError({ message: error.message, description: getErrorDescription(error.message) })
        rej(error)
        setLoading(false)
      }
    })
  }
  

  const onForgotPasswordClicked = useCallback(() => {
    const email = emailWatch
    navigate(buildUrl(paths.forgotPassword, email ? [{ id: 'email', value: email }] : []))
  }, [emailWatch, navigate])

  usePageTitle('Sign in | Pharmaspectra')

  return (
    <LoginTemplate>
      <LoginNonSSOForm onSubmit={handleSubmit(onSubmitHandler)}>
        <Heading level="2">Sign in with Pharmaspectra credentials</Heading>
        {error && (
          <Toast
            id="1"
            icon={<WarningSvg />}
            status={Status.ERROR}
            isRemovable={true}
            title={error.message}
            description={error.description}
          />
        )}

        <EmailInput
          inputSize="md"
          name="email"
          type="email"
          role="title"
          control={control}
          placeholder="email@email.com"
          rules={{
            required: 'Email is required',
          }}
          defaultValue={'email@email.com'}
          label="Email"
          labelPosition="top"
          errors={errors}
          data-testid="email-input"
        />

        <PasswordInput
          inputSize="md"
          name="password"
          type="password"
          role="password"
          control={control}
          rules={{
            required: 'Password is required',
          }}
          label="Password"
          labelPosition="top"
          errors={errors}
          data-testid="password-input"
        />

        <ContinueButton variant="primary" size="lg" type="submit" isDisabled={(!emailWatch && !passwordWatch) || loading}>
          Sign in
        </ContinueButton>

        <LinksContainer>
          <StyledCustomLink onClick={onForgotPasswordClicked}>
            Forgot password?
          </StyledCustomLink>
        </LinksContainer>
      </LoginNonSSOForm>
    </LoginTemplate>
  )
}
