import styled, { css, DefaultTheme } from 'styled-components'
import { Flex } from '~common'
import { CheckMarkIcon } from '~components/ui/atoms/check-mark-icon'
import { CheckBoxSize, LabelPosition, Variant } from './Checkbox'

export const StyledHiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

interface StyledCheckboxProps {
  isChecked: boolean
  isDisabled: boolean
  checkboxSize: CheckBoxSize
  labelPosition: LabelPosition
}

const checkboxSizes = {
  sm: '0.75rem',
  md: '0.875rem',
  lg: '1.5rem',
}

const checkboxMargin = {
  sm: '0.25rem',
  md: '0.5rem',
  lg: '1rem',
}

export const StyledCheckbox = styled(Flex)<StyledCheckboxProps>`
  align-items: center;
  justify-content: center;
  width: ${({ checkboxSize }) => checkboxSizes[checkboxSize]};
  height: ${({ checkboxSize }) => checkboxSizes[checkboxSize]};
  cursor: ${({ isDisabled }) => !isDisabled && 'pointer'};
  transition: all 0.24s ease-in-out;
  ${({ checkboxSize, labelPosition }) => {
    if (labelPosition === 'left') {
      return css`
        margin-left: ${checkboxMargin[checkboxSize]};
      `
    }
    if (labelPosition === 'right') {
      return css`
        margin-right: ${checkboxMargin[checkboxSize]};
      `
    }
  }};
`

export const StyledCheckIcon = styled(CheckMarkIcon)<{ variant: Variant; checkboxSize: CheckBoxSize }>`
  width: ${({ checkboxSize }) => (checkboxSize === 'lg' ? '0.9375rem' : '0.75rem')};
  height: ${({ checkboxSize }) => (checkboxSize === 'lg' ? '0.75rem' : '0.5rem')};
  fill: ${({ theme, variant }) =>
    variant === 'primary' ? theme.colors.forms.iconOnPrimary : theme.colors.brand.blueSecondary};
  path {
    fill: ${({ theme, variant }) =>
      variant === 'primary' ? theme.colors.forms.iconOnPrimary : theme.colors.brand.blueSecondary};
  }
`

const mixinBgAndBorder = ({
  isChecked,
  isDisabled,
  variant,
  theme,
}: {
  isChecked: boolean
  isDisabled: boolean
  theme: DefaultTheme
  variant: Variant
}) => {
  if (isDisabled && !isChecked)
    return css`
      opacity: 0.2;
      border: 1px solid ${theme.colors.neutrals.neutral40};
      background: ${theme.colors.forms.checkboxBackground};
    `
  if (isDisabled && isChecked)
    return css`
      opacity: 0.2;
      background: ${variant === 'primary' ? theme.colors.brand.bluePrimary : theme.colors.brand.backgroundPrimary};
    `
  if (!isChecked)
    return css`
      background: ${theme.colors.forms.checkboxBackground};
      border: 1px solid ${theme.colors.neutrals.neutral40};
      &:hover {
        background: ${theme.colors.forms.checkboxBackground};
        border: 1px solid ${({ theme }) => theme.colors.neutrals.neutral60};
      }
    `
  if (isChecked)
    return css`
      background: ${variant === 'primary' ? theme.colors.brand.bluePrimary : theme.colors.brand.backgroundPrimary};
    `
}

export const StyledCheckboxLabel = styled(Flex).attrs({ as: 'label' })<{
  isChecked: boolean
  isDisabled: boolean
  labelPosition: LabelPosition
  variant: Variant
}>`
  color: ${({ isDisabled, variant, theme }) => {
    if (isDisabled) return theme.colors.neutrals.neutral60
    if (variant === 'primary') return theme.colors.text.textPrimary
    if (variant === 'secondary') return theme.colors.text.textSecondary
  }};
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  align-items: center;
  flex-direction: ${({ labelPosition }) => (labelPosition === 'left' ? 'row' : 'row-reverse')};
  ${StyledCheckIcon} {
    visibility: ${({ isChecked }) => (isChecked ? 'visible' : 'hidden')};
  }
  ${StyledHiddenCheckbox}:focus + ${StyledCheckbox} {
    ${({ isChecked, isDisabled }) =>
      !isChecked &&
      !isDisabled &&
      css`
        border: 1px solid ${({ theme }) => theme.colors.neutrals.neutral60};
      `};
  }
  ${StyledHiddenCheckbox}:focus:not(:focus-visible) + ${StyledCheckbox} {
    ${({ isChecked, isDisabled, theme, variant }) => mixinBgAndBorder({ isChecked, isDisabled, theme, variant })}
  }
  ${StyledHiddenCheckbox}:focus-visible + ${StyledCheckbox} {
    outline: -webkit-focus-ring-color auto 1px;
    ${({ isChecked, isDisabled }) =>
      !isChecked &&
      !isDisabled &&
      css`
        border: 1px solid ${({ theme }) => theme.colors.neutrals.neutral60};
      `};
  }
  &:hover ${StyledCheckbox} {
    ${({ isChecked, isDisabled, theme }) =>
      !isChecked &&
      !isDisabled &&
      css`{
        background: ${theme.colors.forms.checkboxBackground};
        border: 1px solid ${theme.colors.neutrals.neutral60};
      }
    }`}
  }
  ${StyledCheckbox} {
    ${({ isChecked, isDisabled, theme, variant }) => mixinBgAndBorder({ isChecked, isDisabled, theme, variant })}
  }
`
