import qs from 'qs'
import { either, isNil, isEmpty } from 'ramda'
import {
  AcceptedPillCategories,
  ContentTypes,
  SearchQueryValue,
  TTrendDirection,
  SearchQuerySynonyms,
  sosvTimeframeLabels,
  GraphType,
  SoSVTimeframe,
  DateInterval,
  DateBreakdown,
  CollectionType,
  WeighteningType,
  SearchedQueryValues,
} from '~common/types'
import { RadioItem } from '~components/ui/controlled'
import addWeeks from 'date-fns/addWeeks'
import addYears from 'date-fns/addYears'
import { getSoSVProductColors, textSizes, ThemeBrand } from '~common/theme'

export const errorMessages = {
  hasCorrectLength: 'Please make sure password is at least 8 characters in length',
  hasNumericChar: 'Please make sure password at least has one numeric character',
  hasUppercaseChar: 'Please make sure password at least one uppercase character',
  hasLowerCaseChar: 'Please make sure password at least one lowercase character',
  hasSpecialChar: 'Please make sure password at least one special character',
}

export const passwordValidationRules = {
  hasCorrectLength: (val: string): boolean | string => val.length >= 8 || errorMessages.hasCorrectLength,
  hasNumericChar: (val: string): boolean | string => /\d/g.test(val) || errorMessages.hasNumericChar,
  hasUppercaseChar: (val: string): boolean | string => /[A-Z]/.test(val) || errorMessages.hasUppercaseChar,
  hasLowerCaseChar: (val: string): boolean | string => /[a-z]/.test(val) || errorMessages.hasLowerCaseChar,
  hasSpecialChar: (val: string): boolean | string => /[\W]/.test(val) || errorMessages.hasSpecialChar,
}

export const smartTrim = (input: string, limit: number): string => {
  const isLastCharWhitespace = (input: string): boolean => {
    return input.charAt(input.length - 1) === ' '
  }

  const trimUntilWhitespace = (input: string): string => {
    const trimmed = input.slice(0, input.length - 1)
    if (isLastCharWhitespace(trimmed)) {
      return trimmed.trim() + '...'
    } else {
      return trimUntilWhitespace(trimmed)
    }
  }

  if (limit >= input.length) {
    return input
  }

  const limitTrimmedInput = input.slice(0, limit)
  if (!isLastCharWhitespace(limitTrimmedInput) && limitTrimmedInput.includes(' ')) {
    return trimUntilWhitespace(limitTrimmedInput)
  }

  return limitTrimmedInput + '...'
}

export const isScrolledToBottom = (element: React.UIEvent['currentTarget']): boolean =>
  element.scrollTop + element.clientHeight * 2 >= element.scrollHeight

export const convertToKebabCase = (str: string): string => str.replace(/\s+/g, '-').toLowerCase()

export const nonEmptyListOrUndefined = <T>(list: T[]): T[] | undefined => {
  return list.length > 0 ? list : undefined
}

export const capitalizeFirstLetter = (str: string): string => str[0].toUpperCase() + str.slice(1)

export const nFormatter = (num: number): number | string => {
  if (num > 999 && num < 1000000) {
    return parseFloat((num / 1000).toFixed(1)) + 'K'
  } else if (num >= 1000000) {
    return parseFloat((num / 1000000).toFixed(1)) + 'M'
  } else {
    return num
  }
}

export const removeAnyHTMLTags = (str: string): string => str.replace(/(<([^>]+)>)/gi, '')

export const camelCaseToSentenceCase = (camelCaseString: string): string => {
  const sentenceCaseString = camelCaseString.replace(/([A-Z])/g, ' $1').toLowerCase()
  return sentenceCaseString.charAt(0).toUpperCase() + sentenceCaseString.slice(1)
}

export const toCamelCase = (str: string): string =>
  str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (_, chr) => chr.toUpperCase())

export const formatUrl = (url: string, query?: Record<string, unknown>, hash?: string): string => {
  if (!!qs.stringify(query)) {
    const urlHasQueryStringSeparator = url && url.indexOf('?') !== -1
    if (!urlHasQueryStringSeparator) {
      return `${url}?${qs.stringify(query, { arrayFormat: 'repeat' })}${hash ? `#${hash}` : ''}`
    }

    const [path, urlParams] = url.split('?')

    const parseOptions = { parseArrays: true }
    const parsedUrl = qs.parse(urlParams, parseOptions)
    const parsedUrlKeys = Object.keys(parsedUrl)

    const parsedQuery = qs.parse(qs.stringify(query, { arrayFormat: 'repeat' }), parseOptions)
    const parsedQueryKeys = Object.keys(parsedQuery)

    const sanitizedParams: Record<string, unknown> = {}
    const keys = Array.from(new Set([...parsedUrlKeys, ...parsedQueryKeys]))
    for (const key of keys) {
      sanitizedParams[key] = parsedQueryKeys.includes(key) ? parsedQuery[key] : parsedUrl[key]
    }

    return `${path}?${qs.stringify(sanitizedParams, { arrayFormat: 'repeat' })}${hash ? `#${hash}` : ''}`
  }

  return `${url}${hash ? `#${hash}` : ''}`
}

export const getYearsRange = (startYear: number): number[] => {
  const currentYear = new Date().getFullYear(),
    years: number[] = []
  startYear = startYear || 1980
  while (startYear <= currentYear) {
    years.push(startYear++)
  }
  return years
}

export const getPastYearsLabels = (selectedYearsRange: number, descOrder = true): string[] => {
  if (selectedYearsRange === 0) {
    return []
  }

  const yearsLabels: string[] = []
  let i = 1

  do {
    const currentLabel = i === 1 ? 'Past 12 months' : i + ' Years ago'

    descOrder ? yearsLabels.unshift(currentLabel) : yearsLabels.push(currentLabel)

    i++
  } while (i <= selectedYearsRange)

  return yearsLabels
}

export const cleanForFalsyValues = (obj: Record<string, string | null>): Record<string, string> => {
  const newObj: Record<string, string> = {}
  Object.keys(obj).forEach((prop: string) => {
    if (obj[prop] !== null) {
      newObj[prop] = obj[prop] as string
    }
  })
  return newObj
}

export const locale = (): string => {
  if (navigator.languages) return navigator.languages.length ? navigator.languages[0] : navigator.language
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (navigator as any).userLanguage
}

export const getLocalisedTimeString = (date: Date): Date => {
  const jsDate = new Date(date)
  jsDate.setTime(jsDate.getTime() + jsDate.getTimezoneOffset() * 60 * 1000)
  return jsDate
}

export const getFilenameTimestamp = (): string => {
  const dateTime = getLocalisedTimeString(new Date(Date.now()))
  const pad = (v: number) => (`${v}`.length === 1 ? `0${v}` : `${v}`)
  return (
    `${dateTime.getFullYear()}-` +
    `${pad(dateTime.getMonth() + 1)}-` +
    `${pad(dateTime.getDate())} ` +
    `${pad(dateTime.getHours())}_` +
    `${pad(dateTime.getMinutes())}_` +
    `${pad(dateTime.getSeconds())}`
  )
}

export const formattedDate = (date?: string | Date | null, timeZone?: string): string | undefined =>
  date
    ? new Date(date).toLocaleDateString(locale(), {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        timeZone,
      })
    : undefined

export const formattedTime = (date: string | Date, force24Hour = false): string => {
  const jsDate = getLocalisedTimeString(new Date(date))
  return force24Hour ? jsDate.toLocaleTimeString('en-GB') : jsDate.toLocaleTimeString(locale())
}

export const formattedYears = (fromYear: string | null, toYear: string | null): string | null => {
  if (fromYear === toYear) return fromYear as string
  if (fromYear && toYear) return `${fromYear}-${toYear}`
  if (fromYear) return fromYear
  if (toYear) return toYear
  return null
}

export const getFormatedStartAndEndDate = (
  startDate?: string | number | Date | null,
  endDate?: string | number | Date | null,
): string => {
  const formatDateOrYear = (date: Date | string | number) =>
    typeof date === 'number' ? date.toString() : formattedDate(date)
  const formattedStartDate = startDate && formatDateOrYear(startDate)
  const formattedEndDate = endDate && formatDateOrYear(endDate)
  return [formattedStartDate, formattedEndDate].filter((x) => !!x).join(' - ')
}

export const getTrendDirectionFromSlope = (slope: number): TTrendDirection => {
  if (slope > 0.2) return 'up'
  if (slope < -0.2) return 'down'
  return 'straight'
}

export const findItemNested = <T>(arr: T[], itemId: string, nestingKey: string): T | null =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  arr.reduce((a, item: any) => {
    if (a) return a
    if (item.id === itemId) return item
    if (item[nestingKey]) return findItemNested(item[nestingKey], itemId, nestingKey)
  }, null)

export const isEmptyOrSpaces = (str: string): boolean => str === null || str.match(/^ *$/) !== null

export const isPillKey = (key: string): boolean => {
  return key.indexOf('filters') === 0
}

export const roundDecimals = (value: number, decimalPlaces: number): number => {
  const factorOfTen = Math.pow(10, decimalPlaces)
  return Math.round(value * factorOfTen) / factorOfTen
}

export const calcPercentage = (total: number, part: number): number => {
  const maybeNaN = (100 * part) / total
  const safe = isNaN(maybeNaN) ? 0 : maybeNaN
  const rounded = safe > 0 && safe < 1 ? 1 : Math.round(safe)
  return rounded > 100 ? 100 : rounded
}

export const getBayerListIdsForTlScore = (): string[] => {
  /* Bayer list IDs that have TL Score
  Query:
  SELECT DISTINCT sl.ListID AS listId
  FROM tbl_syssegmentationcodevalue AS seg
  INNER JOIN tbl_syslist sl ON seg.ListId = sl.DetailOfList
  WHERE seg.ListId IN (
    SELECT listId
      FROM tbl_client_preferences clientpref,
          tbl_preferences pref
      WHERE pref.id = clientpref.prefid
          and clientID = 48 -- bayer
          and pref_value = 'TL Score'
    );
  */
  return [
    '8d5e7fa2-d0eb-43be-9087-fa0e3f904595',
    'c799c78d-c348-466d-85db-53c176a6e34c',
    'c4f30d9e-7d7b-4456-a134-55627c37728a',
    '62a4ae2a-073e-4b10-a7d2-2355af263419',
    'fbdb3475-5220-4ac4-ae62-cdc0b4ef0f1f',
    '89c24ea6-ff27-43f0-a7ec-571368609848',
    '70c98dcd-14ea-45ac-a8b9-6362683926c9',
    '1922e10f-811b-4612-963f-f9e5b7b98808',
    'd33d313c-ecd0-4bc8-b6c2-ae2dadaa1f6d',
    'b8fea565-ab47-4218-91f2-6e30439a0bf7',
    'a91e3c06-c882-4fd9-a4ca-a2ee846dc3b2',
    'af52605a-c923-4f1f-a6cf-30b565746d34',
    'efbddfd4-5b15-47a2-a818-a0e60aff68fd',
  ]
}

export const dedupeObjectArray = <T>(items: T[]): T[] => {
  const unique: T[] = []
  const jsonArray = items.map((i) => JSON.stringify(i))
  for (let i = 0; i < items.length; i++) {
    // search strings; if this is the FIRST index found, use the item
    if (jsonArray.indexOf(JSON.stringify(items[i])) == i) {
      unique.push(items[i])
    }
  }
  return unique
}

export const formatContentTypes = (contentTypes: string[]): string[] => {
  const allContentTypes = Object.entries(ContentTypes).map(([_, value]) => value)

  if (contentTypes.length === 0 || allContentTypes.every((ct) => contentTypes.includes(ct)))
    return ['All content types']

  return contentTypes.map((ct) => {
    if (ct === 'nih-grants') return 'NIH grants'
    const sentenceCaseString = ct.replace(/-/g, ' ')
    return sentenceCaseString.charAt(0).toUpperCase() + sentenceCaseString.slice(1)
  })
}

export const decodePillValue = (value: string | null | undefined): string => {
  return decodeURIComponent(value || '')
}
export const encodePillValue = (value: string | null | undefined): string => {
  return encodeURIComponent(value || '')
}

export const convertPillsToUrlFiltersParam = (obj: SearchQueryValue, i: number): string => {
  const parameters: string[] = []
  parameters.push(encodeURI(`filters[${i}][category]=${obj.category}`))
  parameters.push(encodeURI(`filters[${i}][value]=`) + encodePillValue(obj.value))
  if (obj.synonyms) {
    const synonyms = Object.keys(obj.synonyms)
    synonyms
      .filter((s) => obj.synonyms?.[s])
      .forEach((s) => parameters.push(encodeURI(`filters[${i}][synonyms]=`) + encodePillValue(s)))
    synonyms
      .filter((s) => !obj.synonyms?.[s])
      .forEach((s) => parameters.push(encodeURI(`filters[${i}][synonyms0]=`) + encodePillValue(s)))
  }
  return parameters.join('&')
}

export const getPillsFromQuery = (rest: {
  [key: string]: string | string[] | undefined | unknown
}): SearchQueryValue[] => {
  const filters: { [key: string]: string | string[] | undefined | unknown } = rest
  const filterKeys = Object.keys(filters).filter(isPillKey)
  const pills: SearchQueryValue[] = []

  for (let i = 0; filterKeys.includes(`filters[${i}][value]`); i++) {
    const category = `filters[${i}][category]`
    const value = `filters[${i}][value]`
    const synonyms = `filters[${i}][synonyms]`
    const unselectedSynonyms = `filters[${i}][synonyms0]`
    const pill: SearchQueryValue = {
      category: filters[category] as string,
      value: decodePillValue(filters[value] as string),
    }

    if (!(pill.category.toLowerCase() in AcceptedPillCategories)) continue

    const pillSynonyms: SearchQuerySynonyms = {}
    if (filters[synonyms]) {
      ;[filters[synonyms]].flat().forEach((synonym) => (pillSynonyms[decodePillValue(synonym as string)] = true))
    }
    if (filters[unselectedSynonyms]) {
      ;[filters[unselectedSynonyms]]
        .flat()
        .forEach((synonym) => (pillSynonyms[decodePillValue(synonym as string)] = false))
    }
    if (Object.keys(pillSynonyms).length > 0) {
      pill.synonyms = pillSynonyms
    }
    pills.push(pill)
  }
  return pills
}

const categorySortOrder: Record<string, number> = {
  'medical term': 1,
  'full name': 2,
  degree: 3,
  speciality: 4,
  society: 5,
  'society event': 6,
  'society event custom term': 7,
  journal: 8,
  institution: 9,
  city: 10,
  state: 11,
  country: 12,
  disclosures: 13,
  zip: 14,
  'free text': 15,
}

interface Items {
  id: string
  value: string
  category: string
  synonyms?: Record<string, boolean>
}

export const sortByCategoryThenValue = (items: Items[], categoryToTheEnd?: string): Items[] =>
  [...items].sort((a, b) => {
    if (a.category === categoryToTheEnd) {
      return 1
    }
    if (b.category === categoryToTheEnd) {
      return -1
    }
    const result =
      (categorySortOrder[a.category.toLowerCase()] || 0) - (categorySortOrder[b.category.toLowerCase()] || 0)
    return result !== 0 ? result : a.value.toLowerCase().localeCompare(b.value.toLowerCase())
  })

export const getProductColors = (productsLength: number, brand: ThemeBrand = 'pharmaspectra'): string[] => {
  const sosvProductColours = getSoSVProductColors(brand)
  if (productsLength === 1) return [sosvProductColours[0]]
  if (productsLength === 2) return [sosvProductColours[6], sosvProductColours[0]]
  if (productsLength === 3) return [sosvProductColours[10], sosvProductColours[2], sosvProductColours[0]]
  if (productsLength === 4)
    return [sosvProductColours[9], sosvProductColours[6], sosvProductColours[3], sosvProductColours[0]]
  if (productsLength === 5)
    return [
      sosvProductColours[10],
      sosvProductColours[8],
      sosvProductColours[6],
      sosvProductColours[4],
      sosvProductColours[0],
    ]
  if (productsLength === 6)
    return [
      sosvProductColours[10],
      sosvProductColours[8],
      sosvProductColours[6],
      sosvProductColours[4],
      sosvProductColours[2],
      sosvProductColours[0],
    ]
  if (productsLength === 7)
    return [
      sosvProductColours[11],
      sosvProductColours[9],
      sosvProductColours[7],
      sosvProductColours[5],
      sosvProductColours[3],
      sosvProductColours[1],
      sosvProductColours[0],
    ]
  if (productsLength === 8)
    return [
      sosvProductColours[11],
      sosvProductColours[10],
      sosvProductColours[8],
      sosvProductColours[6],
      sosvProductColours[4],
      sosvProductColours[2],
      sosvProductColours[1],
      sosvProductColours[0],
    ]
  if (productsLength === 9)
    return [
      sosvProductColours[11],
      sosvProductColours[10],
      sosvProductColours[9],
      sosvProductColours[7],
      sosvProductColours[5],
      sosvProductColours[3],
      sosvProductColours[2],
      sosvProductColours[1],
      sosvProductColours[0],
    ]
  if (productsLength === 10)
    return [
      sosvProductColours[11],
      sosvProductColours[10],
      sosvProductColours[9],
      sosvProductColours[8],
      sosvProductColours[6],
      sosvProductColours[4],
      sosvProductColours[3],
      sosvProductColours[2],
      sosvProductColours[1],
      sosvProductColours[0],
    ]
  if (productsLength === 11)
    return [
      sosvProductColours[11],
      sosvProductColours[10],
      sosvProductColours[9],
      sosvProductColours[8],
      sosvProductColours[7],
      sosvProductColours[5],
      sosvProductColours[4],
      sosvProductColours[3],
      sosvProductColours[2],
      sosvProductColours[1],
      sosvProductColours[0],
    ]
  if (productsLength === 12)
    return [
      sosvProductColours[11],
      sosvProductColours[10],
      sosvProductColours[9],
      sosvProductColours[8],
      sosvProductColours[7],
      sosvProductColours[6],
      sosvProductColours[5],
      sosvProductColours[4],
      sosvProductColours[3],
      sosvProductColours[2],
      sosvProductColours[1],
      sosvProductColours[0],
    ]
  return []
}

export const generateSoSVModuleTitle = ({
  moduleType,
  weighteningType,
  graphType,
  sosvTimeframe,
  isTotal,
  startDate,
  endDate,
  top,
}: {
  moduleType: CollectionType
  sosvTimeframe: SoSVTimeframe
  weighteningType: WeighteningType
  isTotal?: boolean
  graphType?: GraphType
  startDate?: string
  endDate?: string
  top?: number
}): string => {
  const isCustomDateRange = sosvTimeframe === 'customDateRange'
  const titleEnding = isCustomDateRange
    ? `between ${formattedDate(startDate)} and ${formattedDate(endDate)}`
    : `over the ${sosvTimeframeLabels[sosvTimeframe]}`

  if (moduleType === 'products') {
    return `${isTotal ? 'Total ' : ''}${`${isTotal ? 'p' : 'P'}roduct`} ${
      graphType === 'counts' ? (weighteningType === 'weighted' ? 'score' : 'mentions') : 'share'
    } ${titleEnding}`
  } else if (moduleType === 'societyEventsLocation') {
    return `Society events by location ${titleEnding}`
  } else if (moduleType === 'launchAnalogue') {
    return `Launch analogue across ${sosvTimeframeLabels[sosvTimeframe].replace('last ', '')}`
  } else {
    const titleStarting = top ? `Top ${top} ${moduleType}` : `${capitalizeFirstLetter(moduleType)}`
    return `${titleStarting} ${titleEnding}`
  }
}

export const mapToSoSVSeries = (
  sosvSummary: {
    productName: string
    percentage: number
  }[],
): Highcharts.SeriesOptionsType[] =>
  sosvSummary.map((item, i) => ({
    name: item.productName,
    data: [{ y: +item.percentage.toFixed(1) }],
    type: 'column',
    className: `product-${i}`,
  }))

export const getPercentage = ({ value, whole }: { value: number; whole: number }): number => {
  if (!value) {
    return 0
  }
  if (!whole) {
    throw new Error(`[getPercentage] Error getting percentage from whole ${JSON.stringify(whole)}`)
  }
  return (value / whole) * 100
}

export const getIntervalForTimeframe = (timeframe?: string): DateInterval => {
  const now = new Date()
  switch (timeframe) {
    case 'twelveWeeks':
      return {
        breakDownBy: DateBreakdown.Week,
        startDate: addWeeks(now, -12),
        endDate: now,
      }
    case 'oneYear':
      return {
        breakDownBy: DateBreakdown.Month,
        startDate: addYears(now, -1),
        endDate: now,
      }
    case 'threeYears':
      return {
        breakDownBy: DateBreakdown.Quarter,
        startDate: addYears(now, -3),
        endDate: now,
      }
    case 'tenYears':
      return {
        breakDownBy: DateBreakdown.Year,
        startDate: addYears(now, -10),
        endDate: now,
      }
    default:
      return {
        breakDownBy: DateBreakdown.Week,
        startDate: addWeeks(now, -12),
        endDate: now,
      }
  }
}

enum NotificationsExclusions {
  both = 'Searches including zip codes or expert names do not support notifications',
  'zip-code' = 'Searches including zip codes do not support notifications',
  'full-name' = 'Searches including expert names do not support notifications',
  none = '',
}

export const getNotificationRadios = (
  queryItems: SearchedQueryValues[],
): { radios: RadioItem[]; message: NotificationsExclusions } => {
  const notificationOptions = [
    {
      label: 'None',
      id: 'none',
      value: 'none',
    },
    {
      label: 'Weekly',
      id: 'weekly',
      value: 'weekly',
    },
    {
      label: 'Monthly',
      id: 'monthly',
      value: 'monthly',
    },
  ]

  const getNotificationsExclusion = (queryItems: SearchedQueryValues[]): NotificationsExclusions => {
    if (queryItems.some((qi) => qi.category === 'Zip') && queryItems.some((qi) => qi.category === 'Full Name')) {
      return NotificationsExclusions.both
    } else if (queryItems.some((qi) => qi.category === 'Zip')) {
      return NotificationsExclusions['zip-code']
    } else if (queryItems.some((qi) => qi.category === 'Full Name')) {
      return NotificationsExclusions['full-name']
    }
    return NotificationsExclusions.none
  }

  const excludeNotifications = getNotificationsExclusion(queryItems)

  return {
    radios: excludeNotifications ? notificationOptions.filter((option) => option.id === 'none') : notificationOptions,
    message: excludeNotifications,
  }
}

export const rankFont: Record<string, Record<string, string | number>> = {
  '3': textSizes.desktop.rankM,
  '4': textSizes.desktop.rankS,
  '5': textSizes.desktop.rankXs,
}

export const getInfluenceScoresEnabledLists = (): string[] => [
  'a5f5bbd6-4870-4de9-82fa-b10ac4299223',
  'd13a7658-2072-4ed3-9f93-0a99f930a36f',
]

export const isNilOrEmpty = either(isNil, isEmpty)
