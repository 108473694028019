import styled, { css } from 'styled-components'
import { Card } from '~components/ui/molecules/card'

export const ProductTileLink = styled.a`
  flex: 100%;
  @media only screen and (min-width: 960px) {
    flex: 0 calc((100% - 1.5em) / 2);
  }
  @media only screen and (min-width: 1281px) {
    flex: 0 calc((100% - 3em) / 3);
  }
`

export const ProductTileCard = styled(Card)<{ isExceptionalClientUI: boolean }>`
  margin: 0;
  background-color: ${({ theme }) => theme.colors.always.alwaysLight};
  color: ${({ theme }) => theme.colors.navigation.navPrimary};
  padding: 3em 2em;
  flex: 100%;

  :hover {
    background-color: ${({ theme }) => theme.colors.always.alwaysLight};
  }

  * > img,
  * > svg {
    ${({ isExceptionalClientUI }) =>
      isExceptionalClientUI &&
      css`
        height: 24px;
        width: auto;
      `}
  }
  @media only screen and (min-width: 960px) {
    flex: 0 calc((100% - 1.5em) / 2);
  }

  @media only screen and (min-width: 1281px) {
    flex: 0 calc((100% - 3em) / 3);
  }

  > header {
    height: 0;
  }

  > section {
    margin-top: 0;
  }
`

export const ProductTileName = styled.p`
  margin-top: 0.625rem;
  ${({ theme }) => theme.textSizes.desktop.bodyL}
`
