import styled from 'styled-components'

export const LayoutContainer = styled.div`
  position: relative;
`

export const ChildrenContainer = styled.div`
  position: relative;
  overflow-y: unset;
  height: 100vh;
`

export const LayoutBackground = styled.div`
  background-color: ${({ theme }) => theme.colors.navigation.navPrimary};
  height: 100vh;
  width: 100%;
  position: absolute;
  z-index: 1;
`

export const LayoutBanner = styled.div`
  position: fixed;
  max-height: 400px;
  left: -1%;
  right: 0%;
  bottom: 25vh;
  z-index: -1;

  img {
    width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.desktopLarge)}) {
    bottom: 20vh;
  }

  @media (max-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletLarge)}) {
    bottom: 30vh;
  }
`

export const LayoutFooter = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 3;
  background-color: ${({ theme }) => theme.colors.navigation.navPrimary};
`
