import { Product } from './Product'
import { Config } from '../../common/types'
import { DashboardIconSvg } from '~shared/common/svgs'

export class HeliosProductBuilder {
  public static build(input: { idToken: string; config: Config; username: string; email: string }): Product {
    const { idToken, config, username } = input
    return {
      name: 'Helios',
      description: 'Dashboard',
      url: `${config.HELIOS_URL}/ssopLogin?username=${username}&linkfrom=MedmemeProducts&clienturl=${config.RETURN_URL}&id_token=${idToken}`,
      icon: <DashboardIconSvg />,
      isOutsideIPRange: false,
    }
  }
}
