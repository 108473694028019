import React from 'react'
import styled, { css } from 'styled-components'
import { Flex } from '~common'

export type FormItemLabelPosition = 'left' | 'top' | 'right'

export type FormItemSize = 'xs' | 'sm' | 'md' | 'lg'

interface StyledFormItemProps {
  formItemSize: FormItemSize
  labelPosition?: FormItemLabelPosition
  isError?: boolean
}

export const StyledFormItemWrapper = styled(Flex)<StyledFormItemProps>`
  width: 100%;
  align-items: ${({ labelPosition }) => (labelPosition === 'top' ? 'start' : 'center')};
  flex-direction: ${({ labelPosition }) => {
    if (labelPosition === 'right') {
      return 'row-reverse'
    }
    if (labelPosition === 'top') {
      return 'column'
    }
    return 'row'
  }};
  ${({ formItemSize, theme }) => {
    if (formItemSize === 'xs' || formItemSize === 'sm') {
      return theme.textSizes.desktop.label
    }
    if (formItemSize === 'md') {
      return theme.textSizes.desktop.bodyS
    }

    if (formItemSize === 'lg') {
      return theme.textSizes.desktop.body
    }
  }}
`

const StyledLabel = styled.label<StyledFormItemProps>`
  color: ${({ isError, theme }) => isError && theme.colors.warnings.alert};
  flex-shrink: 0;
  ${({ formItemSize, labelPosition }) => {
    if (labelPosition === 'left') {
      return css`
        margin-right: ${formItemSize === 'md' || formItemSize === 'lg' ? '0.5rem' : '0.25rem'};
      `
    }
    if (labelPosition === 'top') {
      return css`
        margin-bottom: ${formItemSize === 'md' || formItemSize === 'lg' ? '0.5rem' : '0.25rem'};
      `
    }
    if (labelPosition === 'right') {
      return css`
        margin-left: ${formItemSize === 'md' || formItemSize === 'lg' ? '0.5rem' : '0.25rem'};
      `
    }
  }};
`

export type FormItemProps = React.HTMLAttributes<HTMLDivElement> & {
  formItemSize: FormItemSize
  labelPosition?: FormItemLabelPosition
  htmlFor?: string
  label?: string
  isError?: boolean
}

export const FormItem: React.FC<FormItemProps> = ({
  formItemSize,
  labelPosition = 'left',
  label,
  children,
  htmlFor,
  style,
  isError = false,
  ...rest
}): JSX.Element => (
  <StyledFormItemWrapper formItemSize={formItemSize} labelPosition={labelPosition} style={style} {...rest}>
    {label && (
      <StyledLabel formItemSize={formItemSize} labelPosition={labelPosition} htmlFor={htmlFor} isError={isError}>
        {label}
      </StyledLabel>
    )}
    {children}
  </StyledFormItemWrapper>
)
