import { useEffect, useState } from 'react'
import { Config } from '../../common/types/common-types'
import { getConfig } from '~config'

export const useConfig = (): Config => {
  const [config, setConfig] = useState<Config>({
    COGNITO_SSO_CLIENT_ID: '',
    NODE_ENV: '',
    COGNITO_SSO_DOMAIN: '',
    COGNITO_SSO_REDIRECT_URI: '',
    AWS_API: '',
    AWS_REGION: '',
    ENV: '',
    USER_POOL_ID: '',
    USER_POOL_WEB_CLIENT_ID: '',
    GRAPHQL_ENDPOINT: '',
    INSIGHTMEME_URL: '',
    HELIOS_URL: '',
    PHARMASPECTRA_PLATFORM_URL: '',
    PROFILEMEME_URL: '',
    RETURN_URL: '',
    LOG_LEVEL: '',
    RUM_APP_ID: '',
    RUM_CLIENT_TOKEN: '',
    GIT_HASH: '',
    GOOGLE_ANALYTICS_UA_ID: '',
    GOOGLE_ANALYTICS_GA4_ID: '',
    EE_REDIRECT_URL: '',
  })

  useEffect(() => {
    const newConfig = getConfig()
    setConfig(newConfig)
  }, [setConfig])

  return config
}
