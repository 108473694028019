import React from 'react'
import { RefObject } from 'react'
import styled from 'styled-components'

export const StyledSVG = styled.svg`
  fill: ${({ theme }) => theme.colors.navigation.iconOnNav};
`

export interface CustomSVGProps extends React.HTMLAttributes<SVGSVGElement> {
  width?: string
  height?: string
  ariaHidden?: boolean
  transform?: string
  elementRef?: RefObject<SVGSVGElement>
}

export const CustomSVG: React.FC<CustomSVGProps> = ({
  children,
  width = '16',
  height = '16',
  ariaHidden,
  elementRef,
  ...rest
}): JSX.Element => (
  <StyledSVG
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden={ariaHidden}
    ref={elementRef}
    {...rest}
  >
    {children}
  </StyledSVG>
)
