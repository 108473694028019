import React from 'react'
import { Controller } from 'react-hook-form'
import styled from 'styled-components'
import { StyledRadioContainedButtonGroup } from '~common'
import { RadioContainedButton, RadioContainedButtonProps } from '~components/ui/atoms/radio-contained-button'
import { ControlledProps } from '../types'

const StyledRadioContainedButton = styled(RadioContainedButton)`
  width: auto;
`

export interface RadioContainedButtonItem {
  id: string
  value: string
  label: string
  isDisabled?: boolean
  hideTooltipOnDisabled?: boolean
  icon?: React.ReactElement
}

export type ControlledRadioContainedButtonGroupProps<TFormValues> = ControlledProps<TFormValues> &
  Omit<RadioContainedButtonProps, 'value' | 'isChecked' | 'id' | 'label'> & {
    radios: RadioContainedButtonItem[]
    icon?: React.ReactElement
    className?: string
  }

export const ControlledRadioContainedButtonGroup = <TFormValues extends Record<string, unknown>>({
  name,
  control,
  rules = {},
  errors,
  radios,
  className,
  icon,
  ...restProps
}: ControlledRadioContainedButtonGroupProps<TFormValues>): JSX.Element => (
  <>
    <Controller
      rules={rules}
      control={control}
      name={name}
      defaultValue={restProps.defaultValue}
      render={({ field }) => (
        <StyledRadioContainedButtonGroup className={className}>
          {radios.map((radio: RadioContainedButtonItem) => (
            <StyledRadioContainedButton
              key={radio.id}
              isChecked={field.value === radio.value}
              onChange={field.onChange}
              icon={field.value === radio.value ? radio.icon ?? icon : undefined}
              iconPosition="right"
              onBlur={field.onBlur}
              label={radio.label}
              value={radio.value}
              id={radio.id}
              name={`${name}-${radio.id}`}
              isDisabled={radio.isDisabled}
              hideTooltipOnDisabled={radio.hideTooltipOnDisabled}
              {...restProps}
            />
          ))}
        </StyledRadioContainedButtonGroup>
      )}
    />
    {errors?.[name] && <>{errors?.[name]?.message}</>}
  </>
)
