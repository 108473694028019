import React, { useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { datadogRum } from '@datadog/browser-rum'
import { UserContext } from '~contexts/UserContext'

export interface IAuthRouteProps {
  children: any
  redirectUrl: string
  isAuthRequired: boolean
}

export function AuthRoute(props: IAuthRouteProps) {
  const { children, redirectUrl, isAuthRequired } = props
  const { user: userStatus, userAttributes, loading } = useContext(UserContext)

  const navigate = useNavigate()

  useEffect(() => {
    if (userAttributes && userStatus) {
      const givenName = userAttributes?.given_name
      const familyName = userAttributes?.family_name
      const email = userAttributes?.email

      datadogRum.setUser({
        id: userStatus?.username,
        name: `${givenName} ${familyName}`,
        email: email,
      })
    }
  }, [userAttributes, userStatus])

  useEffect(() => {
    if (loading) {
      return
    }

    if (isAuthRequired && !userStatus) {
      navigate(redirectUrl)
    }

    if (!isAuthRequired && userStatus) {
      navigate(redirectUrl)
    }
  }, [userStatus, navigate, redirectUrl, isAuthRequired, loading])

  if (isAuthRequired && userStatus) return <>{children}</>
  if (!isAuthRequired && !userStatus) return <>{children}</>
  return <></>
}
