import React from 'react'
import { Controller } from 'react-hook-form'
import styled from 'styled-components'
import { BinarySwitch, BinarySwitchProps } from '~components/ui/molecules/binary-switch'
import { ControlledProps } from '../types'

const StyledBinarySwitch = styled(BinarySwitch)`
  width: auto;
`

export type ControlledBinarySwitchProps<TFormValues> = ControlledProps<TFormValues> &
  Omit<BinarySwitchProps, 'selectedValue'>

export const ControlledBinarySwitch = <TFormValues extends Record<string, unknown>>({
  name,
  control,
  rules = {},
  errors,
  ...restProps
}: ControlledBinarySwitchProps<TFormValues>): JSX.Element => (
  <>
    <Controller
      rules={rules}
      control={control}
      name={name}
      defaultValue={restProps.defaultValue}
      render={({ field }) => (
        <StyledBinarySwitch
          selectedValue={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          {...restProps}
        />
      )}
    />
    {errors?.[name] && <>{errors?.[name]?.message}</>}
  </>
)
