import * as React from 'react'
import { useCallback, useState, useEffect } from 'react'
import { LoginTemplate } from '~components/login-template'
import { ProvideEmailForm, ContinueButton, FormHeader, ContinueButtonContainer } from './styles'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useDebounce } from '~common/hooks'
import { useConfig, useLoginSettings } from '~hooks'
import { buildUrl, paths } from '~helpers'
import { useForm } from 'react-hook-form'
import { Controlled } from '~components/ui/controlled'
import usePageTitle from '~hooks/use-page-title'
import { Auth } from 'aws-amplify'
import { redirectToEe } from '~common/ee-redirect'
interface FormValues {
  email: string
}

export function ProvideEmail() {
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const redirectUrl = searchParams.get('redirect_url')
  const userEmail = searchParams.get('user_email') ?? ''

  useEffect(() => {
    if (redirectUrl) {
      sessionStorage.setItem('redirect_url', redirectUrl)
    }
  }, [redirectUrl])

  const [continueText, setContinueText] = useState<string>('Continue')
  const [isTyping, setIsTyping] = useState<boolean>(false)

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<FormValues>({ defaultValues: { email: userEmail } })

  const watchEmail = watch('email')
  const debouncedEmail = useDebounce(watchEmail, 500)
  const { loginSettings, loading: loginSettingsLoading } = useLoginSettings(debouncedEmail)
  const { EE_REDIRECT_URL } = useConfig()

  useEffect(() => {
    setIsTyping(true)
  }, [watchEmail])

  useEffect(() => {
    setIsTyping(false)
  }, [debouncedEmail])

  useEffect(() => {
    if (
      !loginSettings ||
      loginSettings.shouldRedirectToEE ||
      loginSettings.ssoProvider === '' ||
      loginSettings.ssoProvider === 'none'
    ) {
      setContinueText('Continue')
    } else if (userEmail && userEmail.length) {
      Auth.federatedSignIn({
        customProvider:
          loginSettings.ssoProvider === 'Office365' ? 'PS' + loginSettings.ssoProvider : loginSettings.ssoProvider,
      })
    } else {
      setContinueText('Continue with your company credentials')
    }
  }, [loginSettings, userEmail])

  const onSubmitHandler = useCallback(
    (values: FormValues) => {
      if (loginSettings) {
        if (loginSettings.shouldRedirectToEE && EE_REDIRECT_URL) {
          if (redirectUrl) {
            //calculate correct EE redirect url and go there
            redirectToEe(redirectUrl)
          } else {
            // redirect to EE platform login page
            window.location.href = EE_REDIRECT_URL
          }
          return
        } else if (loginSettings.ssoProvider !== '' && loginSettings.ssoProvider !== 'none') {
          Auth.federatedSignIn({
            customProvider:
              loginSettings.ssoProvider === 'Office365' ? 'PS' + loginSettings.ssoProvider : loginSettings.ssoProvider,
          })
          return
        }
      }

      // by default, navigate to nonSSO page
      navigate(buildUrl(paths.nonSSO, [{ id: 'email', value: values.email }]))
    },
    [loginSettings, navigate, EE_REDIRECT_URL, redirectUrl],
  )

  usePageTitle('Sign in | Pharmaspectra')

  return (
    <LoginTemplate>
      <ProvideEmailForm onSubmit={handleSubmit(onSubmitHandler)}>
        <FormHeader level="2">Sign in</FormHeader>
        <Controlled.Input
          inputSize="md"
          name="email"
          type="email"
          role="title"
          data-testid="email-input"
          control={control}
          rules={{
            required: 'Email is required',
          }}
          label="Email"
          labelPosition="top"
          errors={errors}
        />
        <ContinueButtonContainer>
          <ContinueButton
            type="submit"
            variant="primary"
            size="lg"
            isDisabled={watchEmail === '' || loginSettingsLoading || isTyping}
          >
            {continueText}
          </ContinueButton>
        </ContinueButtonContainer>
      </ProvideEmailForm>
    </LoginTemplate>
  )
}
