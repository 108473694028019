import {
  ChangePasswordForm,
  ChangePasswordLayout,
  ErrorLabel,
  PharmaspectraBrandLogoWithLeftPadding,
} from '~pages/change-password/styles'
import { ContinueButton } from '~pages/provide-email/styles'
import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { PasswordInput } from '~pages/login-nonsso/styles'
import { useForm } from 'react-hook-form'
import { passwordValidationRules, Status } from '~common'
import { Auth } from 'aws-amplify'
import { SuccessSvg, WarningSvg } from '~common/svgs'
import { Toast } from '~shared/ui/molecules/toast'
import { useNavigate } from 'react-router-dom'
import { paths } from '~helpers'
import { Heading } from '~shared/ui/atoms/heading'
import usePageTitle from '~hooks/use-page-title'

interface FormValues {
  password: string
  newPassword: string
  confirmPassword: string
}

export function ChangePassword() {
  const navigate = useNavigate()
  const [success, setSuccess] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [passwordMatchErr, setPasswordMatchErr] = useState<string>('')

  const defaultValues: FormValues = {
    password: '',
    newPassword: '',
    confirmPassword: '',
  }

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<FormValues>({ defaultValues })

  const passwordWatch = watch('password')
  const newPasswordWatch = watch('newPassword')
  const confirmPasswordWatch = watch('confirmPassword')

  useEffect(() => {
    if (confirmPasswordWatch !== newPasswordWatch) {
      setPasswordMatchErr('Passwords do not match')
    } else {
      setPasswordMatchErr('')
    }
  }, [newPasswordWatch, confirmPasswordWatch, errors])

  const onSubmit = useCallback(() => {
    setError('')
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, passwordWatch, newPasswordWatch)
      })
      .then((data) => {
        if (data === 'SUCCESS') setSuccess(true)
      })
      .catch((err) => {
        if (err.message.includes('Incorrect username or password')) setError('incorrectPassword')
        else if (err.message.includes('Attempt limit exceeded')) setError('attemptLimit')
      })
  }, [passwordWatch, newPasswordWatch])

  let errorTitle = 'An error occured'
  let errorDescription = 'Please try again after some time'

  if (error === 'attemptLimit') {
    errorTitle = 'Attempt limit exceeded'
    errorDescription = 'Please try after some time'
  } else if (error === 'incorrectPassword') {
    errorTitle = 'Incorrect current password'
    errorDescription = 'Please enter valid current password'
  }

  let content = (
    <>
      <Heading level="2">Change Password</Heading>
      {error && (
        <Toast
          id="2"
          title={errorTitle}
          description={errorDescription}
          status={Status.ERROR}
          icon={<WarningSvg />}
          isRemovable={true}
        />
      )}
      <PasswordInput
        inputSize="md"
        data-testid="current-password"
        name="password"
        type="password"
        id="password"
        label="Current password"
        labelPosition="top"
        control={control}
        errors={errors}
        rules={{
          required: 'This password is required',
        }}
      />

      <PasswordInput
        inputSize="md"
        data-testid="new-password"
        id="newPassword"
        name="newPassword"
        type="password"
        label="New password"
        labelPosition="top"
        control={control}
        errors={errors}
        rules={{
          required: 'This password is required',
          validate: passwordValidationRules,
        }}
      />

      <PasswordInput
        inputSize="md"
        data-testid="confirm-password"
        id="confirmPassword"
        name="confirmPassword"
        type="password"
        label="Confirm new password"
        labelPosition="top"
        control={control}
        errors={errors}
        rules={{
          required: 'This password is required',
          validate: passwordValidationRules,
        }}
      />
      <ErrorLabel>{passwordMatchErr}</ErrorLabel>
      <ContinueButton type="submit" variant="primary" size="lg" isDisabled={passwordMatchErr.length > 0}>
        Submit
      </ContinueButton>
    </>
  )

  if (success)
    content = (
      <>
        <Heading level="2">Success</Heading>
        <Toast
          id="1"
          icon={<SuccessSvg />}
          status={Status.SUCCESS}
          isRemovable={true}
          title="Password changed succesfully"
          description="Please continue to the dashboard"
        />
        <ContinueButton size="lg" onClick={() => navigate(paths.products)}>
          Back to dashboard
        </ContinueButton>
      </>
    )

  usePageTitle('Change password | Pharmaspectra')

  return (
    <>
      <ChangePasswordLayout>
        <PharmaspectraBrandLogoWithLeftPadding
          isTouchable={false}
          logoSize="lg"
          logoVariant="primary"
          withNextLink={false}
        />
        <ChangePasswordForm onSubmit={handleSubmit(onSubmit)}>{content}</ChangePasswordForm>
      </ChangePasswordLayout>
    </>
  )
}
