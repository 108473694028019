import React from 'react'
import { ButtonWithNoStyle } from '~common'
import styled from 'styled-components'

const StyledIconButton = styled(ButtonWithNoStyle)`
  & > svg {
    vertical-align: bottom;
  }
`

const StyledA11yTitle = styled.span`
  position: absolute;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  text-transform: none;
  white-space: nowrap;
`

export interface IconButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  icon: React.ReactNode
  a11yTitle: string
  type?: 'button' | 'submit' | 'reset'
}

export const IconButton = ({ icon, a11yTitle, type, ...rest }: IconButtonProps): JSX.Element => (
  <StyledIconButton {...rest} type={type}>
    {icon}
    <StyledA11yTitle>{a11yTitle}</StyledA11yTitle>
  </StyledIconButton>
)
