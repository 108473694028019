import React from 'react'
import styled from 'styled-components'
import { Flex, Status } from '~common'
import { Heading } from '~components/ui/atoms/heading'

const isDeployed = process.env.NODE_ENV === 'production' && process.env.ENV && process.env.IS_LOCAL !== 'true'

const StyledErrorPageBlock = styled.div`
  width: 18rem;
  padding: 1rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
    width: 35rem;
  }
`

const StyledIconWrapper = styled(Flex)<{ errorType: Status.ERROR | Status.NOTIFICATION }>`
  margin-bottom: 1rem;
  justify-content: center;
  svg {
    width: 4rem;
    height: 4rem;
    text-align: center;
  }
  & > svg,
  & > svg > path {
    fill: ${({ theme, errorType }) =>
      errorType === Status.ERROR ? theme.colors.warnings.alert : theme.colors.neutrals.neutral80};
  }
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
    svg {
      width: 6rem;
      height: 6rem;
    }
  }
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletMedium)}) {
    margin-bottom: 1.5rem;
  }
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.desktopMedium)}) {
    svg {
      width: 8rem;
      height: 8rem;
    }
  }
`

const StyledTitle = styled(Heading)`
  ${({ theme }) => theme.textSizes.mobile.h2};
  text-align: center;
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
    ${({ theme }) => theme.textSizes.desktop.h2};
  }
`

const StyledText = styled.p`
  ${({ theme }) => theme.textSizes.desktop.body};
  margin-top: 1rem;
  text-align: center;
`

export interface ErrorPageBlockProps extends React.HTMLAttributes<HTMLDivElement> {
  errorMessage: string
  errorTitle?: string
  icon?: React.ReactNode
  errorType?: Status.ERROR | Status.NOTIFICATION
}

export const ErrorPageBlock = ({
  errorTitle = 'Sorry',
  errorMessage,
  icon,
  errorType = Status.ERROR,
  ...restProps
}: ErrorPageBlockProps): JSX.Element => (
  <StyledErrorPageBlock {...restProps}>
    {icon && <StyledIconWrapper errorType={errorType}>{icon}</StyledIconWrapper>}
    <StyledTitle>{errorTitle}</StyledTitle>
    <StyledText>{errorMessage}</StyledText>
    <p aria-hidden={true} hidden>
      [Pharmaspectra Platform version: {isDeployed ? process.env.GIT_HASH : 'local'}]
    </p>
  </StyledErrorPageBlock>
)
