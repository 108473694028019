import { Product } from './Product'
import { Config } from '../../common/types'
import * as React from 'react'
import { ProfileMemeIcon } from '../../common/icons'

export class ProfileMemeProductBuilder {
  public static build(input: { idToken: string; config: Config; username: string; email: string }): Product {
    const { idToken, config, username } = input
    return {
      name: 'ProfileMeme',
      description: 'Opinion Leaders',
      url: `${config.PROFILEMEME_URL}/login/doLogin.action?username=${username}&linkfrom=MedmemeProducts&clienturl=${config.RETURN_URL}&id_token=${idToken}`,
      icon: <ProfileMemeIcon />,
      isOutsideIPRange: false,
    }
  }
}
