import styled from 'styled-components'
import { ErrorPageBlock } from '~components/ui/molecules/error-page-block'
import { Button } from '~shared/ui/atoms/button'

export const StyledErrorPageBlock = styled(ErrorPageBlock)`
  svg,
  svg > path {
    fill: ${({ theme }) => theme.colors.textOn.textOnPrimary};
  }
  h1 {
    color: ${({ theme }) => theme.colors.textOn.textOnPrimary};
  }
`

export const Container = styled.div`
  display: flex;
  display: -ms-flexbox;
  justify-content: center;
  align-items: center;
  height: 200vh;
  flex-direction: row;
`
export const Row = styled.div`
  display: flex;
  flex-flow: wrap;
  position: absolute;
`

export const Column = styled.div`
  flex: 50%;
`

export const BackButton = styled(Button)`
  width: max-content;
  margin: 15px auto 0px 15px;
`
