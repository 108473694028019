import React, { useState } from 'react'
import { FormItem, FormItemSize, FormItemLabelPosition } from '~components/ui/atoms/form-item'
import { StyledCustomSelect, StyledCustomSelectWrapper } from './styles'
import { useOutsideClick } from '~common/hooks'
import { ChevronIcon } from '~components/ui/atoms/chevron-icon'
import { chevronSize } from '../Select'
import { Dropdown, DropdownOption } from '~components/ui/molecules/dropdown'
export interface SelectWithFilteredOptionsDropdownProps extends React.HTMLAttributes<HTMLSelectElement> {
  id: string
  options: DropdownOption[]
  selectDropdownSize?: FormItemSize
  labelPosition?: FormItemLabelPosition
  label?: string
  isDisabled?: boolean
  customDropdownItems?: { value: string; label: string }[]
  dropdownTitle?: string | JSX.Element
  value?: DropdownOption
  onOptionSelect?: (option: DropdownOption) => void
}

export const SelectWithFilteredOptionsDropdown = ({
  id,
  label,
  selectDropdownSize = 'sm',
  labelPosition = 'top',
  isDisabled,
  style,
  className,
  options,
  value,
  dropdownTitle,
  placeholder,
  onOptionSelect,
}: SelectWithFilteredOptionsDropdownProps): JSX.Element => {
  const [selectedOption, setSelectedOption] = useState<DropdownOption | undefined>(value)
  const {
    ref: selectDropdownRef,
    state: isOpenDropdown,
    action: setIsOpenDropdown,
  } = useOutsideClick<HTMLDivElement>(false)

  const onSelectOptionHandler = (option: DropdownOption) => {
    setSelectedOption(option)
    setIsOpenDropdown(false)
    if (onOptionSelect) {
      onOptionSelect(option)
    }
  }

  return (
    <FormItem
      className={className}
      formItemSize={selectDropdownSize}
      labelPosition={labelPosition}
      style={style}
      label={label}
      htmlFor={`select-${id}`}
    >
      <StyledCustomSelectWrapper ref={selectDropdownRef}>
        <StyledCustomSelect
          id={`select-${id}`}
          type="button"
          selectDropdownSize={selectDropdownSize}
          isOpened={isOpenDropdown}
          aria-expanded={isOpenDropdown}
          data-toggle="dropdown"
          aria-haspopup={true}
          disabled={isDisabled}
          onClick={() => setIsOpenDropdown(!isOpenDropdown)}
        >
          {selectedOption ? selectedOption.label : placeholder}
          <ChevronIcon
            position="right"
            rotation="top-bottom"
            width={chevronSize[selectDropdownSize].size}
            height={chevronSize[selectDropdownSize].size}
            pathDValue={chevronSize[selectDropdownSize].dValue}
            isOpened={isOpenDropdown}
          />
        </StyledCustomSelect>
        <Dropdown.WithOptions
          isOpen={isOpenDropdown}
          dropdownOptions={options.filter((o) => o.id !== selectedOption?.id)}
          ariaLabel={`select-${id} options`}
          onBlur={() => setIsOpenDropdown(false)}
          onFocus={() => setIsOpenDropdown(true)}
          onSelectOption={onSelectOptionHandler}
          dropdownTitle={dropdownTitle}
        />
      </StyledCustomSelectWrapper>
    </FormItem>
  )
}
