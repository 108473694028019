import { gql } from '@apollo/client'

export const GET_FEATURES_FOR_USER = gql`
  query GetFeaturesForUserQuery {
    getFeaturesForUser {
      experts
      sosv
      general
      science
    }
  }
`
