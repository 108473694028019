import { useEffect, useState } from 'react'
import { useConfig, useToken } from '~hooks'

export interface GenerateZendeskSsoTokenQuery {
  generateZendeskSsoToken: string
}

export const useZendeskUrl = (): { zendeskUrl: string } => {
  const config = useConfig()
  const [zendeskUrl, setZendeskUrl] = useState('')
  const { idToken: token } = useToken()

  useEffect(() => {
    if (token) {
      setZendeskUrl('https://medmeme.zendesk.com')
      fetch(config.GRAPHQL_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          query: `
            query GenerateZendeskSsoTokenQuery {
              generateZendeskSsoToken
            }`,
          variables: {},
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.error) {
          } else if (result.data?.generateZendeskSsoToken) {
            const zendeskToken = result.data?.generateZendeskSsoToken
            setZendeskUrl(`https://medmeme.zendesk.com/access/jwt?jwt=${zendeskToken}`)
          }
        })
    }
  }, [token, config.GRAPHQL_ENDPOINT])

  return {
    zendeskUrl,
  }
}
