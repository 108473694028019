import styled from 'styled-components'

export const ProductListBoundingBox = styled.div`
  position: relative;
  left: 0;
  width: 100vw;
  margin-top: 3rem;
  overflow-y: auto;
  height: calc(100vh - 8rem - 100px);

  @media only screen and (max-width: 900px) {
    height: calc(100vh - 8rem - 129px);
  }
`

export const ProductListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 1.5em 0 1.5em;
  gap: 1.5em;
`

export const NoProductsListBoundingBox = styled.div`
  position: fixed;
  z-index: 100;
  left: 0;
  width: 100vw;
  height: calc(100vh - 4em);
  background-color: ${({ theme }) => theme.colors.always.alwaysLight};
`

export const NoProductsListContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  p {
    ${({ theme }) => theme.textSizes.desktop.body}
    color: ${({ theme }) => theme.colors.text.textPrimary};
  }
`

export const StyledToast = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -10em 5em 0 0;
  gap: 1.5em;
  float: right;
  width: 20em;
  height: 7em;
`
