import styled from 'styled-components'
import { flexMixin } from '~common'
import { Tooltip } from '~components/ui/molecules/tooltip'

export const StyledTooltip = styled(Tooltip)`
  padding: 0;
`

export const StyledRadioLabel = styled.label<{
  isChecked: boolean
  isDisabled: boolean
}>`
  ${flexMixin};
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  color: ${({ isDisabled, theme }) => (isDisabled ? theme.colors.neutrals.neutral60 : theme.colors.text.textPrimary)};
  align-items: center;
  width: 100%;
  position: relative;
`

export const StyledHiddenRadio = styled.input.attrs({ type: 'radio' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`
