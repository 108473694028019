import styled, { css, DefaultTheme } from 'styled-components'
import { Flex, flexMixin } from '~common'
import { LabelPosition, RadioSize } from './Radio'

export const StyledHiddenRadio = styled.input.attrs({ type: 'radio' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

interface StyledRadioProps {
  isChecked: boolean
  isDisabled: boolean
  radioSize: RadioSize
  labelPosition: LabelPosition
}

export const StyledRadio = styled(Flex)<StyledRadioProps>`
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: ${({ radioSize }) => (radioSize === 'sm' ? '0.75rem' : '0.875rem')};
  height: ${({ radioSize }) => (radioSize === 'sm' ? '0.75rem' : '0.875rem')};
  cursor: ${({ isDisabled }) => !isDisabled && 'pointer'};
  transition: all 0.24s ease-in-out;
  ${({ radioSize, labelPosition }) => {
    if (labelPosition === 'left') {
      return css`
        margin-left: ${radioSize === 'sm' ? '0.25rem' : '0.5rem'};
      `
    }
    if (labelPosition === 'right') {
      return css`
        margin-right: ${radioSize === 'sm' ? '0.25rem' : '0.5rem'};
      `
    }
  }};
`

export const StyledCheckIcon = styled.div<{ radioSize: RadioSize }>`
  width: ${({ radioSize }) => {
    if (radioSize === 'sm') return '0.375rem'
    if (radioSize === 'md') return '0.5rem'
  }};
  height: ${({ radioSize }) => {
    if (radioSize === 'sm') return '0.375rem'
    if (radioSize === 'md') return '0.5rem'
  }};
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.forms.iconOnPrimary};
`

const mixinBgAndBorder = ({
  isChecked,
  isDisabled,
  theme,
}: {
  isChecked: boolean
  isDisabled: boolean
  theme: DefaultTheme
}) => {
  if (isDisabled && !isChecked)
    return css`
      opacity: 0.2;
      border: 1px solid ${theme.colors.neutrals.neutral40};
      background: ${theme.colors.forms.checkboxBackground};
    `
  if (isDisabled && isChecked)
    return css`
      opacity: 0.2;
      background: ${theme.colors.brand.bluePrimary};
    `
  if (!isChecked)
    return css`
      background: ${theme.colors.forms.checkboxBackground};
      border: 1px solid ${theme.colors.neutrals.neutral40};
      &:hover {
        background: ${theme.colors.forms.checkboxBackground};
        border: 1px solid ${({ theme }) => theme.colors.neutrals.neutral60};
      }
    `
  if (isChecked)
    return css`
      background: ${theme.colors.brand.bluePrimary};
    `
}

export const StyledRadioLabel = styled.label<{
  isChecked: boolean
  isDisabled: boolean
  labelPosition: LabelPosition
}>`
  ${flexMixin};
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  color: ${({ isDisabled, theme }) => (isDisabled ? theme.colors.neutrals.neutral60 : theme.colors.text.textPrimary)};
  align-items: center;
  flex-direction: ${({ labelPosition }) => (labelPosition === 'left' ? 'row' : 'row-reverse')};
  ${StyledCheckIcon} {
    visibility: ${({ isChecked }) => (isChecked ? 'visible' : 'hidden')};
  }
  ${StyledHiddenRadio}:focus + ${StyledRadio} {
    ${({ isChecked, isDisabled }) =>
      !isChecked &&
      !isDisabled &&
      css`
        border: 1px solid ${({ theme }) => theme.colors.neutrals.neutral60};
      `};
  }
  ${StyledHiddenRadio}:focus:not(:focus-visible) + ${StyledRadio} {
    ${({ isChecked, isDisabled, theme }) => mixinBgAndBorder({ isChecked, isDisabled, theme })}
  }
  ${StyledHiddenRadio}:focus-visible + ${StyledRadio} {
    outline: -webkit-focus-ring-color auto 1px;
    ${({ isChecked, isDisabled }) =>
      !isChecked &&
      !isDisabled &&
      css`
        border: 1px solid ${({ theme }) => theme.colors.neutrals.neutral60};
      `};
  }
  &:hover ${StyledRadio} {
    ${({ isChecked, isDisabled, theme }) =>
      !isChecked &&
      !isDisabled &&
      css`{
       background: ${theme.colors.forms.checkboxBackground};
        border: 1px solid ${theme.colors.neutrals.neutral60};
        border-radius: 50%;
      }
    }`}
  }
  ${StyledRadio} {
    ${({ isChecked, isDisabled, theme }) => mixinBgAndBorder({ isChecked, isDisabled, theme })}
  }
`
