import React, { RefObject } from 'react'
import { FormItemLabelPosition, FormItemSize } from '~components/ui/atoms/form-item'
import { LoadingIndicator } from '~components/ui/atoms/loading-indicator'
import { FormItem } from '~components/ui/atoms/form-item'
import {
  StyledInput,
  StyledInputWrapper,
  StyledErrorMessage,
  StyledMaxCharLimit,
  StyledMaxCharAndErrorWrapper,
  StyledLoadingIndicatorWrapper,
} from './styles'
import { useExpertEcosystemContext } from '~common/providers'
interface Error {
  isError: boolean
  message?: string
}

export type InputProps = React.HTMLAttributes<HTMLInputElement> & {
  inputSize: FormItemSize
  isLoading?: boolean
  value?: string
  error?: Error
  labelPosition?: FormItemLabelPosition
  label?: string
  isDisabled?: boolean
  type?: string
  autoComplete?: boolean
  maxCharLimit?: number
  id?: string
  itemRef?: RefObject<HTMLInputElement>
}

export const Input = ({
  id,
  autoComplete,
  placeholder,
  label,
  inputSize = 'sm',
  labelPosition = 'top',
  type = 'text',
  isDisabled,
  style,
  value = '',
  onChange,
  className,
  error,
  maxCharLimit,
  isLoading,
  itemRef,
  ...rest
}: InputProps): JSX.Element => {
  const { EELoadingComponent } = useExpertEcosystemContext() ?? {}
  return (
    <FormItem
      formItemSize={inputSize}
      labelPosition={labelPosition}
      style={style}
      label={label}
      htmlFor={id}
      className={className}
      isError={error?.isError}
    >
      <StyledInputWrapper>
        <StyledInput
          aria-label={!label ? 'text-box' : label}
          isError={error?.isError}
          isLoading={isLoading}
          autoComplete={autoComplete ? 'on' : 'off'}
          type={type}
          inputSize={inputSize}
          disabled={isDisabled}
          placeholder={placeholder}
          id={id}
          maxLength={maxCharLimit}
          value={value}
          onChange={onChange}
          ref={itemRef}
          {...rest}
        />
        {isLoading && (
          <>
            {!!EELoadingComponent ? (
              <EELoadingComponent zIndex={100} bgOpacity={0} message="Please wait" isAbsolutePosition={false} />
            ) : (
              <StyledLoadingIndicatorWrapper data-testid="loading-indicator">
                <LoadingIndicator size="sm" />
              </StyledLoadingIndicatorWrapper>
            )}
          </>
        )}
      </StyledInputWrapper>
      {(maxCharLimit || error?.message) && (
        <StyledMaxCharAndErrorWrapper>
          {maxCharLimit && <StyledMaxCharLimit>{`${value.length}/${maxCharLimit}`}</StyledMaxCharLimit>}
          {error?.message && <StyledErrorMessage isAlignedRight={!!maxCharLimit}>{error.message}</StyledErrorMessage>}
        </StyledMaxCharAndErrorWrapper>
      )}
    </FormItem>
  )
}
