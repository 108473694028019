import { RequestDemoContainer, RequestDemoHeader, RequestDemoContent, StyledButton } from './styles'
import React from 'react'

export function RequestDemo() {
  return (
    <RequestDemoContainer>
      <RequestDemoHeader>Not a subscriber?</RequestDemoHeader>
      <RequestDemoContent>
        We deliver complete medical expert identification, profiling and connection mapping, alongside comprehensive
        updates on the latest clinical science to enable you to make key decisions with our world leading data.
      </RequestDemoContent>

      <a href="https://www.pharmaspectra.com/request-a-demo/" target="_blank" rel="noreferrer">
        <StyledButton data-testid="pdf-download" size="sm" variant="secondary">
          Request a demo
        </StyledButton>
      </a>
    </RequestDemoContainer>
  )
}
