import { createGlobalStyle } from 'styled-components'
import { Theme } from '~common/theme'

export const GlobalStyles = createGlobalStyle<{ theme: Theme }>`
  html {
    font-family: ${({ theme }) => theme.fontFamily};
    height: -webkit-fill-available;
    overflow-x: hidden;
  }

  body {
    min-height: 100vh;
    min-width: 20rem;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${({ theme }) => theme.colors.text.textPrimary};
    min-height: -webkit-fill-available;
    background: ${({ theme }) => theme.colors.always.alwaysLight};
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  ul,
  ol {
    padding: 0;
    list-style: none;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol,
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd,
  input,
  select,
  textarea {
    margin: 0;
  }

  a {
    text-decoration: none;
    text-decoration-skip-ink: auto;
  }

  img {
    max-width: 100%;
    display: block;
  }

  input,
  button,
  textarea,
  select {
    font: inherit;
    border-radius: 0;
  }

  input:active,
  input:focus,
  textarea:active,
  textarea:focus,
  select:active,
  select:focus {
    border-radius: 0;
  }

  span.hlt {
    background-color: #e7c7e0;
    padding: 0.125rem 0.25rem;
  }

  .sb-show-main {
    background-color: white;
  }

  .leaflet-div-icon {
    background: none !important;
    border: none !important;
  }

  @media only screen and (prefers-color-scheme: dark) {
    body {
      color: ${({ theme }) => theme.colors.always.alwaysLight};
      background: ${({ theme }) => theme.colors.brand.backgroundPrimary};
    }

    span.hlt {
      background-color: #a02084;
    }
  }

   // highcharts styles
  .highcharts-tooltip, .highcharts-tooltip-box, .highcharts-tooltip .highcharts-label-box {
    font-size: 0.75rem;
    line-height: 1rem;
    stroke: ${({ theme }) => theme.colors.dataVisualisation.tooltipBackground};
    fill: ${({ theme }) => theme.colors.dataVisualisation.tooltipBackground};
    padding: 4px;
    fill-opacity: 1;
  }

  .highcharts-tooltip.custom-tooltip {
    fill: none;
    stroke: none;
  }

  .highcharts-tooltip span {
    color: #ddd9d4;
    font-size: 0.75rem !important;
    line-height: 1rem;
  }

  .highcharts-column-series .highcharts-point-hover {
    fill-opacity: 0.48;
  }

  .highcharts-tooltip .key-value-wrapper{
    display: flex;
    justify-content: space-between;
  }

  .highcharts-tooltip .key {
    margin-right: 20px;
    color: #ddd9d4;
  }

  .highcharts-tooltip .value {
    font-weight: 600;
    color: #f9f8f7;
    flex-grow: 1;
    text-align: right;
  }

  .highcharts-tooltip .optionalValue {
    color: #f9f8f7;
    margin-left: 4px;
  }

  .highcharts-legend-item > text,
  .highcharts-axis-labels > text {
    fill: #67625d !important;
    font-size: 0.65rem !important;
    line-height: 1rem;
  }

  .highcharts-yaxis-grid .highcharts-grid-line {
    stroke: #eeece9;
  }

  .regression-line-up path {
    stroke: #006a20;
  }

  .regression-line-straight path {
    stroke: #67625d;
  }

  .regression-line-down path {
    stroke: #d32f2f;
  }

  .highcharts-markers {
    opacity: 1;
  }

  .padding-sm {
    padding: 4px;
  }

  @media only screen and (prefers-color-scheme: dark) {
    .highcharts-background {
      fill: transparent;
    }
    .highcharts-legend-item > text,
    .highcharts-axis-labels > text {
      fill: #ddd9d4 !important;
      color: #ddd9d4 !important;
    }

    .highcharts-yaxis-grid .highcharts-grid-line {
      stroke: #67625d;
    }

    .regression-line-up path {
      stroke: #96d59e;
    }

    .regression-line-straight path {
      stroke: #eeece9;
    }

    .regression-line-down path {
      stroke: #ef5350;
    }

    .highcharts-tooltip {
      stroke: #eeece9;
      fill: #eeece9;
    }

    .highcharts-tooltip .key {
      color: #67625d;
    }

    .highcharts-tooltip .value {
      color: #3d3b37;
    }
  }
`
