import styled from 'styled-components'
import { Button } from '~shared/ui/atoms/button'
import { Flex } from '~common'
import { Heading } from '~components/ui/atoms/heading'

export const ProvideEmailForm = styled(Flex).attrs({ as: 'form' })`
  justify-content: space-between;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.brand.backgroundSecondary};
  padding: ${({ theme }) => theme.spacing(2.4)};
  border-radius: 1.5px;
  color: ${({ theme }) => theme.colors.text.textPrimary};
`

export const FormHeader = styled(Heading)`
  margin-bottom: 25px;
`

export const ContinueButton = styled(Button)`
  width: max-content;
  margin: 15px auto 0px auto;
`

export const ContinueButtonContainer = styled.div`
  margin: 0 auto;
`
