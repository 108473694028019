import { Dispatch, RefObject, SetStateAction, useEffect, useRef, useState } from 'react'

export const useOutsideClick = <T extends HTMLElement>(
  initialState: boolean,
): {
  ref: RefObject<T>
  state: boolean
  action: Dispatch<SetStateAction<boolean>>
} => {
  const [state, action] = useState(initialState)
  const ref = useRef<T>(null)

  const handleClickOutside = (event: Event) => {
    if (ref.current && event.target instanceof Node && !ref.current.contains(event.target)) {
      action(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  return { ref, state, action }
}
