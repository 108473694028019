import { PharmaspectraIconSvg } from '~shared/common/svgs'
import { Product } from './Product'
import { Config } from '../../common/types'

export class PharmaspectraPlaftormProductBuilder {
  public static build(input: { idToken: string; config: Config; username: string; email: string }): Product {
    const { config } = input
    return {
      name: 'Pharmaspectra Platform',
      description: 'Opinion Leaders',
      url: config.PHARMASPECTRA_PLATFORM_URL,
      icon: <PharmaspectraIconSvg />,
      isOutsideIPRange: false,
    }
  }
}
