import React from 'react'
import styled from 'styled-components'
import { Flex } from '~common'
import { RadioContainedButton } from '~components/ui/atoms/radio-contained-button'

const StyledRadioContainedButton = styled(RadioContainedButton)`
  width: 100%;
  justify-content: center;
`

interface Option {
  value: string
  name: string
  label: string
  isDisabled?: boolean
  icon?: React.ReactNode
}

export type BinarySwitchProps = React.HTMLAttributes<HTMLInputElement> & {
  optionLeft: Option
  optionRight: Option
  selectedValue: string
}

export const BinarySwitch = ({ optionLeft, optionRight, selectedValue, ...rest }: BinarySwitchProps): JSX.Element => (
  <Flex>
    <StyledRadioContainedButton
      id={optionLeft.name}
      isChecked={selectedValue === optionLeft.value}
      isDisabled={optionLeft.isDisabled}
      label={optionLeft.label}
      value={optionLeft.value}
      name={optionLeft.name}
      rounded="left"
      icon={optionLeft.icon}
      {...rest}
    />
    <StyledRadioContainedButton
      id={optionLeft.name}
      isChecked={selectedValue === optionRight.value}
      isDisabled={optionRight.isDisabled}
      label={optionRight.label}
      value={optionRight.value}
      name={optionRight.name}
      rounded="right"
      icon={optionRight.icon}
      {...rest}
    />
  </Flex>
)
