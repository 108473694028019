import * as React from 'react'
import { PharmaspectraBrandLogo } from '~shared/ui/molecules/brand-logo'
import { CenteredFlex } from '~shared/common'
import { ForgotPasswordTemplateContainer, Column, Row, StyledLogoSection } from './styles'

export interface IForgotPasswordTemplateProps {
  children: React.ReactElement
}

export function ForgotPasswordTemplate(props: IForgotPasswordTemplateProps) {
  return (
    <ForgotPasswordTemplateContainer>
      <Row>
        <Column>
          <StyledLogoSection>
            <CenteredFlex>
              <PharmaspectraBrandLogo isTouchable={false} logoSize="lg" logoVariant="primary" withNextLink={false} />
            </CenteredFlex>
          </StyledLogoSection>
        </Column>
        <Column>{props.children}</Column>
      </Row>
    </ForgotPasswordTemplateContainer>
  )
}
