import { CognitoUser } from 'amazon-cognito-identity-js'
import { createContext } from 'react'

export interface UserAttributes {
  email: string
  email_verified: string
  family_name: string
  given_name: string
  name: string
  sub: string
  identities?: { userId: string; providerName: string }[]
  ssoProvider?: string
}

export interface IUserContext {
  user?: CognitoUser & { username: string }
  userAttributes?: UserAttributes
  clientId?: number
  setClientId?: (clientId: number) => void
  loading: boolean
}

export const UserContext = createContext<IUserContext>({} as IUserContext)
