import React from 'react'
import { Controller } from 'react-hook-form'
import { Select, SelectWithSystemDropdownProps } from '~components/ui/molecules/select'
import { ControlledProps } from '../types'
import { SelectError } from './styles'

export interface ControlledSelectWithSystemDropdownProps<TFormValues>
  extends ControlledProps<TFormValues>,
    SelectWithSystemDropdownProps {
  options: { value: string; label: string }[]
}

export const ControlledSelectWithSystemDropdown = <TFormValues extends Record<string, unknown>>({
  rules,
  control,
  name,
  options,
  errors,
  defaultValue,
  ...selectProps
}: ControlledSelectWithSystemDropdownProps<TFormValues>): JSX.Element => (
  <Controller
    rules={rules}
    control={control}
    name={name}
    defaultValue={defaultValue}
    render={({ field }) => (
      <>
        <Select.WithSystemDropdown {...selectProps} onChange={field.onChange} value={field.value || defaultValue}>
          {options.map((option) => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select.WithSystemDropdown>
        {errors?.[name] && <SelectError>{errors?.[name]?.message}</SelectError>}
      </>
    )}
  />
)
