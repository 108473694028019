import { Config } from './common/types/'

export const getConfig = (): Config => {
  const config: Config = {
    ENV: process.env.REACT_APP_ENV as string,
    NODE_ENV: process.env.NODE_ENV as string,
    AWS_API: process.env.REACT_APP_AWS_API as string,
    AWS_REGION: process.env.REACT_APP_AWS_REGION as string,
    USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID as string,
    USER_POOL_WEB_CLIENT_ID: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID as string,
    COGNITO_SSO_DOMAIN: process.env.REACT_APP_COGNITO_SSO_DOMAIN as string,
    COGNITO_SSO_REDIRECT_URI: process.env.REACT_APP_COGNITO_SSO_REDIRECT_URI as string,
    COGNITO_SSO_CLIENT_ID: process.env.REACT_APP_COGNITO_SSO_CLIENT_ID as string,
    GRAPHQL_ENDPOINT: process.env.REACT_APP_GRAPHQL_ENDPOINT as string,
    INSIGHTMEME_URL: process.env.REACT_APP_INSIGHTMEME_URL as string,
    PHARMASPECTRA_PLATFORM_URL: process.env.REACT_APP_PHARMASPECTRA_PLATFORM_URL as string,
    HELIOS_URL: process.env.REACT_APP_HELIOS_URL as string,
    PROFILEMEME_URL: process.env.REACT_APP_PROFILEMEME_URL as string,
    RETURN_URL: process.env.REACT_APP_RETURN_URL as string,
    LOG_LEVEL: process.env.REACT_APP_LOG_LEVEL ?? 'INFO',
    RUM_APP_ID: process.env.REACT_APP_RUM_APP_ID as string,
    RUM_CLIENT_TOKEN: process.env.REACT_APP_RUM_CLIENT_TOKEN as string,
    GIT_HASH: process.env.REACT_APP_GIT_HASH as string,
    GOOGLE_ANALYTICS_UA_ID: process.env.REACT_APP_GOOGLE_ANALYTICS_UA_ID as string,
    GOOGLE_ANALYTICS_GA4_ID: process.env.REACT_APP_GOOGLE_ANALYTICS_GA4_ID as string,
    EE_REDIRECT_URL: process.env.REACT_APP_EE_REDIRECT_URL as string,
  }

  if (process.env.NODE_ENV !== 'test') {
    Object.entries(config).forEach(([key, value]) => {
      if (!value || value === 'null') {
        throw new Error(
          `getConfig failed - environment variable ${key} is missing. Make sure you have added the environment variable to config.ts and next.config.js`,
        )
      }
    })
  }
  return { ...config }
}
