import * as React from 'react'
import banner from './banner.svg'
import { Footer } from '~components/footer'
import { ChildrenContainer, LayoutBackground, LayoutBanner, LayoutContainer, LayoutFooter } from './styles'

export interface ILayoutProps {
  children: any
}

export function Layout(props: ILayoutProps) {
  return (
    <LayoutContainer>
      <LayoutBackground>
        <ChildrenContainer>{props.children}</ChildrenContainer>
        <LayoutBanner>
          <img src={banner} alt="banner" />
        </LayoutBanner>
        <LayoutFooter>
          <Footer />
        </LayoutFooter>
      </LayoutBackground>
    </LayoutContainer>
  )
}
