import { DropdownWithAutocomplete } from './componets/DropdownWithAutocomplete'
import { DropdownWithControlledCheckboxes } from './componets/DropdownWithControlledCheckboxes'
import { DropdownWithCheckboxes } from './componets/DropdownWithCheckboxes'
import { DropdownWithLinks } from './componets/DropdownWithLinks'
import { DropdownWithSearchFiltersOptions } from './componets/DropdownWithSearchFiltersOptions'
import { DropdownWithOptions } from './componets/DropdownWithOptions'

export * from './componets/DropdownCheckbox'
export * from './componets/DropdownWithControlledCheckboxes'
export * from './componets/DropdownWithCheckboxes'
export * from './componets/DropdownWithLinks'
export * from './componets/DropdownWithSearchFiltersOptions'
export * from './componets/DropdownWithAutocomplete'
export * from './componets/DropdownWithOptions'

export const Dropdown = {
  WithLinks: DropdownWithLinks,
  WithSearchFiltersOptions: DropdownWithSearchFiltersOptions,
  WithControlledCheckboxes: DropdownWithControlledCheckboxes,
  WithCheckboxes: DropdownWithCheckboxes,
  WithAutocomplete: DropdownWithAutocomplete,
  WithOptions: DropdownWithOptions,
}
