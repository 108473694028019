import styled, { css } from 'styled-components'
import { LogoSize, LogoVariant } from './BrandLogo'
const logoLinkWidthSizes = {
  sm: '8.5375rem',
  md: '11.25rem',
  lg: '16.625rem',
}
const logoIconWidthSizes = {
  sm: '1.725rem',
  md: '2.3rem',
  lg: '3.45rem',
}

const logoIconColor = {
  primary: '#d090c2',
  secondary: '#fff',
}

const logoNameWidthSizes = {
  sm: '6.4375rem',
  md: '8.5812rem',
  lg: '12.8125rem',
}

export const StyledBrandLogoIcon = styled.svg<{ hubsCount: number; logoSize: LogoSize; logoVariant: LogoVariant }>`
  width: ${({ logoSize }) => logoIconWidthSizes[logoSize]};
  fill: ${({ logoVariant }) => logoIconColor[logoVariant]};
  @media (min-width: ${({ theme }) => theme.pxsToEms(400)}) {
    margin-right: 0.375rem;
  }
`

export const StyledBrandNameIcon = styled.svg<{ hubsCount: number; logoSize: LogoSize }>`
  display: none;
  fill: ${({ theme }) => theme.colors.always.alwaysLight};
  position: absolute;
  width: ${({ logoSize }) => logoNameWidthSizes[logoSize]};
  bottom: ${({ logoSize }) => {
    if (logoSize === 'sm') {
      return '15px'
    }
    if (logoSize === 'md') {
      return '12px'
    }
    if (logoSize === 'lg') {
      return '5px'
    }
  }};
  @media (min-width: ${({ theme }) => theme.pxsToEms(400)}) {
    ${({ hubsCount }) =>
      hubsCount < 2 &&
      css`
        display: inline;
      `}
  }

  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletMedium)}) {
    display: inline;
  }
`

export const StyledBrandLogo = styled.a<{
  isTouchable: boolean
  logoSize: LogoSize
  href?: string
  hubsCount: number
}>`
  padding: 1rem 0;
  display: block;
  font-size: 0;
  z-index: 999;
  position: relative;
  @media (min-width: ${({ theme }) => theme.pxsToEms(400)}) {
    ${({ hubsCount, logoSize }) =>
      hubsCount < 2 &&
      css`
        width: ${logoLinkWidthSizes[logoSize]};
      `}
  }

  &:hover {
    ${StyledBrandLogoIcon} {
      ${({ isTouchable, href }) =>
        !isTouchable &&
        !!href &&
        css`
          fill: ${({ theme }) => theme.colors.always.alwaysLight};
        `}
    }
  }

  &:focus {
    outline-offset: -5px;
  }

  span {
    display: inline-block;
  }
`
