import styled, { css } from 'styled-components'
import { Flex } from '~common'

export const StyledDateRangePicker = styled(Flex)`
  align-items: center;
  width: 100%;

  .react-datepicker {
    font-family: ${({ theme }) => theme.fontFamily};
    ${({ theme }) => theme.textSizes.desktop.dataAxis}
    color: ${({ theme }) => theme.colors.text.textPrimary};
    border-radius: 0;
    border: 1px solid ${({ theme }) => theme.colors.neutrals.neutral20};
    background: ${({ theme }) => theme.colors.brand.backgroundSecondary};
  }

  .react-datepicker__current-month {
    ${({ theme }) => theme.textSizes.desktop.labelBold}
    color: ${({ theme }) => theme.colors.text.textPrimary};
    margin-top: 0.3125rem;
  }

  .react-datepicker__day-name,
  .react-datepicker__time-name {
    width: 1rem;
    line-height: 1rem;
    margin: 0.425rem;
  }

  .react-datepicker__day {
    width: 1.25rem;
    line-height: 1.25rem;
    margin: 0.3125rem;
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    border-radius: 0.125rem;
    background-color: ${({ theme }) => theme.colors.neutrals.neutral5};
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    color: ${({ theme }) => theme.colors.text.textPrimary};
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected,
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background-color: ${({ theme }) => theme.colors.pill.pillBackgroundSecondary};
    border-radius: 0.125rem;
    color: ${({ theme }) => theme.colors.textOn.textOnPill};
  }

  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__month-text--keyboard-selected:hover,
  .react-datepicker__quarter-text--keyboard-selected:hover,
  .react-datepicker__year-text--keyboard-selected:hover,
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker__year-text--selected:hover,
  .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker__year-text--in-range:hover,
  .react-datepicker__day--in-selecting-range:not(
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range
    ),
  .react-datepicker__month-text--in-selecting-range:not(
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range
    ),
  .react-datepicker__quarter-text--in-selecting-range:not(
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range
    ),
  .react-datepicker__year-text--in-selecting-range:not(
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range
    ) {
    opacity: 0.4;
    background-color: ${({ theme }) => theme.colors.pill.pillBackgroundSecondary};
  }

  .react-datepicker__header {
    background: ${({ theme }) => theme.colors.brand.backgroundPrimary};
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutrals.neutral20};
  }

  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
    border-bottom-color: ${({ theme }) => theme.colors.brand.backgroundPrimary};
  }

  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
    border-bottom-color: 1px solid ${({ theme }) => theme.colors.neutrals.neutral20};
  }

  .react-datepicker__day--disabled:hover,
  .react-datepicker__month-text--disabled:hover,
  .react-datepicker__quarter-text--disabled:hover,
  .react-datepicker__year-text--disabled:hover {
    background-color: transparent;
  }

  .react-datepicker__day--in-range:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--selected:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__year-text--in-range:hover,
  .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker__year-text--selected:hover {
    background-color: ${({ theme }) => theme.colors.pill.pillBackgroundSecondary};
  }

  .react-datepicker__year-dropdown,
  .react-datepicker__month-dropdown,
  .react-datepicker__month-year-dropdown {
    background: ${({ theme }) => theme.colors.brand.backgroundPrimary};
  }

  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled,
  .react-datepicker__quarter-text--disabled,
  .react-datepicker__year-text--disabled {
    opacity: 0.4;
  }
`

export const StyledDividerText = styled.span<{ isDisabled?: boolean }>`
  ${({ theme }) => theme.textSizes.desktop.dataAxis};
  margin: 0 0.25rem;
  color: ${({ theme }) => theme.colors.text.textSecondary};
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.4;
    `}
`
