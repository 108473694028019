import React from 'react'
import { MissingFileSvg } from '~common/svgs'
import { StyledErrorPageBlock, BackButton } from './styles'
import { paths } from "~helpers";
import { useNavigate } from "react-router-dom";

export const InvalidSSOConfiguration = (): JSX.Element => {
  const navigate = useNavigate()
  return <>
    <StyledErrorPageBlock
     errorMessage="Couldn't sign in, because your SSO configuration is incorrect. Please contact your administrator"
     icon={<MissingFileSvg />}
    />
    <BackButton size="lg" onClick={() => navigate(paths.home)}>
      Go back
    </BackButton>
  </>
}
