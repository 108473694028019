import styled from 'styled-components'
import { Button, ButtonProps } from '~shared/ui/atoms/button'

export const StyledButton = styled(Button)<ButtonProps>`
  & svg path {
    transform: translateY(0rem);
  }
  &:hover svg path {
    transform: translateY(0.046875rem);
  }
  &:focus-visible svg path {
    transform: translateY(0.046875rem);
  }
  &:active svg path {
    transform: translateY(0.09375rem);
  }
`
