import { useEffect, useState, useContext } from 'react'
import { useAvailableProducts } from '~hooks/use-available-products'
import { getProducts } from '~api/ssoProviderApi'
import { Product } from '~hooks/use-available-products/Product'
import { Logger } from '~helpers/Logger'
import { useToken } from '~hooks/use-token'
import { UserContext } from '~contexts/UserContext'

export function useUserProducts(): {
  products: Product[]
  shouldRedirectToEE: boolean
  clientId: number
  userIpAdress: string
  loading: boolean
} {
  const [products, setProducts] = useState<
    { name: string; description: string; url: string; isOutsideIPRange: boolean }[]
  >([])
  const [mappedProducts, setMappedProducts] = useState<Product[]>([])
  const [clientId, setClientId] = useState(0)
  const [userIpAdress, setUserIpAdress] = useState('')
  const { user: cognitoUser, loading: loadingCognitoUser } = useContext(UserContext)
  const { idToken } = useToken()
  const [shouldRedirectToEE, setShouldRedirectToEE] = useState(false)
  const [loading, setLoading] = useState(true)
  const availableProducts = useAvailableProducts()

  useEffect(() => {
    if (!cognitoUser || !idToken || loadingCognitoUser) return
    setLoading(true)
    getProducts(cognitoUser?.username, idToken)
      .then((response) => {
        setClientId(response.clientId)
        setUserIpAdress(response.userIpAdress)
        if (response.products) {
          setShouldRedirectToEE(response.shouldRedirectToEE)
          setProducts(response.products)
          setLoading(false)
        }
      })
      .catch((err) => {
        Logger.error(err)
        setLoading(false)
      })
  }, [cognitoUser, idToken, setLoading, setClientId, setUserIpAdress, loadingCognitoUser])

  useEffect(() => {
    if (!cognitoUser) return
    const result: Product[] = []
    const userProducts = availableProducts.filter((s) => products.find((a) => a.name === s.name))
    for (let product of userProducts) {
      const productFromServer = products.find((p) => p.name === product.name)
      result.push({
        name: productFromServer?.name || product.name,
        description: productFromServer?.description || product.description,
        url: productFromServer?.url || product.url,
        icon: product.icon,
        isOutsideIPRange: productFromServer?.isOutsideIPRange,
      })
    }
    setMappedProducts(result)
  }, [cognitoUser, products, availableProducts])

  return {
    loading,
    products: mappedProducts,
    clientId,
    userIpAdress,
    shouldRedirectToEE,
  }
}
