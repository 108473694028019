import { Product } from './Product'
import { KwelloIcon } from '../../common/icons'

export class KwelloProductBuilder {
  public static build(): Product {
    return {
      name: 'Kwello',
      description: 'Kwello',
      url: ``,
      icon: <KwelloIcon />,
      isOutsideIPRange: false,
    }
  }
}
