import { getConfig } from '~config'

const config = getConfig()

const allowedRedirectUrls = [
  config.COGNITO_SSO_REDIRECT_URI,
  config.RETURN_URL,
  config.HELIOS_URL,
  config.INSIGHTMEME_URL,
  config.PHARMASPECTRA_PLATFORM_URL,
  config.PROFILEMEME_URL,
  'https://dev.insightmeme.com',
]

export const getAllowedRedirectUrls = () => allowedRedirectUrls
