import React, { useEffect, useState } from 'react'
import { Dropdown, DropdownSearchFiltersOption } from '~components/ui/molecules/dropdown'
import { FormItem, FormItemSize, FormItemLabelPosition } from '~components/ui/atoms/form-item'
import {
  StyledCustomSelect,
  StyledSelectWithOptionsWrapper,
  StyledSearchFiltersQueryGroup,
  StyledSelectedOptionLabel,
} from './styles'
import { useOutsideClick } from '~common/hooks'
import { ChevronIcon } from '~components/ui/atoms/chevron-icon'
import { chevronSize } from '../Select'
import { Flex } from '~common'

export interface SelectWithSearchFiltersOptionsDropdownProps extends React.HTMLAttributes<HTMLSelectElement> {
  id: string
  options: DropdownSearchFiltersOption[]
  selectDropdownSize?: FormItemSize
  labelPosition?: FormItemLabelPosition
  label?: string
  isDisabled?: boolean
  customDropdownItems?: { value: string; label: string }[]
  dropdownTitle?: string | JSX.Element
  value?: DropdownSearchFiltersOption
  onOptionSelect?: (option: DropdownSearchFiltersOption) => void
}

export const SelectWithSearchFiltersOptionsDropdown = ({
  id,
  label,
  selectDropdownSize = 'sm',
  labelPosition = 'top',
  isDisabled,
  style,
  className,
  options,
  value,
  dropdownTitle,
  placeholder,
  onOptionSelect,
}: SelectWithSearchFiltersOptionsDropdownProps): JSX.Element => {
  const [selectedOption, setSelectedOption] = useState<DropdownSearchFiltersOption | undefined>(value)

  useEffect(() => {
    setSelectedOption(value)
  }, [value])

  const {
    ref: selectDropdownRef,
    state: isOpenDropdown,
    action: setIsOpenDropdown,
  } = useOutsideClick<HTMLDivElement>(false)

  const onSelectOptionHandler = (option: DropdownSearchFiltersOption) => {
    setSelectedOption(option)
    setIsOpenDropdown(false)
    if (onOptionSelect) {
      onOptionSelect(option)
    }
  }

  return (
    <FormItem
      className={className}
      formItemSize={selectDropdownSize}
      labelPosition={labelPosition}
      style={style}
      label={label}
      htmlFor={`select-${id}`}
    >
      <StyledSelectWithOptionsWrapper ref={selectDropdownRef}>
        <StyledCustomSelect
          id={`select-${id}`}
          type="button"
          selectDropdownSize={selectDropdownSize}
          isOpened={isOpenDropdown}
          aria-expanded={isOpenDropdown}
          data-toggle="dropdown"
          aria-haspopup={true}
          disabled={isDisabled}
          onClick={() => setIsOpenDropdown(!isOpenDropdown)}
        >
          {selectedOption ? (
            <Flex>
              <StyledSelectedOptionLabel withFilters={!!selectedOption.filters?.length}>
                {selectedOption.label}
              </StyledSelectedOptionLabel>
              {!!selectedOption.filters?.length && <StyledSearchFiltersQueryGroup filters={selectedOption.filters} />}
            </Flex>
          ) : (
            placeholder
          )}
          <ChevronIcon
            position="right"
            rotation="top-bottom"
            width={chevronSize[selectDropdownSize].size}
            height={chevronSize[selectDropdownSize].size}
            pathDValue={chevronSize[selectDropdownSize].dValue}
            isOpened={isOpenDropdown}
          />
        </StyledCustomSelect>
        <Dropdown.WithSearchFiltersOptions
          isOpen={isOpenDropdown}
          dropdownOptions={options}
          ariaLabel={`select-${id} options`}
          onBlur={() => setIsOpenDropdown(false)}
          onFocus={() => setIsOpenDropdown(true)}
          onSelectOption={onSelectOptionHandler}
          dropdownTitle={dropdownTitle}
        />
      </StyledSelectWithOptionsWrapper>
    </FormItem>
  )
}
