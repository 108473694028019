import styled, { css, keyframes } from 'styled-components'
import { LoadingIndicatorSize } from './LoadingIndicator'
import { Theme } from '~common/theme'

const dotAnimation = (theme: Theme, size: LoadingIndicatorSize) => keyframes`
0% {
    box-shadow: 9984px 0 0 0 ${theme.colors.loading.loadingPrimary}, ${size === 'md' ? '9999px' : '9995px'} 0 0 0 ${
  theme.colors.loading.loadingPrimary
}, ${size === 'md' ? '10014px' : '10006px'} 0 0 0 ${theme.colors.loading.loadingPrimary};
  }
  16.667% {
    box-shadow: 9984px -12px 0 0 ${theme.colors.loading.loadingTertiary}, ${
  size === 'md' ? '9999px' : '9995px'
} 0 0 0 ${theme.colors.loading.loadingPrimary}, ${size === 'md' ? '10014px' : '10006px'} 0 0 0 ${
  theme.colors.loading.loadingPrimary
};
  }
  33.333% {
    box-shadow: 9984px 0 0 0 ${theme.colors.loading.loadingSecondary}, ${size === 'md' ? '9999px' : '9995px'} 0 0 0 ${
  theme.colors.loading.loadingPrimary
}, ${size === 'md' ? '10014px' : '10006px'} 0 0 0 ${theme.colors.loading.loadingPrimary};
  }
  50% {
    box-shadow: 9984px 0 0 0 ${theme.colors.loading.loadingPrimary}, ${size === 'md' ? '9999px' : '9995px'} -12px 0 0 ${
  theme.colors.loading.loadingPrimary
}, ${size === 'md' ? '10014px' : '10006px'} 0 0 0 ${theme.colors.loading.loadingPrimary};
  }
  66.667% {
    box-shadow: 9984px 0 0 0 ${theme.colors.loading.loadingPrimary}, ${size === 'md' ? '9999px' : '9995px'} 0 0 0 ${
  theme.colors.loading.loadingTertiary
}, ${size === 'md' ? '10014px' : '10006px'} 0 0 0 ${theme.colors.loading.loadingPrimary};
  }
  83.333% {
    box-shadow: 9984px 0 0 0 ${theme.colors.loading.loadingPrimary}, ${size === 'md' ? '9999px' : '9995px'} 0 0 0 ${
  theme.colors.loading.loadingSecondary
}, ${size === 'md' ? '10014px' : '10006px'} -12px 0 0 ${theme.colors.loading.loadingTertiary};
  }
  100% {
    box-shadow: 9984px 0 0 0 ${theme.colors.loading.loadingPrimary}, ${size === 'md' ? '9999px' : '9995px'} 0 0 0 ${
  theme.colors.loading.loadingPrimary
}, ${size === 'md' ? '10014px' : '10006px'} 0 0 0 ${theme.colors.loading.loadingSecondary};
  }
`

export const StyledLoadingIndicator = styled.div<{ size: LoadingIndicatorSize }>`
  position: relative;
  left: -9999px;
  width: ${({ size }) => (size === 'sm' ? '4px' : '8px')};
  height: ${({ size }) => (size === 'sm' ? '4px' : '8px')};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.loading.loadingPrimary};
  color: ${({ theme }) => theme.colors.loading.loadingPrimary};
  box-shadow: ${({ theme, size }) => `9984px 0 0 0 ${theme.colors.loading.loadingPrimary},
     ${size === 'md' ? '9999px' : '9995px'} 0 0 0 ${theme.colors.loading.loadingPrimary},
    ${size === 'md' ? '10014px' : '10006px'} 0 0 0 ${theme.colors.loading.loadingPrimary}`};
  animation: ${({ theme, size }) =>
    css`
      ${dotAnimation(theme, size)} 0.9s infinite linear
    `};
`
