import React from 'react'
import { useExpertEcosystemContext } from '~common/providers'
import { StyledLoadingIndicator } from './styles'

export type LoadingIndicatorSize = 'sm' | 'md'

export interface LoadingIndicatorProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: LoadingIndicatorSize
}

export const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({ children, size = 'md', ...rest }): JSX.Element => {
  const { EELoadingComponent } = useExpertEcosystemContext() ?? {}

  if (!!EELoadingComponent) {
    return <EELoadingComponent zIndex={100} bgOpacity={0} message="Please wait" isAbsolutePosition={false} />
  }

  return (
    <StyledLoadingIndicator size={size} {...rest}>
      {children}
    </StyledLoadingIndicator>
  )
}
