import styled, { css } from 'styled-components'
import { EELink } from '~components/ui/atoms/custom-link/EELink'

export type Position = 'left' | 'right'
export type TSize = 'sm' | 'md'

const iconStyles = {
  sm: {
    ['margin-left']: '0.125rem',
    width: '0.875rem',
    height: '0.875rem',
  },
  md: {
    ['margin-left']: '0.125rem',
    width: '1.125rem',
    height: '1.125rem',
  },
}

export const StyledAnchorTag = styled.a<{ $isDefaultStyles?: boolean; $size: TSize; $hasIcon: boolean }>`
  ${({ $isDefaultStyles, $size, theme }) =>
    $isDefaultStyles
      ? css`
          font-size: ${$size === 'sm' && '0.75rem'};
          line-height: 1rem;
          color: ${theme.colors.buttons.link};
          text-decoration: none;
          &:visited,
          &:hover,
          &:focus {
            text-decoration: none;
          }
          &:visited {
            color: ${theme.colors.states.visited};
          }
          &:hover,
          &:focus {
            color: ${theme.colors.text.textHoverPrimary};
          }
        `
      : ''}
  ${({ theme, $size, $hasIcon }) =>
    $hasIcon &&
    css`
      & > span,
      & > svg {
        display: inline-block;
        vertical-align: middle;
      }
      &:visited > svg {
        fill: ${theme.colors.text.textHoverPrimary};
      }
      &:hover > svg,
      &:focus > svg {
        fill: ${theme.colors.text.textHoverPrimary};
      }
      &:visited > span,
      &:hover > span,
      &:focus > span {
        color: ${theme.colors.text.textHoverPrimary};
      }
      & > svg {
        ${iconStyles[$size]};
        fill: ${theme.colors.buttons.link};
      }
    `}
`

export const StyledEELink = styled(EELink)<{
  $isDefaultStyles?: boolean
  $size: TSize
  $hasIcon: boolean
}>`
  ${({ $isDefaultStyles, $size, theme }) =>
    $isDefaultStyles
      ? css`
          font-size: ${$size === 'sm' && '0.75rem'};
          line-height: 1rem;
          color: ${theme.colors.buttons.link};
          text-decoration: none;
          &:visited,
          &:hover,
          &:focus {
            text-decoration: none;
          }
          &:visited {
            color: ${theme.colors.states.visited};
          }
          &:hover,
          &:focus {
            color: ${theme.colors.text.textHoverPrimary};
          }
        `
      : ''}
  ${({ theme, $size, $hasIcon }) =>
    $hasIcon &&
    css`
      & > span,
      & > svg {
        display: inline-block;
        vertical-align: middle;
      }
      &:visited > svg {
        fill: ${theme.colors.text.textHoverPrimary};
      }
      &:hover > svg,
      &:focus > svg {
        fill: ${theme.colors.text.textHoverPrimary};
      }
      & > svg {
        ${iconStyles[$size]};
        fill: ${theme.colors.buttons.link};
      }
    `}
`
