import React from 'react'
import { Controller } from 'react-hook-form'
import styled from 'styled-components'
import { Flex } from '~common'
import { Radio, RadioProps } from '~components/ui/atoms/radio'
import { ControlledProps } from '../types'

const StyledRadioGroup = styled(Flex)`
  align-items: center;
  & > span {
    ${({ theme }) => theme.textSizes.desktop.searchStrong}
    margin-right: 0.5rem;
  }
`

const StyledRadio = styled(Radio)`
  margin-right: 1rem;
  width: auto;
`

export interface RadioItem {
  id: string
  value: string
  label: string
}

export type ControlledRadioGroupProps<TFormValues> = ControlledProps<TFormValues> &
  Omit<RadioProps, 'value' | 'isChecked' | 'id'> & {
    radios: RadioItem[]
  }

export const ControlledRadioGroup = <TFormValues extends Record<string, unknown>>({
  name,
  control,
  rules = {},
  errors,
  radios,
  label,
  ...restProps
}: ControlledRadioGroupProps<TFormValues>): JSX.Element => (
  <>
    <Controller
      rules={rules}
      control={control}
      name={name}
      defaultValue={restProps.defaultValue}
      render={({ field }) => (
        <StyledRadioGroup>
          <span>{`${label}:`}</span>
          {radios.map((radio: RadioItem) => (
            <StyledRadio
              key={radio.id}
              isChecked={field.value === radio.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              label={radio.label}
              value={radio.value}
              id={radio.id}
              {...restProps}
            />
          ))}
        </StyledRadioGroup>
      )}
    />
    {errors?.[name] && <>{errors?.[name]?.message}</>}
  </>
)
