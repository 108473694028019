import * as React from 'react'
import {
  FooterContainer,
  FooterLogo,
  FooterLinks,
  FooterLogoCopy,
  FooterLink,
  StyledDivider,
  StyledFooterContent,
} from './styles'
import { InmemeRegisterButton } from '../inmeme-register-button'
import { PharmaspectraBrandLogo } from '~components/ui/molecules/brand-logo'

export function Footer() {
  return (
    <FooterContainer>
      <StyledDivider borderType="dashed" />
      <StyledFooterContent>
        <FooterLogo>
          <PharmaspectraBrandLogo
            isTouchable={false}
            href="https://www.pharmaspectra.com/"
            logoSize="sm"
            logoVariant="secondary"
            withNextLink={false}
          />
          <FooterLogoCopy> ©{new Date().getFullYear()}</FooterLogoCopy>
        </FooterLogo>
        <FooterLinks>
          <InmemeRegisterButton />
          <FooterLink target="_blank" href="https://www.pharmaspectra.com/privacy-policy">
            Privacy Policy
          </FooterLink>
          <FooterLink target="_blank" href="https://www.pharmaspectra.com/cookie-policy">
            Cookie Policy
          </FooterLink>
          <FooterLink target="_blank" href="https://www.pharmaspectra.com/terms-and-conditions">
            Terms &amp; Conditions
          </FooterLink>
        </FooterLinks>
      </StyledFooterContent>
    </FooterContainer>
  )
}
