import React from 'react'
import { StyledSection } from '~common'
import { Button } from '~components/ui/atoms/button'
import { StyledCard, StyledCardHeader, StyledTitle, StyledCustomLink } from './styles'

export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string
  headline?: React.ReactNode
  linkTo?: string
  onClick?: () => void
  ctaText?: string
  onClickCTA?: () => void
  asTag?: string
}

export const Card: React.FC<CardProps> = ({
  asTag,
  children,
  title,
  ctaText,
  onClickCTA,
  onClick,
  linkTo,
  headline,
  ...rest
}): JSX.Element => (
  <StyledCard
    as={asTag as never}
    onClick={onClick}
    tabIndex={!!onClick ? 0 : undefined}
    onKeyPress={(e) => e.key === 'Enter' && onClick && onClick()}
    {...rest}
  >
    <StyledCardHeader>
      <StyledCustomLink href={linkTo ? linkTo : ''} onClick={(e) => e.preventDefault()} tabIndex={-1}>
        {headline && headline}
        <StyledTitle level="3">{title}</StyledTitle>
      </StyledCustomLink>
      {ctaText && (
        <Button
          variant="secondary"
          size="sm"
          onClick={(e) => {
            e.stopPropagation()
            onClickCTA && onClickCTA()
          }}
        >
          {ctaText}
        </Button>
      )}
    </StyledCardHeader>
    <StyledSection>{children}</StyledSection>
  </StyledCard>
)
