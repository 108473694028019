import { useEffect, useState } from 'react'
import { getLoginSettings } from '~api/ssoProviderApi'
import { Logger } from '~helpers/Logger'
import { CustomCookieStorage } from '../../../../shared/common/auth/custom-cookie-storage'

export interface ILoginSettings {
  ssoProvider: string
  clientId: number
  sessionTimeout: number
  shouldRedirectToEE?: boolean
}
export const useLoginSettings = (
  email: string,
): { loginSettings: ILoginSettings | undefined | null; loading: boolean } => {
  const [loginSettings, setLoginSettings] = useState<ILoginSettings>()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (email && email !== '') {
      setLoading(true)
      getLoginSettings(email)
        .then((response) => {
          if (response && response.sessionTimeout) {
            CustomCookieStorage.setSessionTimeoutMinutes(response.sessionTimeout)
          } else {
            CustomCookieStorage.clearSessionTimeout()
          }
          setLoginSettings(response)
          setLoading(false)
        })
        .catch((error) => {
          Logger.error(error)
        })
    }
  }, [email])

  return {
    loginSettings,
    loading,
  }
}
