import React, { RefObject } from 'react'
import { FormItemLabelPosition } from '~components/ui/atoms/form-item'
import { FormItem } from '~components/ui/atoms/form-item'
import { StyledTextarea, StyledInputWrapper, StyledErrorMessage, StyledErrorWrapper } from './styles'

interface Error {
  isError: boolean
  messages?: string[]
}

export type TextareaProps = React.HTMLAttributes<HTMLTextAreaElement> & {
  value?: string
  error?: Error
  labelPosition?: FormItemLabelPosition
  label?: string
  isDisabled?: boolean
  id?: string
  rows?: number
  itemRef?: RefObject<HTMLTextAreaElement>
}

export const Textarea = ({
  id,
  placeholder,
  rows = 4,
  label,
  labelPosition = 'top',
  isDisabled,
  style,
  value = '',
  onChange,
  className,
  error,
  itemRef,
  ...rest
}: TextareaProps): JSX.Element => (
  <FormItem
    formItemSize="md"
    labelPosition={labelPosition}
    style={style}
    label={label}
    htmlFor={id}
    className={className}
    isError={error?.isError}
  >
    <StyledInputWrapper>
      <StyledTextarea
        aria-label={!label ? 'text-box' : label}
        isError={error?.isError}
        disabled={isDisabled}
        placeholder={placeholder}
        id={id}
        rows={rows}
        value={value}
        onChange={onChange}
        ref={itemRef}
        {...rest}
      />
    </StyledInputWrapper>
    {!!error?.messages?.length && (
      <StyledErrorWrapper>
        {error?.messages.map((message, i) => (
          <StyledErrorMessage key={i}>{message}</StyledErrorMessage>
        ))}
      </StyledErrorWrapper>
    )}
  </FormItem>
)
