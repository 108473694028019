import { getPillsFromQuery } from '~common'
import { getCurrentShallowQuery } from '~common/providers/shallow-routing-state-provider'
import * as countryMapper from '~global/maps/countries'
import * as stateMapper from '~global/maps/states'

const urlMapper = (currentUrl, query) => {
  const { expertId, listId, contentTypes, filters, timeframe } = query
  const EE_PLATFORM_URL = process.env.EXPERT_ECOSYSTEM_URL || process.env.REACT_APP_EXPERT_ECOSYSTEM_URL
  let newFilters = ''
  const ignoredFilters = ['full name'] //cannot map full name searches easily, so ignore them to avoid breaking the resulting link
  filters
    .filter((f) => !ignoredFilters.includes(f.category.toLowerCase()))
    .forEach((filter) => {
      const filterCategoryMapping = {
        speciality: 'current_specialty',
        degree: 'expert_degrees',
        country: 'expert_country_name',
        city: 'expert_city_name',
        state: 'state_name',
        zip: 'expert_postal_code',
      }
      const filterValueMappers = {
        country: countryMapper.psToEE,
        state: stateMapper.psToEE,
      }
      newFilters += `&filters+category=${encodeURIComponent(
        filterCategoryMapping[filter.category.toLowerCase()] || filter.category,
      )}&filters+value=${encodeURIComponent(
        filterValueMappers[filter.category.toLowerCase()]
          ? filterValueMappers[filter.category.toLowerCase()](filter.value)
          : filter.value,
      )}`
      filter.synonyms.forEach((synonym) => {
        newFilters += `&filters+synonyms=${encodeURIComponent(synonym)}%3Btrue`
      })
    })

  let newContentTypes = ''
  contentTypes.forEach((contentType) => {
    newContentTypes += `&contentTypes=${encodeURIComponent(contentType)}`
  })

  const map = {
    'experts?rootSubscriptionId': `${EE_PLATFORM_URL}/regions/us/app/expert-ecosystem/experts/list?card-view=true&listId=${listId}`,
    '/my-account/saved-searches': `${EE_PLATFORM_URL}/regions/us/app/expert-ecosystem/experts/savedsearches`,
    [`${expertId}?listId=`]: `${EE_PLATFORM_URL}/regions/us/app/expert-ecosystem/experts/expert-profile/scientific/${expertId}?listId=${listId}`,
    '/search-results': `${EE_PLATFORM_URL}/regions/us/app/expert-ecosystem/experts/list?card-view=true&listId=${listId}&search-results=true${newFilters}${newContentTypes}&timeframe=${timeframe}&sort=-1`,
  }
  const contentTypesToMap = [
    'cv',
    'network',
    'presentations',
    'treatment-guidelines',
    'journal-articles',
    'clinical-trials',
    'disclosures',
  ]
  contentTypesToMap.forEach((contentType) => {
    map[
      contentType
    ] = `${EE_PLATFORM_URL}/regions/us/app/expert-ecosystem/experts/expert-profile/scientific/${expertId}/${contentType}?listId=${listId}`
  })

  for (const key in map) {
    if (currentUrl.includes(key)) {
      return map[key]
    }
  }

  //fallback to default expert ecosystem home page
  return `${EE_PLATFORM_URL}/regions/us/app/expert-ecosystem/`
}

export const getRedirectUrl = (currentUrl: string): string => {
  const url = new URL(currentUrl)
  if (url.hostname.includes('insightmeme')) {
    // this is only for the UAT environment environment
    // prod can't yet handle the hdeUser query param
    url.searchParams.delete('id_token')
    url.searchParams.set('hdeUser', '1')
    return url.toString()
  }
  const params = new URLSearchParams(url.search)
  const pathComponents = url.pathname.split('/')
  const expertId = pathComponents.length >= 3 && pathComponents[2].length === 36 ? pathComponents[2] : null
  const listId = params.get('listId')
  const contentTypes = params.getAll('contentTypes')
  const timeframe = params.get('timeframe')
  const pills = getPillsFromQuery(getCurrentShallowQuery(url))
  const filtersArray: { category: string; value: string; synonyms: string[] }[] = pills.map((pill) => {
    const synonyms = pill.synonyms ? Object.keys(pill.synonyms).filter((synonym) => pill.synonyms?.[synonym]) : []
    return { category: pill.category, value: pill.value, synonyms: synonyms }
  })

  for (let i = 0; ; i++) {
    const category = pills.map((pill) => pill.category)[i]
    const value = pills.map((pill) => pill.value)[i]
    if (!category || !value) break
    const synonyms: string[] = pills
      .map((pill) => {
        return Object.keys(pill.synonyms || {})
      })
      .flat()
    filtersArray.push({ category, value, synonyms })
  }
  const query = { expertId, listId, contentTypes, filters: filtersArray, timeframe }
  const currentHash = url.hash
  return urlMapper(currentUrl, query) + (currentHash ? currentHash : '')
}

export const redirectToEe = (currentUrl: string): void => {
  const redirectUrl = getRedirectUrl(currentUrl)
  if (redirectUrl) {
    window.location.href = redirectUrl
  }
}
