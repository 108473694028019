import * as React from 'react'
import { useCallback, useState } from 'react'
import { ForgotPasswordTemplate } from '~components/forgot-password-template'
import { CognitoClient } from '../../auth/CognitoClient'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { StyledForgotPasswordForm, StyledInput } from './styles'
import { useForm } from 'react-hook-form'
import { buildUrl, paths } from '~helpers'
import { Heading } from '~shared/ui/atoms/heading'
import { Button } from '~shared/ui/atoms/button'
import { CenteredFlex, Status, StyledSection } from '~shared/common'
import { Toast } from '~shared/ui/molecules/toast'
import { SuccessSvg, WarningSvg } from '~shared/common/svgs'
import usePageTitle from '~hooks/use-page-title'

interface FormValues {
  email: string
}

export function ForgotPassword() {
  const [searchParams] = useSearchParams()
  const [message, setMessage] = useState<{ title: string; message: string; status: Status } | null>(null)
  const navigate = useNavigate()

  const defaultValues: FormValues = {
    email: searchParams.get('email') || '',
  }

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>({ defaultValues })

  const onSubmitHandler = useCallback(
    async (values: FormValues) => {
      const email = values.email
      return new Promise<boolean>(async (res, rej) => {
        setMessage(null)
        try {
          await CognitoClient.forgotPassword({ username: email })
          res(true)
          navigate(buildUrl(paths.resetPassword, email ? [{ id: 'email', value: email }] : []))
        } catch (error) {
          setMessage({ title: 'Error', message: error.message, status: Status.ERROR })
          rej(error)
        }
      })
    },
    [navigate],
  )

  usePageTitle("Reset password | Pharmaspectra ");

  return (
    <ForgotPasswordTemplate>
      <StyledForgotPasswordForm onSubmit={handleSubmit(onSubmitHandler)}>
        <Heading>Forgot your password?</Heading>

        <StyledSection>
          {message && (
            <Toast
              description={message.message}
              icon={({ [Status.ERROR]: <WarningSvg />, [Status.SUCCESS]: <SuccessSvg /> } as any)[message.status]}
              id="1"
              isRemovable={false}
              status={message.status}
              title={message.title}
            />
          )}
        </StyledSection>

        <StyledSection>
          <StyledInput
            inputSize="md"
            name="email"
            type="email"
            role="title"
            control={control}
            placeholder="email@email.com"
            rules={{
              required: 'Email is required',
            }}
            defaultValue={'email@email.com'}
            label="Email"
            labelPosition="top"
            errors={errors}
            data-testid="email-input"
          />
        </StyledSection>

        <StyledSection>
          <CenteredFlex>
            <Button type="submit" variant="primary" size="lg">
              Submit
            </Button>
          </CenteredFlex>
        </StyledSection>
      </StyledForgotPasswordForm>
    </ForgotPasswordTemplate>
  )
}
