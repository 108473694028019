import React, { forwardRef, RefObject, useRef } from 'react'
import { Controller, FieldValues, UseFormGetValues, UseFormReset } from 'react-hook-form'
import { locale } from '~common'
import { ControlledProps } from '../types'
import DatePicker from 'react-datepicker'
import { StyledDateRangePicker, StyledDividerText } from './styles'
import { Input } from '~components/ui/molecules/input'
import addDays from 'date-fns/addDays'
import addMonths from 'date-fns/addMonths'
import isAfter from 'date-fns/isAfter'
import subDays from 'date-fns/subDays'
import subYears from 'date-fns/subYears'

const CustomDatePickerInput = forwardRef((props, ref) => (
  <Input {...props} inputSize="xs" itemRef={ref as string & RefObject<HTMLInputElement>} />
))

export type ControlledDatePickerDateRangeProps<TFormValues> = ControlledProps<TFormValues> &
  React.HTMLAttributes<HTMLDivElement> & {
    watch: UseFormGetValues<FieldValues>
    reset: UseFormReset<FieldValues>
    getValues: UseFormGetValues<FieldValues>
    startDate?: Date
    endDate?: Date
    isDisabled?: boolean
  }

export const ControlledDatePickerDateRange = <TFormValues extends Record<string, unknown>>({
  name,
  control,
  watch,
  getValues,
  rules = {},
  reset,
  isDisabled,
  startDate,
  endDate,
  errors,
  ...restProps
}: ControlledDatePickerDateRangeProps<TFormValues>): JSX.Element => {
  const startDateInputRef = useRef<string & RefObject<HTMLInputElement>>(null)
  const endDateInputRef = useRef<string & RefObject<HTMLInputElement>>(null)
  const isUSLocale = locale() === 'en-US'
  const dateFormat = isUSLocale ? 'MM/dd/yyyy' : 'dd/MM/yyyy'
  const placeholderText = isUSLocale ? 'mm/dd/yy' : 'dd/mm/yyyy'
  const notEarliestThan10Years = addDays(subYears(new Date(), 10), 1)
  const notLatestThan2Months = subDays(addMonths(new Date(), 2), 1)
  return (
    <StyledDateRangePicker {...restProps}>
      <Controller
        rules={rules}
        control={control}
        name={`${name}.startDate`}
        defaultValue={startDate}
        render={({ field }) => (
          <DatePicker
            disabled={isDisabled}
            selected={field.value}
            dateFormat={dateFormat}
            placeholderText={placeholderText}
            selectsStart={true}
            minDate={notEarliestThan10Years}
            startDate={field.value}
            endDate={watch(`${name}.endDate`)}
            showYearDropdown={true}
            fixedHeight={true}
            maxDate={notLatestThan2Months}
            customInput={<CustomDatePickerInput ref={startDateInputRef} />}
            onBlur={field.onBlur}
            popperPlacement="top-end"
            popperProps={{
              positionFixed: true,
            }}
            onChange={(date) => {
              field.onChange(date)
              const isStartDateLaterThanEndDate = date && isAfter(date, watch(`${name}.endDate`))
              if (isStartDateLaterThanEndDate) {
                reset({
                  ...getValues(),
                  [name]: {
                    startDate: date,
                    endDate: undefined,
                  },
                })
              }
            }}
          />
        )}
      />
      <StyledDividerText isDisabled={isDisabled}>to</StyledDividerText>
      <Controller
        rules={rules}
        control={control}
        name={`${name}.endDate`}
        defaultValue={endDate}
        render={({ field }) => (
          <DatePicker
            disabled={isDisabled}
            selected={field.value}
            selectsEnd={true}
            fixedHeight={true}
            showYearDropdown={true}
            startDate={watch(`${name}.startDate`)}
            endDate={field.value}
            maxDate={notLatestThan2Months}
            minDate={watch(`${name}.startDate`)}
            dateFormat={dateFormat}
            placeholderText={placeholderText}
            customInput={<CustomDatePickerInput ref={endDateInputRef} />}
            onBlur={field.onBlur}
            onChange={field.onChange}
            popperPlacement="top-end"
            popperProps={{
              positionFixed: true,
            }}
          />
        )}
      />
      {errors?.[name] && <>{errors?.[name]?.message}</>}
    </StyledDateRangePicker>
  )
}
