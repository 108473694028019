import { StyledHeader, StyledPharmaspectraBrandLogo, StyledRegeneronIconWrapper } from './styles'
import React, { useCallback } from 'react'
import { useOutsideClick, useWindowSize, WindowSize } from '~common/hooks'
import { GlobalNavigation } from '~components/header/global-navigation'
import { MobileNavigation } from '~components/header/mobile-navigation'
import { useNavigate } from 'react-router-dom'
import { paths } from '~helpers'
import { RegeneronIcon } from '../../common/icons/index'

export const Header = ({ isRegeneronClient }: { isRegeneronClient: boolean }): JSX.Element => {
  const { ref: menuRef, state: isMenuOpened, action: setIsMenuOpened } = useOutsideClick<HTMLLIElement>(false)
  const { width: screenWidth }: WindowSize = useWindowSize()
  const isMobileView: boolean = screenWidth < 600
  const navigate = useNavigate()
  const onLogout = useCallback(() => {
    navigate(paths.logout)
  }, [navigate])
  return (
    <StyledHeader>
      {isRegeneronClient ? (
        <StyledRegeneronIconWrapper>
          <RegeneronIcon />
        </StyledRegeneronIconWrapper>
      ) : (
        <StyledPharmaspectraBrandLogo
          isTouchable={false}
          href="/"
          logoSize="md"
          logoVariant="primary"
          withNextLink={false}
        />
      )}

      <GlobalNavigation
        isSubMenuOpened={isMenuOpened}
        onSubMenuToggle={setIsMenuOpened}
        subMenuRef={menuRef}
        onLogout={onLogout}
        isMobileView={isMobileView}
        isTouchable={false}
      />
      <MobileNavigation
        isMobileNavigationOpened={isMenuOpened}
        onLogout={onLogout}
        onMobileNavigationToggle={setIsMenuOpened}
        isMobileView={isMobileView}
        isTouchable={false}
      />
    </StyledHeader>
  )
}
