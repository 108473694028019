import React from 'react'
import styled from 'styled-components'
import { browserTruncateMixin, SearchedQueryFilters } from '~common'
import { SearchFiltersQueryGroup } from '~components/ui/organisms/search-filters-query-group'
import {
  StyledDropdownWithSearchFiltersOptionsItem,
  StyledDropdown,
  StyledDropdownWithSearchFiltersOptionLabel,
} from '../styles'

export const StyledSearchFiltersQueryGroup = styled(SearchFiltersQueryGroup)`
  ${browserTruncateMixin}
  flex-wrap: nowrap;
  flex-shrink: 2;
  & > * {
    ${browserTruncateMixin}
  }
`

export interface DropdownSearchFiltersOption {
  id: string
  label: string
  filters?: SearchedQueryFilters
}

export interface DropdownWithSearchFiltersOptionsProps extends React.HTMLAttributes<HTMLUListElement> {
  isOpen: boolean
  dropdownOptions: DropdownSearchFiltersOption[]
  ariaLabel: string
  dropdownTitle?: string | JSX.Element
  onSelectOption: (option: DropdownSearchFiltersOption) => void
}

export const DropdownWithSearchFiltersOptions = ({
  dropdownTitle,
  dropdownOptions,
  ariaLabel,
  isOpen,
  onSelectOption,
  ...rest
}: DropdownWithSearchFiltersOptionsProps): JSX.Element => (
  <StyledDropdown
    tabIndex={-1}
    aria-label={ariaLabel}
    aria-hidden={!isOpen}
    isFixedMaxWidth={false}
    isOpen={isOpen}
    {...rest}
  >
    {dropdownTitle && (
      <StyledDropdownWithSearchFiltersOptionsItem hasNoAction={true} isGroupTitle={true}>
        {dropdownTitle}
      </StyledDropdownWithSearchFiltersOptionsItem>
    )}
    {dropdownOptions.map((option: DropdownSearchFiltersOption) => (
      <StyledDropdownWithSearchFiltersOptionsItem
        key={option.id}
        tabIndex={isOpen ? 0 : -1}
        onClick={() => onSelectOption(option)}
        onKeyPress={(e: { key: string }) => e.key === 'Enter' && onSelectOption(option)}
      >
        <StyledDropdownWithSearchFiltersOptionLabel withFilters={!!option.filters?.length}>
          {option.label}
        </StyledDropdownWithSearchFiltersOptionLabel>
        {!!option.filters?.length && <StyledSearchFiltersQueryGroup filters={option.filters} />}
      </StyledDropdownWithSearchFiltersOptionsItem>
    ))}
  </StyledDropdown>
)
