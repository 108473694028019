import styled, { css } from 'styled-components'
import { flexMixin } from '~common'

export const StyledMyAccountSubMenu = styled.div<{ isOpen: boolean }>`
  order: 9999;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.navigation.navPrimary};
  transition: max-height 0.48s ease-in-out;
  max-height: 0rem;
  z-index: 99;
  top: 4.7375rem;
  width: 16rem;
  position: absolute;
  right: 0;
  display: none;
  ${({ isOpen }) =>
    isOpen &&
    css`
      max-height: 52rem;
    `}
  &:focus {
    outline: none;
  }

  @media (min-width: ${({ theme }) => theme.pxsToEms(600)}) {
    display: inline;
  }
`

export const StyledNavigation = styled.nav`
  ${flexMixin}
  z-index: 999;
  flex-direction: row;
`

export const StyledNavigationList = styled.ul<{ isSecondaryList?: boolean }>`
  ${flexMixin}
  flex-direction: row;
  margin-left: 2rem;
`

export const StyledBunPath = styled.path`
  transition: all 0.32s ease-in-out 0s;
`

export const StyledHamburgerBottomBunPath = styled(StyledBunPath)<{ isOpened?: boolean }>`
  transform-origin: 0.0625rem 0.75rem;
  ${({ isOpened }) =>
    isOpened &&
    css`
      transform: rotate(-45deg) scale(1.1);
    `}
`

export const StyledHamburgerTopBunPath = styled(StyledBunPath)<{ isOpened?: boolean }>`
  transform-origin: 0.0625rem 0.25rem;
  ${({ isOpened }) =>
    isOpened &&
    css`
      transform: rotate(45deg) scale(1.1);
    `}
`

export const StyledHamburgerFillingPath = styled.path<{ isOpened?: boolean }>`
  transition: opacity 0.16s ease-in-out 0s;
  ${({ isOpened }) =>
    isOpened &&
    css`
      opacity: 0;
    `}
`
