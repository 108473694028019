import { useEffect, useContext, useState } from 'react'
import { ProductTile } from '~components/product-tile'
import {
  ProductListContainer,
  ProductListBoundingBox,
  NoProductsListBoundingBox,
  NoProductsListContainer,
} from './styles'
import { useUserProducts } from '~hooks/use-user-products'
import { LoadingIndicator } from '~shared/ui/atoms/loading-indicator'
import { CenteredFlex } from '~shared/common'
import useGoogleAnalytics from '~hooks/use-google-analytics'
import { UserContext } from '~contexts/UserContext'
import { NoProducts } from '~components/error/no-products'
import { Toast } from '~shared/ui/molecules/toast'
import { WarningSvg } from '~common/svgs'
import { Status } from '~common'
import { StyledToast } from './styles'
import { useConfig } from '~hooks/use-config'
import { useToken } from '~hooks/use-token'
import { isSafeRedirect, isSameDomain } from '~helpers/paths'

export interface Product {
  name: string
}

export function ProductList({ isRegeneronClient }: { isRegeneronClient: boolean }) {
  const { products, shouldRedirectToEE, clientId, userIpAdress, loading } = useUserProducts()
  const { setClientId } = useContext(UserContext)
  //TODO: convert to context so that only 1 call to useGoogleAnalytics is required - any component can access trackEvent function
  const { trackEvent } = useGoogleAnalytics({ clientId })
  const config = useConfig()
  const { idToken } = useToken()
  const { user: cognitoUser, loading: userLoading } = useContext(UserContext)
  const redirectUrl = sessionStorage.getItem('redirect_url')
  const [redirecting, setRedirecting] = useState(false)

  const { EE_REDIRECT_URL } = useConfig()

  useEffect(() => {
    if (setClientId) {
      setClientId(clientId)
    }
  }, [clientId, setClientId])

  useEffect(() => {
    if (!redirectUrl && shouldRedirectToEE && EE_REDIRECT_URL) {
      setRedirecting(true)
      window.location.href = EE_REDIRECT_URL
      return
    }

    if (!config || !idToken || !cognitoUser || !redirectUrl || !products || !products.length) {
      return
    }

    const redirectProduct = products.find((e) => isSameDomain(redirectUrl, e.url))

    if (redirectProduct) {
      const url = redirectUrl
        .replace(':ID_TOKEN', idToken)
        .replace(':RETURN_URL', config.RETURN_URL)
        .replace(':USERNAME', cognitoUser ? cognitoUser.username : '')
      if (isSafeRedirect(url)) {
        if (redirectProduct.isOutsideIPRange) {
          //No redirect, stay on product list page
        } else {
          setRedirecting(true)
          sessionStorage.removeItem('redirect_url')
          window.location.href = url
        }
      } else {
        console.error(`URL not allowed, aborting redirect for: ${redirectUrl}`)
      }
    }
  }, [config, idToken, cognitoUser, redirectUrl, setRedirecting, products, shouldRedirectToEE, EE_REDIRECT_URL])

  const loadingBlock = (
    <CenteredFlex style={{ margin: '1.5rem' }}>
      <LoadingIndicator />
    </CenteredFlex>
  )

  if (loading || userLoading || redirecting) {
    return loadingBlock
  }

  if (products.length === 0) {
    return (
      <NoProductsListBoundingBox>
        <NoProductsListContainer>
          <NoProducts />
        </NoProductsListContainer>
      </NoProductsListBoundingBox>
    )
  }

  if (products.length === 1 && !products[0].isOutsideIPRange) {
    window.location.href = products[0].url
    return loadingBlock
  }

  return redirectUrl && !products.some((e) => e.isOutsideIPRange) ? (
    <></>
  ) : (
    <>
      <ProductListBoundingBox>
        <ProductListContainer>
          {products.map((product) => {
            if (product?.isOutsideIPRange) {
              return null
            }
            return (
              <ProductTile
                key={product.name}
                product={product}
                trackEvent={trackEvent}
                isExceptionalClientUI={isRegeneronClient}
              />
            )
          })}
        </ProductListContainer>
      </ProductListBoundingBox>
      {products.find((e) => e.name === 'InsightMeme')?.isOutsideIPRange && userIpAdress && (
        <StyledToast>
          <Toast
            id="1"
            title={'Insightmeme access restricted'}
            description={`Access to Pharmaspectra Insightmeme has been restricted based on your company's IP adress security policy. Your IP address is: ${userIpAdress} `}
            status={Status.WARNING}
            icon={<WarningSvg />}
            isRemovable={true}
          />
        </StyledToast>
      )}
      {products.find((e) => e.name === 'InsightMeme')?.isOutsideIPRange && !userIpAdress && (
        <StyledToast>
          <Toast
            id="2"
            title={'Insightmeme access restricted'}
            description={`Access to Pharmaspectra Insightmeme has been restricted based on your company's IP address security policy. Your IP address could not be determined. `}
            status={Status.WARNING}
            icon={<WarningSvg />}
            isRemovable={true}
          />
        </StyledToast>
      )}
    </>
  )
}
