import { useEffect, useState } from 'react'
import { Mode } from '~common/types'

export const useMode = (): [Mode] => {
  const [mode, setMode] = useState<Mode>('light')

  const onSelectMode = (mode: Mode) => {
    setMode(mode)
    if (mode === 'dark') document.body.classList.add('dark-mode')
    else document.body.classList.remove('dark-mode')
  }

  useEffect(() => {
    // Add listener to update styles
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (e) => onSelectMode(e.matches ? 'dark' : 'light'))

    // Setup dark/light mode for the first time
    onSelectMode(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')

    // Remove listener
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', () => {})
    }
  }, [])

  return [mode]
}
