import styled from 'styled-components'
import { Button, ButtonProps } from '~shared/ui/atoms/button'

export const RequestDemoContainer = styled.div`
  width: 400px;
  margin: auto;

  @media only screen and (max-width: 900px) {
    margin-top: 15%;
  }

  @media only screen and (max-width: 450px) {
    width: 250px;
  }
`

export const RequestDemoHeader = styled.h2`
  padding-bottom: 30px;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
`

export const RequestDemoContent = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: block;
`

export const StyledButton = styled(Button)<ButtonProps>`
  margin-top: 0.5rem;
  & svg path {
    transform: translateY(0rem);
  }
  &:hover svg path {
    transform: translateY(0.046875rem);
  }
  &:focus-visible svg path {
    transform: translateY(0.046875rem);
  }
  &:active svg path {
    transform: translateY(0.09375rem);
  }
`
