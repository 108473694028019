import { datadogRum } from '@datadog/browser-rum'
import { Logger as InfoLogger } from '~shared/utils/logger'
const { log } = new InfoLogger('frontend-login')

export class Logger {
  static error(err: Error, context?: any): void {
    datadogRum.addError(err, context)
    log.error(err)
  }

  static debug(message: string): void {
    log.debug(message)
  }
}
