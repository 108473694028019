import { getConfig } from '../config'

let environment = getConfig().ENV
if (environment === 'development') environment = 'dev'
if (environment === 'production') environment = 'prod'

const getDomain = function () {
  if (typeof window !== 'undefined' && typeof window.location.hostname === 'string') {
    const hostnameParts = window.location.hostname.split('.')
    let tld = window.location.hostname
    if (hostnameParts.length > 2) {
      tld = hostnameParts[hostnameParts.length - 2] + '.' + hostnameParts[hostnameParts.length - 1]
    }
    return ';Domain=' + tld
  }

  // Unable to get TLD from hostname, return default:
  return ';Domain=pharmaspectra.com'
}

const keyPrefix = `PS-${environment}-`
const zeroDate = 'Thu, 01 Jan 1970 00:00:01 GMT'
const cookieExpiryDays = 365

export class CustomCookieStorage {
  static setItem(key: string, value: string): string {
    if (typeof document !== 'undefined') {
      const date = new Date()
      date.setTime(date.getTime() + this.getSessionTimeoutMinutes() * 60 * 1000)
      const expires = ';Expires=' + date.toUTCString()
      document.cookie = keyPrefix + key + '=' + (value || '') + expires + getDomain()
      return value
    }
    return ''
  }

  static getItem(key: string): string {
    if (typeof document !== 'undefined') {
      const nameEQ = keyPrefix + key + '='
      const ca = document.cookie.split(';')
      let value = ''
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) == ' ') c = c.substring(1, c.length)
        if (c.indexOf(nameEQ) == 0) value = c.substring(nameEQ.length, c.length)
      }
      return value
    }
    return ''
  }

  static removeItem(key: string): boolean {
    if (typeof document !== 'undefined') {
      document.cookie = keyPrefix + key + '=;Expires=' + zeroDate + getDomain()
      return true
    }
    return false
  }

  static clear(): void {
    if (typeof document !== 'undefined') {
      const cookies = document.cookie.split(';')
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i]
        const eqPos = cookie.indexOf('=')
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
        document.cookie = name + '=;Expires=' + zeroDate + getDomain()
      }
    }
    return
  }

  static createCookiesFromLocalStorage(): void {
    for (const key in localStorage) {
      if (key.startsWith('CognitoIdentityServiceProvider.')) {
        const value = localStorage.getItem(key)
        if (value) {
          CustomCookieStorage.setItem(key, value)
        }
        localStorage.removeItem(key)
      }
    }
  }

  static getCookie(cName: string): string {
    const name = cName + '='
    const cDecoded = decodeURIComponent(document.cookie) //to be careful
    const cArr = cDecoded.split('; ')
    let res
    cArr.forEach((val) => {
      if (val.indexOf(name) === 0) res = val.substring(name.length)
    })
    return res
  }

  static setSessionTimeoutMinutes(sessionTimeout: number): void {
    if (window?.localStorage && sessionTimeout > 0) {
      window.localStorage.setItem('sessionTimeout', sessionTimeout.toString())
    }
  }

  static clearSessionTimeout(): void {
    if (window?.localStorage) {
      window.localStorage.removeItem('sessionTimeout')
    }
  }

  static getSessionTimeoutMinutes(): number {
    const defaultSessionTimeout = cookieExpiryDays * 24 * 60
    if (window?.localStorage) {
      const sessionTimeout = window.localStorage.getItem('sessionTimeout')
      if (sessionTimeout && parseInt(sessionTimeout)) {
        return Math.max(60, parseInt(sessionTimeout)) //minimum of 60 minutes
      }
    }
    return defaultSessionTimeout
  }
}
