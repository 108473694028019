import React, { RefObject } from 'react'
import { NavigationLink } from '~components/header/navigation-item/NavigationLink'
import { StyledNavigationItemLi } from './styles'

interface NavigationItemProps {
  children: React.ReactElement | string
  isTouchable: boolean
  id?: string
  linkTo?: string
  isOpenInNewTab?: boolean
  isActive?: boolean
  hasNoAction?: boolean
  hasIcon?: boolean
  isInlineSmall?: boolean
  style?: Record<string, string>
  ariaLabel?: string
  tabIndex?: number
  navigationItemRef?: RefObject<HTMLLIElement>
  className?: string
  isDisabled?: boolean
}

export const NavigationItem = ({
  children,
  linkTo,
  isOpenInNewTab,
  isActive,
  hasNoAction,
  style,
  ariaLabel,
  hasIcon = true,
  tabIndex,
  navigationItemRef,
  isInlineSmall = false,
  isTouchable,
  isDisabled,
  className,
  id,
}: NavigationItemProps): JSX.Element => {
  const linkTarget = isOpenInNewTab ? '_blank' : undefined
  return (
    <StyledNavigationItemLi
      className={className}
      ref={navigationItemRef}
      style={style}
      hasNoAction={hasNoAction}
      hasIcon={hasIcon}
      isInlineSmall={isInlineSmall}
      isActive={isActive}
      isTouchable={isTouchable}
      isDisabled={isDisabled}
    >
      {linkTo ? (
        <NavigationLink type="custom" aria-label={ariaLabel} href={linkTo} tabIndex={tabIndex} target={linkTarget}>
          {children}
        </NavigationLink>
      ) : (
        children
      )}
    </StyledNavigationItemLi>
  )
}
