import { BrowserRouter, Routes, Route } from 'react-router-dom'
import React, { useState } from 'react'
import { Layout } from '~components/layout/Layout'
import { ProvideEmail } from '~pages/provide-email'
import { LinkThemeProvider } from '~common/theme'
import { LoginNonSSO } from '~pages/login-nonsso'
import { Products } from '~pages/products'
import { LoginWithCode } from '~pages/login-with-code'
import { AuthRoute } from '~components/auth-route'
import { paths } from '~helpers'
import { useMode } from '~common/hooks/use-mode'
import { ChangePassword } from '~pages/change-password'
import { ZendeskRedirect } from '~pages/zendesk-redirect'
import { ForgotPassword } from '~pages/forgot-password'
import { ResetPassword } from '~pages/reset-password'
import { GoogleAnalytics } from '~components/google-analytics'
import { Logout } from '~pages/logout'
import { ErrorBoundary } from '~components/error/error-boundary'
import { NoRoute } from '~components/error/no-route'
import { UserContext } from '~contexts/UserContext'
import useUserStatus from '~hooks/use-user-status'

export default function App() {
  const [mode] = useMode()
  const { user, userAttributes, loading: userLoading } = useUserStatus()
  const [clientId, setClientId] = useState<number>()

  return (
    <LinkThemeProvider mode={mode}>
      <UserContext.Provider
        value={{
          user,
          userAttributes,
          clientId,
          setClientId,
          loading: userLoading,
        }}
      >
        <Layout>
          <ErrorBoundary>
            <BrowserRouter>
              <GoogleAnalytics>
                <Routes>
                  <Route
                    path={paths.home}
                    element={
                      <AuthRoute redirectUrl={paths.products} isAuthRequired={false}>
                        <ProvideEmail />
                      </AuthRoute>
                    }
                  />
                  <Route
                    path={paths.nonSSO}
                    element={
                      <AuthRoute redirectUrl={paths.products} isAuthRequired={false}>
                        <LoginNonSSO />
                      </AuthRoute>
                    }
                  />
                  <Route
                    path={paths.products}
                    element={
                      <AuthRoute redirectUrl={paths.home} isAuthRequired={true}>
                        <Products />
                      </AuthRoute>
                    }
                  />
                  <Route
                    path={paths.login}
                    element={
                      <AuthRoute redirectUrl={paths.products} isAuthRequired={false}>
                        <LoginWithCode />
                      </AuthRoute>
                    }
                  />
                  <Route
                    path={paths.forgotPassword}
                    element={
                      <AuthRoute redirectUrl={paths.products} isAuthRequired={false}>
                        <ForgotPassword />
                      </AuthRoute>
                    }
                  />
                  <Route
                    path={paths.resetPassword}
                    element={
                      <AuthRoute redirectUrl={paths.products} isAuthRequired={false}>
                        <ResetPassword />
                      </AuthRoute>
                    }
                  />
                  <Route
                    path={paths.changePassword}
                    element={
                      <AuthRoute redirectUrl={paths.home} isAuthRequired={true}>
                        <ChangePassword />
                      </AuthRoute>
                    }
                  />
                  <Route path={paths.zendesk} element={<ZendeskRedirect />} />
                  <Route path={paths.logout} element={<Logout />} />
                  <Route path="*" element={<NoRoute />} />
                </Routes>
              </GoogleAnalytics>
            </BrowserRouter>
          </ErrorBoundary>
        </Layout>
      </UserContext.Provider>
    </LinkThemeProvider>
  )
}
