import React from 'react'
import styled from 'styled-components'
import { CustomSVG } from '~components/ui/nucleons/custom-svg'

export const StyledCheckMarkIcon = styled.path<{ fillColor?: string }>`
  fill: ${({ theme, fillColor }) => fillColor ?? theme.colors.buttons.link};
`

export type CheckMarkIconProps = React.HTMLAttributes<HTMLOrSVGElement> & {
  width?: string
  height?: string
  pathD?: string
  fillColor?: string
}

export const CheckMarkIcon = ({
  width = '12',
  height = '8',
  pathD = 'M11.0382 1.34826L9.97752 0.287598L4.67422 5.5909L2.02257 2.93925L0.961914 3.99991L4.67422 7.71222L11.0382 1.34826Z',
  fillColor,
  ...rest
}: CheckMarkIconProps): JSX.Element => (
  <CustomSVG width={width} height={height} {...rest}>
    <StyledCheckMarkIcon d={pathD} fillColor={fillColor} />
  </CustomSVG>
)
