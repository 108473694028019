import React from 'react'
import { Controller } from 'react-hook-form'
import { ControlledProps } from '../types'
import { Checkbox, CheckboxProps } from '~components/ui/atoms/checkbox'

export type ControlledCheckboxProps<TFormValues> = ControlledProps<TFormValues> & CheckboxProps

export const ControlledCheckbox = <TFormValues extends Record<string, unknown>>({
  name,
  control,
  rules = {},
  errors,
  ...restProps
}: ControlledCheckboxProps<TFormValues>): JSX.Element => (
  <Controller
    rules={rules}
    control={control}
    name={name}
    defaultValue={restProps.defaultChecked}
    render={({ field }) => (
      <>
        <Checkbox isChecked={field.value} onChange={field.onChange} onBlur={field.onBlur} {...restProps} />
        {errors?.[name] && <>{errors?.[name]?.message}</>}
      </>
    )}
  />
)
