import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { getConfig } from '~config'
import Amplify from 'aws-amplify'
import { datadogRum, RumErrorEvent } from '@datadog/browser-rum'
import { CustomCookieStorage } from '~common/auth/custom-cookie-storage'

const config = getConfig()

Amplify.configure({
  Auth: {
    region: config.AWS_REGION,
    userPoolId: config.USER_POOL_ID,
    userPoolWebClientId: config.USER_POOL_WEB_CLIENT_ID,
    storage: CustomCookieStorage,
  },
  oauth: {
    domain: config.COGNITO_SSO_DOMAIN,
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: `${config.COGNITO_SSO_REDIRECT_URI}/login`,
    redirectSignOut: `${config.COGNITO_SSO_REDIRECT_URI}/logout`,
    responseType: 'code',
  },
})

if (config.NODE_ENV === 'production' && config.ENV) {
  datadogRum.init({
    site: 'datadoghq.com',
    applicationId: config.RUM_APP_ID,
    clientToken: config.RUM_CLIENT_TOKEN,
    service: `login-frontend-${config.ENV}`,
    version: `1.0.${config.GIT_HASH}`,
    sampleRate: 100,
    replaySampleRate: 100,
    env: config.ENV,
    trackInteractions: ['dev', 'prod'].includes(config.ENV as string),
    beforeSend: (event) => {
      if (event.type === 'error') {
        const err = (event as RumErrorEvent).error
        const isTestPageError = err.message === 'Throwing an error for testing purposes'
        if (isTestPageError) return false
      }

      return
    },
  })

  datadogRum.startSessionReplayRecording()
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
