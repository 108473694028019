import { getConfig } from '~config'

const ADMIN_PUBLIC_API = getConfig().AWS_API

export async function getProducts(cognitoId: string, idToken: string) {
  const data = await fetch(`${ADMIN_PUBLIC_API}/products?cognitoId=${cognitoId}&idToken=${idToken}`)
  return data.json()
}

export async function syncCognitoUser(idToken: string) {
  await fetch(`${ADMIN_PUBLIC_API}/sync-cognito-user?idToken=${idToken}`, {
    method: 'POST',
  })
}

export async function checkInmemeRegister(): Promise<{ isAllowed: boolean }> {
  const data = await fetch(`${ADMIN_PUBLIC_API}/inmeme-register`, {
    method: 'GET',
  })
  return data.json()
}

export async function getLoginSettings(
  email: string,
): Promise<{ ssoProvider: string; clientId: number; sessionTimeout: number; shouldRedirectToEE?: boolean }> {
  const data = await fetch(`${ADMIN_PUBLIC_API}/login-settings?email=${email}`)
  return data.json()
}
