import styled, { css } from 'styled-components'
export const flexMixin = css`
  display: flex;
  display: -ms-flexbox;
`
export const Flex = styled.div`
  ${flexMixin}
`

export const LoginTemplateContainer = styled(Flex)`
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: row;
`
export const Row = styled.div`
  display: flex;
  flex-flow: wrap;
  position: absolute;
  padding-bottom: 150px;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  max-width: 1100px;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
    top: 5%;
  }

  @media only screen and (max-height: 600px) and (orientation: landscape) {
    top: 3%;
  }
`

export const Column = styled.div`
  flex: 50%;
`
export const StyledHeader = styled.header<{ isExpanded: boolean }>`
  display: flex;
  display: -ms-flexbox;
  margin-bottom: 1rem;
  margin-right: auto;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 400px;
  display: block;

  @media only screen and (max-width: 450px) {
    width: 250px;
  }
`
