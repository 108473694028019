import styled from 'styled-components'
import { Flex } from '~common'
import { Divider } from '~components/ui/atoms/divider'

export const FooterContainer = styled.footer`
  margin: 1.5rem 1rem 0;
  font-size: 0.625rem;
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletMedium)}) {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
`

export const FooterLogo = styled(Flex)`
  align-items: baseline;
`

export const FooterLogoCopy = styled.span`
  margin-left: 1rem;
`

export const FooterLinks = styled(Flex)`
  white-space: nowrap;
  @media only screen and (max-width: 900px) {
    > * {
      &:first-child {
        margin-left: 0;
      }
    }
  }
`

export const StyledFooterContent = styled(Flex)`
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.pxsToEms(theme.breakPoints.tabletSmall)}) {
    flex-direction: row;
    align-items: center;
  }
`

export const FooterLink = styled.a`
  margin-left: 0.9375rem;
  margin-right: 0.9375rem;
  &:last-child {
    margin-right: 0;
  }
  font-family: 'Inter';
  :hover {
    color: ${({ theme }) => theme.colors.text.textHoverSecondary};
  }
`

export const StyledDivider = styled(Divider)`
  border-color: ${({ theme }) => theme.colors.brand.blueSecondary};
`
