import styled, { css } from 'styled-components'
import { ButtonWithNoStyle, flexMixin } from '~common'
import { textSizes } from '~common/theme'
import { Variant, Size, Position } from './Button'

export const iconStyles = (iconPosition: Position): Record<string, Record<string, string>> => ({
  xs: {
    [`margin-${iconPosition === 'left' ? 'right' : 'left'}`]: '0.125rem',
    width: '0.625rem',
    height: '0.625rem',
  },
  sm: {
    [`margin-${iconPosition === 'left' ? 'right' : 'left'}`]: '0.25rem',
    width: '0.75rem',
    height: '0.75rem',
  },
  md: {
    [`margin-${iconPosition === 'left' ? 'right' : 'left'}`]: '0.375rem',
    width: '0.875rem',
    height: '0.875rem',
  },
  lg: {
    [`margin-${iconPosition === 'left' ? 'right' : 'left'}`]: '0.5rem',
    width: '1rem',
    height: '1rem',
  },
})

const buttonStylesMixin = {
  sm: {
    ...textSizes.desktop.buttonS,
    'border-radius': '0.0937rem',
  },
  md: {
    ...textSizes.desktop.buttonM,
    'border-radius': '0.125rem',
  },
  lg: {
    ...textSizes.desktop.buttonL,
    'border-radius': '0.1875rem',
  },
}

export const buttonStyles = {
  primary: {
    xs: {
      ...buttonStylesMixin['sm'],
      padding: '0.125rem 0.375rem',
    },
    sm: {
      ...buttonStylesMixin['sm'],
      padding: '0.25rem 0.5rem',
    },
    md: {
      ...buttonStylesMixin['md'],
      padding: '0.375rem 0.5rem',
    },
    lg: {
      ...buttonStylesMixin['lg'],
      padding: '0.6rem 1rem',
    },
  },
  secondary: {
    xs: {
      ...buttonStylesMixin['sm'],
      padding: '0.0625rem 0.3437rem',
    },
    sm: {
      ...buttonStylesMixin['sm'],
      padding: '0.1875rem 0.4687rem',
    },
    md: {
      ...buttonStylesMixin['md'],
      padding: '0.3125rem 0.4687rem',
    },
    lg: {
      ...buttonStylesMixin['lg'],
      padding: '0.4375rem 0.9687rem',
    },
  },
  tertiary: {
    xs: {
      ...buttonStylesMixin['sm'],
      padding: '0.125rem 0.375rem',
    },
    sm: {
      ...buttonStylesMixin['sm'],
      padding: '0.25rem 0.5rem',
    },
    md: {
      ...buttonStylesMixin['md'],
      padding: '0.375rem 0.5rem',
    },
    lg: {
      ...buttonStylesMixin['lg'],
      padding: '0.5rem 1rem',
    },
  },
  neutral: {
    xs: {
      ...buttonStylesMixin['sm'],
      padding: '0.0625rem 0.3437rem',
    },
    sm: {
      ...buttonStylesMixin['sm'],
      padding: '0.1875rem 0.4687rem',
    },
    md: {
      ...buttonStylesMixin['md'],
      padding: '0.3125rem 0.4687rem',
    },
    lg: {
      ...buttonStylesMixin['lg'],
      padding: '0.4375rem 0.9687rem',
    },
  },
  link: {
    xs: {
      ...buttonStylesMixin['sm'],
    },
    sm: {
      ...buttonStylesMixin['sm'],
    },
    md: {
      ...buttonStylesMixin['md'],
    },
    lg: {
      ...buttonStylesMixin['lg'],
    },
  },
}

export const StyledButton = styled(ButtonWithNoStyle)<{
  size: Size
  variant: Variant
  iconPosition: Position
  hasChildren: boolean
  active: boolean
}>`
  ${flexMixin}
  flex-direction: ${({ iconPosition }) => (iconPosition === 'left' ? 'row' : 'row-reverse')};
  align-items: center;
  transition: all 0.24s ease-in-out;
  cursor: pointer;
  & > svg {
    ${({ size, iconPosition }) => iconStyles(iconPosition)[size]};
    margin-right: ${({ hasChildren }) => !hasChildren && '0'};
  }
  ${({ variant, size }) => buttonStyles[variant][size]};
  ${({ variant, theme, active }) => {
    if (variant === 'primary') {
      return css`
        color: ${theme.colors.textOn.textOnButtonPrimary};
        background: ${theme.colors.buttons.buttonBackgrondPrimary};
        & > svg {
          fill: ${theme.colors.textOn.textOnButtonPrimary};
        }
        &:hover:enabled {
          background: ${theme.colors.buttons.buttonBackgroundPrimaryHover};
        }
        &:focus-visible:enabled {
          background: ${theme.colors.buttons.buttonBackgroundPrimaryHover};
        }
        &:active:enabled {
          background: ${theme.colors.buttons.buttonBackgroundPrimaryActive};
        }
      `
    }

    if (variant === 'secondary') {
      return css`
        color: ${theme.colors.textOn.textOnButtonSecondary};
        background: ${theme.colors.buttons.buttonBackgroundSecondary};
        border: ${`1px solid ${theme.colors.textOn.textOnButtonSecondary}`};
        & > svg {
          fill: ${theme.colors.textOn.textOnButtonSecondary};
        }
        &:hover:enabled {
          color: ${theme.colors.buttons.buttonBackgroundPrimaryHover};
          border: ${`1px solid ${theme.colors.buttons.buttonBackgroundPrimaryHover}`};
          & > svg {
            fill: ${theme.colors.buttons.buttonBackgroundPrimaryHover};
          }
        }
        &:focus-visible:enabled {
          color: ${theme.colors.buttons.buttonBackgroundPrimaryHover};
          border: ${`1px solid ${theme.colors.buttons.buttonBackgroundPrimaryHover}`};
          & > svg {
            fill: ${theme.colors.buttons.buttonBackgroundPrimaryHover};
          }
        }
        &:active:enabled {
          color: ${theme.colors.buttons.buttonBackgroundPrimaryActive};
          border: ${`1px solid ${theme.colors.buttons.buttonBackgroundPrimaryActive}`};
          & > svg {
            fill: ${theme.colors.buttons.buttonBackgroundPrimaryActive};
          }
        }
      `
    }

    if (variant === 'tertiary') {
      return css`
        color: ${active ? theme.colors.textOn.textOnButtonTertiaryActive : theme.colors.textOn.textOnButtonTertiary};
        background: ${active
          ? theme.colors.buttons.buttonBackgroundTertiaryActive
          : theme.colors.buttons.buttonBackgroundTertiary};
        border: ${active ? `1px solid ${theme.colors.textOn.textOnButtonTertiaryActive}` : ''};
        & > svg,
        & > svg path {
          fill: ${active ? theme.colors.textOn.textOnButtonTertiaryActive : theme.colors.textOn.textOnButtonTertiary};
        }
        &:hover:enabled {
          background: ${theme.colors.buttons.buttonBackgroundTertiaryHover};
          color: ${theme.colors.textOn.textOnButtonTertiaryHover};
        }
        &:focus-visible:enabled {
          background: ${theme.colors.buttons.buttonBackgroundTertiaryHover};
          color: ${theme.colors.textOn.textOnButtonTertiaryHover};
        }
        &:active:enabled {
          background: ${theme.colors.buttons.buttonBackgroundTertiaryHover};
          color: ${theme.colors.textOn.textOnButtonTertiaryHover};
        }
      `
    }

    if (variant === 'neutral') {
      return css`
        color: ${theme.colors.textOn.textOnButtonNeutral};
        background: ${theme.colors.buttons.buttonBackgroundSecondary};
        border: ${`1px solid ${theme.colors.textOn.textOnButtonNeutral}`};
        & > svg {
          fill: ${theme.colors.textOn.textOnButtonNeutral};
        }
        &:hover:enabled {
          color: ${theme.colors.textOn.textOnButtonNeutralHover};
          border: ${`1px solid ${theme.colors.textOn.textOnButtonNeutralHover}`};
          & > svg {
            fill: ${theme.colors.textOn.textOnButtonNeutralHover};
          }
        }
        &:focus-visible:enabled {
          color: ${theme.colors.textOn.textOnButtonNeutralHover};
          border: ${`1px solid ${theme.colors.textOn.textOnButtonNeutralHover}`};
          & > svg {
            fill: ${theme.colors.textOn.textOnButtonNeutralHover};
          }
        }
        &:active:enabled {
          color: ${theme.colors.textOn.textOnButtonNeutralActive};
          border: 1px solid ${theme.colors.textOn.textOnButtonNeutralActive};
          & > svg {
            fill: ${theme.colors.textOn.textOnButtonNeutralActive};
          }
        }
      `
    }

    if (variant === 'link') {
      return css`
        color: ${theme.colors.buttons.link};
        font-weight: normal;
        & > svg {
          fill: ${theme.colors.buttons.link};
          margin-right: 0.25rem;
        }

        &:hover:enabled,
        &:focus-visible:enabled {
          color: ${theme.colors.text.textHoverPrimary};
          & > svg {
            fill: ${theme.colors.text.textHoverPrimary};
          }
        }
      `
    }
  }}

  &:disabled {
    cursor: default;
    opacity: 0.4;
  }
`
