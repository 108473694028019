import * as React from 'react'
import { useCallback, useState } from 'react'
import { ResetPasswordTemplate } from '~components/reset-password-template'
import { CognitoClient } from '../../auth/CognitoClient'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { buildUrl, paths } from '~helpers'
import { CenteredFlex, Status, StyledSection } from '~shared/common'
import { SuccessSvg, WarningSvg } from '~shared/common/svgs'
import { Toast } from '~shared/ui/molecules/toast'
import { Heading } from '~shared/ui/atoms/heading'
import { Button } from '~shared/ui/atoms/button'
import {
  StyledResetPasswordForm,
  StyledCodeInputSection,
  StyledInput,
  StyledPasswordHint,
  StyledTextButton,
} from './styles'
import usePageTitle from '~hooks/use-page-title'

interface FormValues {
  code: string
  password: string
  confirmPassword: string
}

export function ResetPassword() {
  const [searchParams] = useSearchParams()
  const [message, setMessage] = useState<{ title: string; message: string; status: Status } | null>(null)
  const [isSuccessfull, setIsSuccessfull] = useState(false)
  const navigate = useNavigate()

  const email = searchParams.get('email') || ''

  const defaultValues: FormValues = {
    code: '',
    password: '',
    confirmPassword: '',
  }

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>({ defaultValues })

  const errorFormat = (code: string, message: string): string => {
    switch (code) {
      case 'InvalidPasswordException':
        return message.split('Password does not conform to policy: ').pop() ?? 'Password failed to meet requirements.'
      default:
        return message
    }
  }

  const onSubmitHandler = useCallback(
    async (values: FormValues) => {
      const errorTitle: { [key: string]: string } = {
        CodeMismatchException: 'Unauthorized',
        InvalidPasswordException: 'Invalid Password',
      }
      return new Promise<boolean>(async (res, rej) => {
        setMessage(null)
        try {
          const newPassword = values.password
          const confirmPassword = values.confirmPassword

          if (newPassword !== confirmPassword) {
            setMessage({
              title: 'Password Mismatch',
              message: 'Please make sure password and confirm passwords match.',
              status: Status.ERROR,
            })
            rej()
          } else {
            const username = email
            const code = values.code
            await CognitoClient.resetPassword({ username, code, newPassword })
            res(true)
            setIsSuccessfull(true)
          }
        } catch (error) {
          setMessage({
            title: errorTitle[error.code] ?? 'Error',
            message: errorFormat(error.code, error.message),
            status: Status.ERROR,
          })
          rej(error)
        }
      })
    },
    [email],
  )

  const resendCode = useCallback(async () => {
    return new Promise<boolean>(async (res, rej) => {
      setMessage(null)
      await CognitoClient.forgotPassword({ username: email })
      setMessage({ title: 'Resent Code', message: 'A new code was sent to your email', status: Status.SUCCESS })
    })
  }, [email, setMessage])

  const goToDashboard = () => {
    navigate(buildUrl(paths.nonSSO, email ? [{ id: 'email', value: email }] : []))
  }

  usePageTitle('Reset password | Pharmaspectra')

  return (
    <ResetPasswordTemplate>
      <>
        {!isSuccessfull && (
          <StyledResetPasswordForm onSubmit={handleSubmit(onSubmitHandler)}>
            <Heading level="2">Reset password</Heading>

            <StyledSection>
              {message && (
                <Toast
                  description={message.message}
                  icon={({ [Status.ERROR]: <WarningSvg />, [Status.SUCCESS]: <SuccessSvg /> } as any)[message.status]}
                  id="1"
                  isRemovable={false}
                  status={message.status}
                  title={message.title}
                />
              )}
            </StyledSection>

            <StyledCodeInputSection>
              <StyledTextButton href="" size="sm" onClick={resendCode}>
                Resend Code
              </StyledTextButton>
              <StyledInput
                inputSize="md"
                name="code"
                type="text"
                label="Code"
                role="title"
                control={control}
                placeholder=""
                rules={{
                  required: 'Code is required',
                }}
                labelPosition="top"
                errors={errors}
                data-testid="code-input"
              ></StyledInput>
            </StyledCodeInputSection>

            <StyledSection>
              <StyledInput
                inputSize="md"
                name="password"
                label="New password"
                type="password"
                role="title"
                control={control}
                placeholder=""
                rules={{
                  required: 'password is required',
                }}
                labelPosition="top"
                errors={errors}
                data-testid="password-input"
              ></StyledInput>
              <StyledPasswordHint color="secondary">
                Please make sure password is at least 8 characters in length, has one numeric character, one lowercase
                character and one uppercase character.
              </StyledPasswordHint>
            </StyledSection>

            <StyledSection>
              <StyledInput
                inputSize="md"
                name="confirmPassword"
                label="Confirm new password"
                type="password"
                role="title"
                control={control}
                placeholder=""
                rules={{
                  required: 'Please confirm password',
                }}
                labelPosition="top"
                errors={errors}
                data-testid="confirm-password-input"
              ></StyledInput>
            </StyledSection>

            <StyledSection>
              <CenteredFlex>
                <Button type="submit" variant="primary" size="lg">
                  Reset
                </Button>
              </CenteredFlex>
            </StyledSection>
          </StyledResetPasswordForm>
        )}

        {isSuccessfull && (
          <StyledResetPasswordForm>
            <Heading level="2">Success</Heading>
            <StyledSection>
              <Toast
                description={'Please continue to the dashboard'}
                icon={<SuccessSvg />}
                id="1"
                isRemovable={false}
                status={Status.SUCCESS}
                title="Password changed successfully"
              />
            </StyledSection>

            <StyledSection>
              <CenteredFlex>
                <Button variant="primary" size="lg" onClick={goToDashboard}>
                  Back to Dashboard
                </Button>
              </CenteredFlex>
            </StyledSection>
          </StyledResetPasswordForm>
        )}
      </>
    </ResetPasswordTemplate>
  )
}
