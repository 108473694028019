import styled from 'styled-components'
import { Flex, StyledSection } from '~shared/common'

export const ForgotPasswordTemplateContainer = styled(Flex)`
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: row;
`

export const StyledLogoSection = styled(StyledSection)`
  margin-bottom: 3.6em;
`

export const Row = styled(Flex)`
  flex-flow: wrap;
  position: absolute;
  padding-bottom: 150px;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    top: 5%;
  }
  @media only screen and (orientation: landscape) {
    top: 3%;
  }
`

export const Column = styled.div`
  flex: 50%;
`
