import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  UserData,
} from 'amazon-cognito-identity-js'
import { getConfig } from '../config'

let cognitoPool: CognitoUserPool
const config = getConfig()

function getCognitoUserPool(): CognitoUserPool {
  if (!cognitoPool) {
    cognitoPool = new CognitoUserPool({
      UserPoolId: config.USER_POOL_ID,
      ClientId: config.USER_POOL_WEB_CLIENT_ID,
    })
  }
  return cognitoPool
}

export class CognitoClient {
  public static signIn(input: CognitoSignInRequest): Promise<CognitoUserData> {
    return new Promise<CognitoUserData>((res, rej) => {
      const authenticationDetails = new AuthenticationDetails({
        Username: input.username,
        Password: input.password,
      })

      const userData = {
        Username: input.username,
        Pool: getCognitoUserPool(),
      }

      const cognitoUser = new CognitoUser(userData) as any
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function () {
          cognitoUser.getUserData((err: object, data: UserData) => {
            const { idToken, accessToken } = cognitoUser.signInUserSession
            if (err) {
              rej(err)
            } else {
              res(CognitoClient.parseUserData(data, idToken.jwtToken, accessToken.jwtToken))
            }
          })
        },

        onFailure: function (err: object) {
          rej(err)
        },
      })
    })
  }

  public static signOut(): Promise<void> {
    return new Promise<void>((res, rej) => {
      try {
        const cognitoUser = getCognitoUserPool().getCurrentUser()
        if (cognitoUser != null) {
          cognitoUser.signOut(() => {
            res()
          })
        } else {
          res()
        }
      } catch (err) {
        rej(err)
      }
    })
  }

  public static getCurrentUserSession(): Promise<CognitoUserSession | null> {
    return new Promise<CognitoUserSession | null>((res, rej) => {
      try {
        const cognitoUser = getCognitoUserPool().getCurrentUser()
        if (cognitoUser != null) {
          cognitoUser.getSession((err: object, session?: CognitoUserSession | null) => {
            if (err || !session) {
              rej(err)
            } else {
              res(session)
            }
          })
        } else {
          res(null)
        }
      } catch (err) {
        rej(err)
      }
    })
  }

  private static parseUserData(userData: UserData, idToken: string, accessToken: string): CognitoUserData {
    const cognitoUserData = {} as CognitoUserData
    cognitoUserData.userName = userData.Username
    userData.UserAttributes.forEach((attr) => {
      switch (attr.Name) {
        case 'email':
          cognitoUserData.email = attr.Value
          break
        case 'given_name':
          cognitoUserData.givenName = attr.Value
          break
        case 'family_name':
          cognitoUserData.familyName = attr.Value
          break
      }
    })
    return { ...cognitoUserData, accessToken, idToken }
  }

  public static forgotPassword(input: CognitoForgotPasswordRequest): Promise<CognitoUserData> {
    return new Promise<CognitoUserData>((res, rej) => {
      const userData = {
        Username: input.username,
        Pool: getCognitoUserPool(),
      }

      const cognitoUser = new CognitoUser(userData) as any

      cognitoUser.forgotPassword({
        onSuccess: function (result: any) {
          res(result)
        },

        onFailure: function (err: object) {
          rej(err)
        },
      })
    })
  }

  public static resetPassword(input: CognitoResetPasswordRequest): Promise<CognitoUserData> {
    return new Promise<CognitoUserData>((res, rej) => {
      const userData = {
        Username: input.username,
        Pool: getCognitoUserPool(),
      }

      const cognitoUser = new CognitoUser(userData) as any

      const { code, newPassword } = input

      cognitoUser.confirmPassword(code, newPassword, {
        onSuccess: function (result: any) {
          res(result)
        },

        onFailure: function (err: object) {
          rej(err)
        },
      })
    })
  }
}

export interface CognitoSignInRequest {
  username: string
  password: string
}

export interface CognitoUserData {
  userName: string
  email: string
  givenName: string
  familyName: string
  idToken: string
  accessToken: string
}

export interface CognitoForgotPasswordRequest {
  username: string
}

export interface CognitoResetPasswordRequest {
  username: string
  code: string
  newPassword: string
}
