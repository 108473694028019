import styled, { css } from 'styled-components'
import { browserTruncateMixin, flexMixin } from '~common'
import { FormItemSize } from '~components/ui/atoms/form-item'
import { SearchFiltersQueryGroup } from '~components/ui/organisms/search-filters-query-group'
import { chevronSize } from '../Select'

type SelectDropdownSize = FormItemSize

export const styledSelectMixin = css`
  ${browserTruncateMixin}
  border: 1px solid ${({ theme }) => theme.colors.neutrals.neutral40};
  font: inherit;
  cursor: pointer;
  appearance: none;
  margin: 0;
  color: ${({ theme }) => theme.colors.text.textSecondary};
  transition: all 0.24s ease-in-out;
  background-color: ${({ theme }) => theme.colors.brand.backgroundPrimary};
  max-width: 16rem;
  svg {
    transition: all 0.24s ease-in-out;
    fill: ${({ theme }) => theme.colors.neutrals.neutral40};
  }
  &:hover,
  &:focus-visible {
    border: 1px solid ${({ theme }) => theme.colors.neutrals.neutral60};
  }
  &:active {
    border: 1px solid ${({ theme }) => theme.colors.neutrals.neutral80};
  }
  &:disabled {
    cursor: default;
    opacity: 0.2;
  }
`

export const StyledDefaultSelect = styled.select<{ selectDropdownSize: SelectDropdownSize }>`
  ${({ theme }) => theme.textSizes.desktop.label};
  ${styledSelectMixin}
  ${({ selectDropdownSize }) => css`
    padding: ${selectDropdownSize === 'sm'
      ? `0.1875rem 1.4375rem 0.1875rem 0.5rem;`
      : `0.375rem 1.75rem 0.375rem 0.5rem`};
    background-repeat: no-repeat;
    background-position: calc(100% - 0.5rem) calc(100% - ${selectDropdownSize === 'sm' ? '0.375rem' : '0.5312rem'});
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='${chevronSize[
      selectDropdownSize
    ].size}' height='${chevronSize[selectDropdownSize]
      .size}' fill='rgb(186, 178, 168)'><path fillRule='evenodd' clipRule='evenodd' d='${chevronSize[selectDropdownSize]
      .dValue}' /></svg>");

    &:hover,
    &:focus-visible,
    &:active {
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='${chevronSize[
        selectDropdownSize
      ].size}' height='${chevronSize[selectDropdownSize]
        .size}' fill='rgb(103, 98, 93)'><path fillRule='evenodd' clipRule='evenodd' d='${chevronSize[selectDropdownSize]
        .dValue}' /></svg>");
      @media only screen and (prefers-color-scheme: dark) {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='${chevronSize[
          selectDropdownSize
        ].size}' height='${chevronSize[selectDropdownSize]
          .size}' fill='rgb(221, 217, 212)'><path fillRule='evenodd' clipRule='evenodd' d='${chevronSize[
          selectDropdownSize
        ].dValue}' /></svg>");
      }
    }

    &:disabled {
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='${chevronSize[
        selectDropdownSize
      ].size}' height='${chevronSize[selectDropdownSize]
        .size}' fill='rgb(186, 178, 168)'><path fillRule='evenodd' clipRule='evenodd' d='${chevronSize[
        selectDropdownSize
      ].dValue}' /></svg>");
    }
  `}
`

export const StyledCustomSelectWrapper = styled.div`
  position: relative;
`

interface StyledCustomSelectProps {
  isOpened: boolean
  selectDropdownSize: SelectDropdownSize
}

export const StyledCustomSelect = styled.button<StyledCustomSelectProps>`
  ${styledSelectMixin}
  ${flexMixin}
  align-items: center;
  justify-content: space-between;
  ${({ selectDropdownSize }) => {
    if (selectDropdownSize === 'sm') {
      return css`
        padding: 0.1875rem 0.5rem;
      `
    }
    if (selectDropdownSize === 'md') {
      return css`
        padding: 0.375rem 0.5rem;
      `
    }
  }}
`

export const StyledSelectWithOptionsWrapper = styled(StyledCustomSelectWrapper)`
  width: 100%;
  ${StyledCustomSelect} {
    width: 100%;
    max-width: none;
    & * {
      ${browserTruncateMixin}
    }
  }
`

export const StyledSearchFiltersQueryGroup = styled(SearchFiltersQueryGroup)`
  ${browserTruncateMixin}
  flex-wrap: nowrap;
  margin-left: 0.5rem;
  flex-shrink: 2;
`

export const StyledSelectedOptionLabel = styled.p<{ withFilters: boolean }>`
  ${({ withFilters }) =>
    !withFilters &&
    css`
      ${browserTruncateMixin};
      width: 100%;
    `}
`
