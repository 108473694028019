import React from 'react'
import styled from 'styled-components'
import { Flex, SearchedQueryFilters } from '~common'

const StyledKey = styled.dt`
  ${({ theme }) => theme.textSizes.desktop.searchStrong};
  color: ${({ theme }) => theme.colors.text.textPrimary};
  margin-right: 0.5rem;
`

const StyledValue = styled.dd`
  ${({ theme }) => theme.textSizes.desktop.label};
  color: ${({ theme }) => theme.colors.text.textSecondary};
`

const StyledSearchFiltersQueryGroup = styled(Flex)`
  align-items: center;
  flex-wrap: wrap;
  ${StyledValue}:not(:last-child) {
    margin-right: 0.25rem;
  }
`

export interface SearchFiltersQueryGroupProps extends React.HTMLAttributes<HTMLDListElement> {
  filters: SearchedQueryFilters
  label?: string
}

export const SearchFiltersQueryGroup = ({ label, filters, ...rest }: SearchFiltersQueryGroupProps): JSX.Element => (
  <StyledSearchFiltersQueryGroup as={label ? 'dl' : 'ul'} {...rest}>
    {label && <StyledKey>{`${label}:`}</StyledKey>}
    {filters.map((filter, i) => (
      <React.Fragment key={i}>
        {Array.isArray(filter) && filter.length > 1 ? (
          filter.map((subFilter, subFilterIndex) => (
            <StyledValue as={label ? 'dd' : 'li'} key={subFilterIndex}>{`${subFilter}${
              subFilterIndex != filter.length - 1 ? ',' : ' •'
            }`}</StyledValue>
          ))
        ) : (
          <StyledValue key={i} as={label ? 'dd' : 'li'}>{`${filter} ${
            i != filters.length - 1 ? '•' : ''
          }`}</StyledValue>
        )}
      </React.Fragment>
    ))}
  </StyledSearchFiltersQueryGroup>
)
