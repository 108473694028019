import React from 'react'

export const EELink = <C extends React.ElementType = 'a'>({
  as,
  children,
  ...restProps
}: {
  as?: C
} & React.ComponentPropsWithoutRef<C>): JSX.Element => {
  const Component = as || 'a'

  return <Component {...restProps}>{children}</Component>
}
