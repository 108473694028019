import React, { FC, createContext, useContext, ReactNode } from 'react'

export interface Location {
  pathname: string
  search: string
  hash: string
}

export interface IExpertEcosystemContext {
  router: IExpertEcosystemContextRouter
  EELoadingComponent?: React.FC<{
    message?: string
    timeout?: number
    zIndex?: number
    bgOpacity?: number
    isAbsolutePosition?: boolean
  }>
  token?: string
}

export const ExpertEcosystemContext = createContext<IExpertEcosystemContext | null>(null)

export const useExpertEcosystemContext = (): IExpertEcosystemContext | null => useContext(ExpertEcosystemContext)

export interface IExpertEcosystemContextRouter {
  location: Location
  push: (path: string) => void
  replace: (path: string) => void
  Link: React.ReactNode
  query?: Record<string, string | string[] | unknown>
  expertsBasePath?: string
  linkBasePath?: string
  saveQueryParameters?: (search: string) => void
}
interface ScientificWrapperProps {
  children: ReactNode
  value: IExpertEcosystemContext
}

const ScientificWrapper: FC<ScientificWrapperProps> = ({ children, value }) => (
  <ExpertEcosystemContext.Provider value={value}>{children}</ExpertEcosystemContext.Provider>
)

export default ScientificWrapper
