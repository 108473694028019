import { ProductTileCard, ProductTileLink, ProductTileName } from './styles'
import { Product } from '~hooks/use-available-products/Product'
import { TrackEventFunction } from '~hooks/use-google-analytics'

export interface IProductTileProps {
  product: Product
  isExceptionalClientUI: boolean
  trackEvent?: TrackEventFunction | undefined
}

export function ProductTile(props: IProductTileProps) {
  const { product, isExceptionalClientUI, trackEvent } = props

  const onTileClick = () => {
    if (!trackEvent) {
      return
    }
    trackEvent({
      name: 'product_select',
      label: product.name,
      data: [[`dimension4`, 'product_name', product.name]],
    })
  }

  return (
    <ProductTileLink onClick={onTileClick} href={product.url}>
      <ProductTileCard title={''} isExceptionalClientUI={isExceptionalClientUI}>
        {product.icon}
        <ProductTileName>{product.description}</ProductTileName>
      </ProductTileCard>
    </ProductTileLink>
  )
}
