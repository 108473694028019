import React from 'react'
import { Controller } from 'react-hook-form'
import { ControlledProps } from '../types'
import { CheckboxContained, CheckboxContainedProps } from '~components/ui/molecules/checkbox-contained'

export type ControlledCheckboxContainedProps<TFormValues> = ControlledProps<TFormValues> & CheckboxContainedProps

export const ControlledCheckboxContained = <TFormValues extends Record<string, unknown>>({
  name,
  control,
  rules = {},
  errors,
  ...restProps
}: ControlledCheckboxContainedProps<TFormValues>): JSX.Element => {
  return (
    <Controller
      rules={rules}
      control={control}
      name={name}
      defaultValue={restProps.defaultChecked}
      render={({ field }) => (
        <>
          <CheckboxContained
            isChecked={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            {...restProps}
            name={name}
          />
          {errors?.[name] && <>{errors?.[name]?.message}</>}
        </>
      )}
    />
  )
}
