import * as React from 'react'
import { useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { useNavigate } from 'react-router-dom'
import { paths } from '~helpers'

export function Logout() {
  const navigate = useNavigate()

  useEffect(() => {
    Auth.signOut().then(() => {
      navigate(paths.home)
    })
  }, [navigate])

  return <></>
}
