import { SelectWithCheckboxesDropdown } from './components/SelectWithCheckboxesDropdown'
import { SelectWithSystemDropdown, SelectDefaultOption } from './components/SelectWithSystemDropdown'
import { SelectWithSearchFiltersOptionsDropdown } from './components/SelectWithSearchFiltersOptionsDropdown'
import { SelectWithFilteredOptionsDropdown } from './components/SelectWithFilteredOptionsDropdown'

export * from './components/SelectWithCheckboxesDropdown'
export * from './components/SelectWithSystemDropdown'
export * from './components/SelectWithSearchFiltersOptionsDropdown'
export * from './components/SelectWithFilteredOptionsDropdown'

export const chevronSize = {
  xs: {
    size: '10',
    dValue: 'M0.75 4.05L1.8 3L6 7.2L10.2 3L11.25 4.05L6 9.3L0.75 4.05Z',
  },
  sm: {
    size: '12',
    dValue: 'M0.75 4.05L1.8 3L6 7.2L10.2 3L11.25 4.05L6 9.3L0.75 4.05Z',
  },
  md: {
    size: '16',
    dValue: 'M1 5.4L2.4 4L8 9.6L13.6 4L15 5.4L8 12.4L1 5.4Z',
  },
}

export const Select = {
  WithSystemDropdown: SelectWithSystemDropdown,
  WithCheckboxesDropdown: SelectWithCheckboxesDropdown,
  WithSearchFiltersOptionsDropdown: SelectWithSearchFiltersOptionsDropdown,
  WithFilteredOptionsDropdown: SelectWithFilteredOptionsDropdown,
  Option: SelectDefaultOption,
}
