import React from 'react'
import { FormItem, FormItemSize, FormItemLabelPosition } from '~components/ui/atoms/form-item'
import { StyledDefaultSelect } from './styles'

export interface SelectWithSystemDropdownProps extends React.HTMLAttributes<HTMLSelectElement> {
  id: string
  selectDropdownSize: FormItemSize
  value?: string
  labelPosition?: FormItemLabelPosition
  label?: string
  isDisabled?: boolean
}

export const SelectWithSystemDropdown: React.FC<SelectWithSystemDropdownProps> = ({
  id,
  placeholder,
  label,
  selectDropdownSize = 'sm',
  labelPosition = 'top',
  isDisabled,
  children,
  style,
  value,
  onChange,
  className,
  ...rest
}) => (
  <FormItem
    className={className}
    formItemSize={selectDropdownSize}
    labelPosition={labelPosition}
    style={style}
    htmlFor={`select-${id}`}
    label={label}
  >
    <StyledDefaultSelect
      selectDropdownSize={selectDropdownSize}
      disabled={isDisabled}
      aria-label={id}
      placeholder={placeholder}
      id={`select-${id}`}
      value={value}
      onChange={onChange}
      {...rest}
    >
      {children}
    </StyledDefaultSelect>
  </FormItem>
)

export interface SelectDefaultOptionProps {
  value: string
  disabled?: boolean
  hidden?: boolean
  selected?: boolean
  ariaLabel?: string
}

export const SelectDefaultOption: React.FC<SelectDefaultOptionProps> = ({
  value,
  disabled,
  selected,
  hidden,
  children,
  ariaLabel,
}) => {
  return (
    <option value={value} selected={selected} disabled={disabled} hidden={hidden} aria-label={ariaLabel}>
      {children}
    </option>
  )
}
