import React from 'react'
import styled from 'styled-components'
import { CustomSVG } from '~components/ui/nucleons/custom-svg'

type Position = 'left' | 'middle' | 'right'
type Rotation = 'right-bottom' | 'top-bottom'

const pathDValues = {
  rightBottom: 'M4.05 11.25L3 10.2L7.2 6L3 1.8L4.05 0.75L9.3 6L4.05 11.25Z',
  topBottom: 'M0.75 4.05L1.8 3L6 7.2L10.2 3L11.25 4.05L6 9.3L0.75 4.05Z',
}

export const StyledChevron = styled(CustomSVG)<{ position: Position }>`
  fill: ${({ theme }) => theme.colors.neutrals.neutral80};
  display: inline-block;
  vertical-align: bottom;
  flex-shrink: 0;
  ${({ position }) => {
    if (position === 'left') return `margin-right: 0.25rem`
    if (position === 'right') return `margin-left: 0.25rem`
    if (position === 'middle') return `margin: 0 0.25rem`
  }}
`

export const StyledChevronPath = styled.path<{ isOpened: boolean; rotation: Rotation }>`
  transform: ${({ isOpened, rotation }) => {
    if (rotation === 'top-bottom') {
      return isOpened ? 'rotate(180deg)' : 'rotate(0)'
    }
    if (rotation === 'right-bottom') {
      return isOpened ? 'rotate(90deg)' : 'rotate(0)'
    }
  }};
  transition: transform 0.48s ease-in-out;
  transform-origin: 50% 50%;
`

export interface ChevronIconProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  width?: string
  height?: string
  isOpened?: boolean
  position?: Position
  rotation?: Rotation
  pathDValue?: string
}

export const ChevronIcon = ({
  width = '12',
  height = '12',
  className,
  isOpened = false,
  position = 'left',
  pathDValue,
  rotation = 'right-bottom',
}: ChevronIconProps): JSX.Element => {
  return (
    <StyledChevron width={width} height={height} className={className} position={position}>
      <StyledChevronPath
        isOpened={isOpened}
        fillRule="evenodd"
        clipRule="evenodd"
        d={pathDValue || (rotation === 'right-bottom' ? pathDValues.rightBottom : pathDValues.topBottom)}
        rotation={rotation}
      />
    </StyledChevron>
  )
}
