import React from 'react'
import { Controller } from 'react-hook-form'
import { Textarea, TextareaProps } from '~components/ui/molecules/textarea'
import { ControlledProps } from '../types'

export interface ControlledTextareaProps<TFormValues> extends ControlledProps<TFormValues>, TextareaProps {
  name: string
}

export const ControlledTextarea = <TFormValues extends Record<string, unknown>>({
  name,
  control,
  label,
  errors,
  placeholder,
  rules = {},
  defaultValue = '',
  ...rest
}: ControlledTextareaProps<TFormValues>): JSX.Element => (
  <Controller
    defaultValue={defaultValue}
    rules={rules}
    control={control}
    name={name}
    render={({ field }) => (
      <Textarea
        {...rest}
        labelPosition={rest.labelPosition}
        label={label}
        placeholder={placeholder}
        onChange={field.onChange}
        onBlur={field.onBlur}
        id={name}
        value={field.value ?? defaultValue}
        error={{
          isError: !!errors?.[name],
          messages: errors?.[name]?.types
            ? Object.values(errors?.[name]?.types)
            : errors?.[name]?.message
            ? [errors?.[name]?.message]
            : undefined,
        }}
      />
    )}
  />
)
