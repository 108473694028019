import styled from 'styled-components'
import { Flex, StyledSection } from '~common'

export const ResetPasswordTemplateContainer = styled(Flex)`
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: row;
  overflow: auto;
`

export const StyledLogoSection = styled(StyledSection)`
  margin-bottom: 3.6em;
`

export const Row = styled.div`
  display: flex;
  flex-flow: wrap;
  position: absolute;
  top: 5%;
  padding-bottom: 150px;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
    top: 5%;
  }

  @media only screen and (max-height: 600px) and (orientation: landscape) {
    top: 3%;
  }
`

export const Column = styled.div`
  flex: 50%;
`

export const ColumnFullWidth = styled.div`
  flex: 100%;
`
