import React from 'react'
import { FormItem } from '~components/ui/atoms/form-item'
import { StyledCheckboxLabel, StyledHiddenCheckbox, StyledCheckbox, StyledCheckIcon } from './styles'

export type LabelPosition = 'left' | 'right'

export type Variant = 'primary' | 'secondary'

export type CheckBoxSize = 'sm' | 'md' | 'lg'

export type CheckboxProps = React.HTMLAttributes<HTMLInputElement> & {
  id: string
  isChecked?: boolean
  value?: string
  labelPosition?: LabelPosition
  checkboxSize?: CheckBoxSize
  label?: string
  isDisabled?: boolean
  name?: string
  variant?: Variant
}

export const Checkbox = ({
  id,
  name,
  label,
  style,
  value,
  variant = 'primary',
  isChecked = false,
  onChange,
  labelPosition = 'right',
  checkboxSize = 'sm',
  isDisabled = false,
  tabIndex,
  ...rest
}: CheckboxProps): JSX.Element => (
  <FormItem formItemSize={checkboxSize} style={style} {...rest}>
    <StyledCheckboxLabel
      isChecked={isChecked}
      isDisabled={isDisabled}
      labelPosition={labelPosition}
      variant={variant}
      as="label"
      htmlFor={`checkbox-${id}`}
    >
      {label && <span>{label}</span>}
      <StyledHiddenCheckbox
        id={`checkbox-${id}`}
        checked={isChecked}
        type="checkbox"
        value={value}
        name={name}
        onChange={onChange}
        disabled={isDisabled}
        aria-label={name}
        tabIndex={tabIndex}
      />
      <StyledCheckbox
        labelPosition={labelPosition}
        checkboxSize={checkboxSize}
        isChecked={isChecked}
        isDisabled={isDisabled}
      >
        <StyledCheckIcon variant={variant} checkboxSize={checkboxSize} />
      </StyledCheckbox>
    </StyledCheckboxLabel>
  </FormItem>
)
