import React from 'react'
import { FormItem } from '~components/ui/atoms/form-item'
import { StyledRadioLabel, StyledHiddenRadio, StyledRadio, StyledCheckIcon } from './styles'

export type LabelPosition = 'left' | 'right'
export type RadioSize = 'sm' | 'md'

export type RadioProps = React.HTMLAttributes<HTMLInputElement> & {
  id: string
  isChecked?: boolean
  value?: string
  labelPosition?: LabelPosition
  radioSize?: RadioSize
  label?: string
  isDisabled?: boolean
  name?: string
}

export const Radio = ({
  id,
  name,
  label,
  style,
  value,
  isChecked = false,
  onChange,
  labelPosition = 'right',
  radioSize = 'sm',
  isDisabled = false,
  tabIndex,
  ...rest
}: RadioProps): JSX.Element => (
  <FormItem formItemSize={radioSize} htmlFor={`radio-button-${id}`} style={style} {...rest}>
    <StyledRadioLabel
      isChecked={isChecked}
      isDisabled={isDisabled}
      labelPosition={labelPosition}
      aria-labelledby={`radio-button-${id}`}
    >
      {label && <span>{label}</span>}
      <StyledHiddenRadio
        id={`radio-button-${id}`}
        checked={isChecked}
        type="radio"
        value={value}
        name={name}
        onChange={onChange}
        disabled={isDisabled}
        aria-label={name}
        tabIndex={tabIndex}
      />
      <StyledRadio labelPosition={labelPosition} radioSize={radioSize} isChecked={isChecked} isDisabled={isDisabled}>
        <StyledCheckIcon radioSize={radioSize} />
      </StyledRadio>
    </StyledRadioLabel>
  </FormItem>
)
