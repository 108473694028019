import React, { useEffect, useRef, useState } from 'react'
import { StyledTooltipTip, StyledTooltipWrapper } from './styles'

export type Placement = 'top' | 'right' | 'bottom' | 'left'

export interface TooltipProps extends React.HTMLAttributes<HTMLDivElement> {
  content: string | React.ReactChild
  placement?: Placement
  delay?: number
}

export const Tooltip: React.FC<TooltipProps> = ({ children, placement = 'top', content, delay, ...rest }) => {
  const [tooltipHeight, setTooltipHeight] = useState(0)

  let timeout: NodeJS.Timeout
  const [active, setActive] = useState(false)
  const tooltipRef = useRef<HTMLDivElement>(null)

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true)
    }, delay || 200)
  }

  const hideTip = () => {
    clearInterval(timeout)
    setActive(false)
  }

  useEffect(() => {
    setTooltipHeight(tooltipRef?.current?.clientHeight || 0)
  }, [active])
  return (
    <StyledTooltipWrapper onMouseEnter={showTip} onMouseLeave={hideTip} {...rest}>
      {children}
      {content && active && (
        <StyledTooltipTip ref={tooltipRef} placement={placement} tooltipHeight={tooltipHeight}>
          {content}
        </StyledTooltipTip>
      )}
    </StyledTooltipWrapper>
  )
}

export default Tooltip
