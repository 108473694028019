import { useState, useEffect, useCallback, useContext } from 'react'
import { useConfig } from '~hooks/use-config'
import { UserContext } from '~contexts/UserContext'

/**
 * userGoogleAnalyticsTracker is a react hook that tracks the user's login status and provides a "isLoggedIn" flag that can be checked in code.
 */
export interface GoogleAnalyticsUserInfo {
  clientId?: number
}

export interface GoogleAnalyticsTracker {
  page_title?: string
  page_path?: string
  page_location?: string
  user_id?: string
  client_id?: number
}

export type TrackEventFunction = (opts: { name: string; label?: string; data?: string[][] }) => void

export interface GoogleAnalytics {
  setConfig: (tracker: GoogleAnalyticsTracker) => void
  trackEvent: TrackEventFunction
}

const defaultTitle = 'Loading | Pharmaspectra'

function useGoogleAnalytics(
  userInfo: GoogleAnalyticsUserInfo,
  trackerOverride?: GoogleAnalyticsTracker,
): GoogleAnalytics {
  const config = useConfig()

  const { clientId } = userInfo

  const { user, loading, userAttributes } = useContext(UserContext)

  let [override, setOverride] = useState<GoogleAnalyticsTracker | undefined>(trackerOverride)

  const [customMap, setCustomMap] = useState([] as string[][])

  useEffect(() => {
    if (loading) return

    const trackers = [config.GOOGLE_ANALYTICS_UA_ID, config.GOOGLE_ANALYTICS_GA4_ID]

    const titleElement = document.querySelector('head > title')

    if (titleElement) {
      if (titleElement.textContent !== defaultTitle) {
        if (customMap.length > 0 && user) {
          const track: any = {
            // https://developers.google.com/analytics/devguides/collection/gtagjs/pages#default_behavior
            page_title: titleElement.textContent,
            page_path: global.location.pathname,
            page_location: global.location.href,
            user_id: user?.username,
            ...(override ?? {}),
          }

          const gtagCustomMap: any = {}

          customMap.forEach((_customMap: any) => {
            track[_customMap[1]] = _customMap[2]
            gtagCustomMap[_customMap[0]] = _customMap[1]
          })

          trackers.forEach((tracker) => {
            gtag('set', {
              custom_map: gtagCustomMap,
            })
            gtag('config', tracker, track)
          })
        }
      }
    }
  }, [override, loading, config, customMap, user])

  useEffect(() => {
    if (clientId && userAttributes) {
      const { email } = userAttributes
      if (typeof email === 'string') {
        setCustomMap([
          ['dimension1', 'client_id', `${clientId}`],
          ['dimension2', 'client_name', email.split('@').pop()?.split('.').reverse()[1] ?? 'unknown'],
          [
            'dimension3',
            'authentication_type',
            userAttributes?.identities && userAttributes?.identities?.length
              ? userAttributes.identities[0].providerName
              : 'none',
          ],
        ])
      }
    }
  }, [clientId, userAttributes])

  const trackEvent = useCallback(
    ({ name, label, data }: { name: string; label?: string; data?: string[][] }) => {
      let _customMap: string[][] = [...customMap]

      const track: any = {}

      if (data) {
        _customMap = [..._customMap, ...data]
      }

      const gtagCustomMap: any = {}

      _customMap.forEach((cm: any) => {
        track[cm[1]] = cm[2]
        gtagCustomMap[cm[0]] = cm[1]
      })

      gtag('set', {
        custom_map: gtagCustomMap,
      })

      gtag('event', name, { ...{ event_label: label }, ...track })
    },
    [customMap],
  )

  const setConfig = useCallback(
    (tracker?: GoogleAnalyticsTracker) => {
      if (JSON.stringify(tracker) !== JSON.stringify(override)) {
        setOverride(tracker)
      }
    },
    [setOverride, override],
  )

  return { setConfig, trackEvent }
}

export default useGoogleAnalytics
