import React from 'react'
import { MissingFileSvg } from '~common/svgs'

import { StyledErrorPageBlock } from './styles'

export const NoRoute = (): JSX.Element => (
  <StyledErrorPageBlock
    errorMessage="Either the page doesn’t exist or you do not have access to it"
    icon={<MissingFileSvg />}
  />
)
