import React from 'react'
import styled from 'styled-components'

const StyledText = styled.p<{ textColor: 'primary' | 'secondary' }>`
  color: ${({ theme, textColor }) => {
    if (textColor === 'primary') return theme.colors.text.textPrimary
    if (textColor === 'secondary') return theme.colors.text.textSecondary
  }};
`

export interface TextProps extends React.HTMLAttributes<HTMLParagraphElement> {
  children: React.ReactNode
  color?: 'primary' | 'secondary'
}

export const Text = ({ children, color = 'primary', ...rest }: TextProps): JSX.Element => (
  <StyledText textColor={color} {...rest}>
    {children}
  </StyledText>
)
