import React, { RefObject } from 'react'
import { ButtonWithNoStyle } from '~common'
import { MyAccountList } from '~components/header/my-account-list'
import { CustomSVG } from '~shared/ui/nucleons/custom-svg'
import {
  StyledMyAccountSubMenu,
  StyledHamburgerTopBunPath,
  StyledHamburgerFillingPath,
  StyledHamburgerBottomBunPath,
  StyledNavigationList,
  StyledNavigation,
} from './styles'
import { NavigationItem } from '~components/header/navigation-item'

interface GlobalNavigationProps {
  isSubMenuOpened: boolean
  onSubMenuToggle: (isSubMenuOpened: boolean) => void
  subMenuRef: RefObject<HTMLLIElement>
  onLogout: () => void
  isMobileView: boolean
  isTouchable: boolean
}

export const GlobalNavigation = ({
  isSubMenuOpened,
  onSubMenuToggle,
  subMenuRef,
  onLogout,
  isMobileView,
  isTouchable,
}: GlobalNavigationProps): JSX.Element => {
  return (
    <StyledNavigation role="navigation" aria-label="Pharmaspectra">
      <StyledNavigationList aria-label="tertiary navigation list">
        <NavigationItem isTouchable={isTouchable} navigationItemRef={subMenuRef} isActive={isSubMenuOpened}>
          <>
            <ButtonWithNoStyle
              type="button"
              aria-label="My account sub menu"
              aria-haspopup={true}
              aria-expanded={isSubMenuOpened}
              onClick={() => {
                isSubMenuOpened ? onSubMenuToggle(false) : onSubMenuToggle(true)
              }}
            >
              <HamburgerSVG isOpened={isSubMenuOpened} />
              Menu
            </ButtonWithNoStyle>
            <StyledMyAccountSubMenu
              isOpen={isSubMenuOpened}
              onFocus={() => onSubMenuToggle(true)}
              onBlur={() => onSubMenuToggle(false)}
              aria-label="My account"
              aria-hidden={isMobileView || !isSubMenuOpened}
              tabIndex={-1}
            >
              <MyAccountList isMyAccountListVisible={isSubMenuOpened} onLogout={onLogout} isTouchable={false} />
            </StyledMyAccountSubMenu>
          </>
        </NavigationItem>
      </StyledNavigationList>
    </StyledNavigation>
  )
}

export const HamburgerSVG = ({ isOpened }: { isOpened?: boolean }): JSX.Element => (
  <CustomSVG>
    <title>Menu</title>
    <StyledHamburgerTopBunPath d="M0 1H16V3H0V1Z" isOpened={isOpened} />
    <StyledHamburgerFillingPath d="M0 7H16V9H0V7Z" isOpened={isOpened} />
    <StyledHamburgerBottomBunPath d="M0 13H16V15H0V13Z" isOpened={isOpened} />
  </CustomSVG>
)
