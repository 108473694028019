import styled, { css } from 'styled-components'
import { Placement } from './Tooltip'

export const StyledTooltipWrapper = styled.div`
  display: inline-block;
  position: relative;
  padding: 0.5rem;
`

export const StyledTooltipTip = styled.div<{ placement: Placement; tooltipHeight: number }>`
  ${({ theme }) => theme.textSizes.desktop.label};
  color: ${({ theme }) => theme.colors.textOn.textOnInverseSecondary};
  background: ${({ theme }) => theme.colors.dataVisualisation.tooltipBackground};
  position: absolute;
  border-radius: 0.09375rem;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5rem;
  line-height: 1;
  white-space: nowrap;
  ${({ placement, tooltipHeight }) => {
    if (placement === 'top') {
      return css`
        top: calc(${tooltipHeight}px * -1);
      `
    }
    if (placement === 'right') {
      return css`
        left: 100%;
        top: 50%;
        transform: translateX(0) translateY(-50%);
      `
    }
    if (placement === 'bottom') {
      return css`
        bottom: calc(${tooltipHeight}px * -1);
      `
    }

    if (placement === 'left') {
      return css`
        left: auto;
        right: 100%;
        top: 50%;
        transform: translateX(0) translateY(-50%);
      `
    }
  }}
  &:before {
    content: ' ';
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 0.375rem;
    margin-left: calc(0.375rem * -1);
    ${({ placement }) => {
      if (placement === 'top') {
        return css`
          top: 100%;
          border-top-color: ${({ theme }) => theme.colors.dataVisualisation.tooltipBackground};
        `
      }
      if (placement === 'right') {
        return css`
          right: auto;
          left: calc(0.375rem * -1);
          top: 50%;
          transform: translateX(0) translateY(-50%);
          border-right-color: ${({ theme }) => theme.colors.dataVisualisation.tooltipBackground};
        `
      }
      if (placement === 'bottom') {
        return css`
          bottom: 100%;
          border-bottom-color: ${({ theme }) => theme.colors.dataVisualisation.tooltipBackground};
        `
      }

      if (placement === 'left') {
        return css`
          left: auto;
          right: calc(0.375rem * -2);
          top: 50%;
          transform: translateX(0) translateY(-50%);
          border-left-color: ${({ theme }) => theme.colors.dataVisualisation.tooltipBackground};
        `
      }
    }}
  }
`
