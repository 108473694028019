import styled, { css } from 'styled-components'
import { Flex } from '~common'
import { BadgeProps } from './Badge'

export const StyledBadge = styled(Flex)<BadgeProps>`
  ${({ theme }) => theme.textSizes.desktop.label}
  padding: 0.25rem 0.375rem;
  width: max-content;
  transition: all 0.24s ease-in-out;
  color: ${({ theme, isSelected }) => (isSelected ? theme.colors.textOn.textOnPill : theme.colors.text.textSecondary)};
  & label {
    color: ${({ theme, isSelected }) =>
      isSelected ? theme.colors.textOn.textOnPill : theme.colors.text.textSecondary};
  }
  background: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.badges.badgeBackgroundActive : theme.colors.badges.badgeBackground};
  opacity: ${({ isDisabled }) => (isDisabled ? '0.4' : '1')};
  border: 1px solid ${({ theme }) => theme.colors.neutrals.neutral10};
  position: relative;
  border-radius: ${({ rounded }) => {
    if (rounded === 'allaround') return '2px'
    if (rounded === 'left') return '2px 0px 0px 2px'
    if (rounded === 'right') return '0px 2px 2px 0px'
  }};
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  & svg {
    fill: ${({ theme, isSelected }) => (isSelected ? theme.colors.textOn.textOnPill : theme.colors.text.textSecondary)};
  }
  &:hover,
  &:focus-visible {
    background: ${({ theme, isDisabled }) => !isDisabled && theme.colors.badges.badgeBackgroundHover};
    color: ${({ theme, isDisabled }) => !isDisabled && theme.colors.textOn.textOnPill};
    & label {
      color: ${({ theme, isDisabled }) => !isDisabled && theme.colors.textOn.textOnPill} !important;
    }
    & svg {
      fill: ${({ theme, isDisabled }) => !isDisabled && theme.colors.textOn.textOnPill};
    }
  }
`

export const StyledChildren = styled.span<{ iconPosition: 'left' | 'right' }>`
  ${({ iconPosition }) => css`
    margin-${iconPosition}: 0.25rem;
  `}
`

export const StyledFlex = styled(Flex)`
  align-items: center;
`
