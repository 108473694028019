import { useEffect, useState } from 'react'
import { checkInmemeRegister } from '~api/ssoProviderApi'
import { Logger } from '~helpers/Logger'

export const useCheckInmemeRegister = (): { isAllowed: boolean } => {
  const [isAllowed, setIsAllowed] = useState(false)

  useEffect(() => {
    checkInmemeRegister()
      .then((response) => {
        setIsAllowed(response.isAllowed)
      })
      .catch((error) => {
        Logger.error(error)
      })
  }, [setIsAllowed])

  return {
    isAllowed,
  }
}
