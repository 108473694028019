import { useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import { Logger } from '~helpers/Logger'

export interface Tokens {
  idToken: string
  accessToken: string
  refreshToken: string
}

export function useToken(): Tokens {
  const [idToken, setIdToken] = useState<string>('')
  const [accessToken, setAccessToken] = useState<string>('')
  const [refreshToken, setRefreshToken] = useState<string>('')

  useEffect(() => {
    Auth.currentSession()
      .then((sess) => {
        setIdToken(sess.getIdToken().getJwtToken())
        setAccessToken(sess.getAccessToken().getJwtToken())
        setRefreshToken(sess.getRefreshToken().getToken())
      })
      .catch((err) => Logger.error(err))
  }, [setIdToken, setAccessToken, setRefreshToken])

  return {
    idToken,
    accessToken,
    refreshToken,
  }
}
