import React from 'react'
import styled, { css } from 'styled-components'
import { Badge } from '~components/ui/atoms/badge'
import { Checkbox, CheckboxProps } from '~components/ui/atoms/checkbox'

const StyledBadge = styled(Badge)<{ denominationColor?: string }>`
  padding-top: 0;
  padding-bottom: 0;
  min-height: 1.625rem;
  margin-left: ${({ denominationColor }) => (denominationColor ? '1rem' : '0')};
  ${({ denominationColor }) => {
    if (denominationColor) {
      return css`
        &:before {
          content: '';
          position: absolute;
          left: -18px;
          top: 0;
          bottom: 0;
          margin: auto 0;
          border: 1px solid ${({ theme }) => theme.colors.neutrals.neutral20};
          width: 12px;
          height: 12px;
          background: ${denominationColor};
        }
      `
    }
  }}
`

export type CheckboxContainedProps = CheckboxProps & {
  denominationColor?: string
}

export const CheckboxContained = ({
  variant = 'secondary',
  denominationColor,
  ...rest
}: CheckboxContainedProps): JSX.Element => (
  <StyledBadge isSelected={rest.isChecked || false} isDisabled={rest.isDisabled} denominationColor={denominationColor}>
    <Checkbox {...rest} variant={variant} />
  </StyledBadge>
)
