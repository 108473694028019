import { InsightmemeIconSvg } from '~shared/common/svgs'
import { Product } from './Product'
import { Config } from '../../common/types'

export class InsightmemeProductBuilder {
  public static build(input: { idToken: string; config: Config; username: string; email: string }): Product {
    const { idToken, config, email } = input
    return {
      name: 'InsightMeme',
      description: 'Congress and Journal abstracts',
      url: `${config.INSIGHTMEME_URL}/default.aspx?username=${email}&linkfrom=MedmemeProducts&clienturl=${config.RETURN_URL}&id_token=${idToken}`,
      icon: <InsightmemeIconSvg />,
      isOutsideIPRange: false,
    }
  }
}
