import styled, { css } from 'styled-components'
import { TBorderColor, TBorderDirection, TDividerType } from './types'
import { Theme } from '~common/theme'

const getColors = (theme: Theme, borderColor: TBorderColor): string => {
  const borderColors: Record<TBorderColor, string> = {
    warning: theme.colors.detail.dividerPrimary,
    muted: theme.colors.neutrals.neutral20,
  }
  return borderColors[borderColor]
}

export const StyledDivider = styled.hr<{
  borderDirection?: TBorderDirection
  borderType: TDividerType
  borderColor: TBorderColor
  isWithMargin: boolean
}>`
  margin: ${({ borderDirection, isWithMargin }) =>
    isWithMargin && (borderDirection === 'horizontal' ? '0.5rem 0' : '0 0.5rem')};
  border-style: none;
  height: auto;
  ${({ borderDirection, borderType, borderColor, theme }) =>
    css`
      border-${borderDirection === 'horizontal' ? 'top' : 'right'}: 1px ${borderType} ${getColors(theme, borderColor)};
  `}
`
