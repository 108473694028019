import { useEffect, useState, useContext } from 'react'
import { useConfig } from '~hooks/use-config'
import { HeliosProductBuilder } from './HeliosProductBuilder'
import { InsightmemeProductBuilder } from './InsightmemeProductBuilder'
import { PharmaspectraPlaftormProductBuilder } from './PharmaspectraPlaftormProductBuilder'
import { Product } from './Product'
import { ProfileMemeProductBuilder } from './ProfileMemeProductBuilder'
import { useToken } from '~hooks/use-token'
import { KwelloProductBuilder } from './KwelloProductBuilder'
import { UserContext } from '~contexts/UserContext'

export const useAvailableProducts = (): Product[] => {
  const config = useConfig()
  const [products, setProducts] = useState<Product[]>([])
  const { idToken } = useToken()
  const { user: cognitoUser, userAttributes } = useContext(UserContext)

  useEffect(() => {
    if (!cognitoUser || !config || !idToken) {
      return
    }

    const newProducts = []
    const input = {
      config,
      username: cognitoUser.username,
      email: userAttributes?.email || '',
      idToken,
    }

    newProducts.push(InsightmemeProductBuilder.build(input))
    newProducts.push(PharmaspectraPlaftormProductBuilder.build(input))
    newProducts.push(ProfileMemeProductBuilder.build(input))
    newProducts.push(HeliosProductBuilder.build(input))
    newProducts.push(KwelloProductBuilder.build())

    setProducts(newProducts)
  }, [config, setProducts, idToken, cognitoUser, userAttributes])

  return products
}
