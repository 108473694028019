import { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { Hub, HubCapsule } from '@aws-amplify/core'
import { CognitoUser } from 'amazon-cognito-identity-js'
import { Logger } from '~helpers/Logger'
import { UserAttributes } from '~contexts/UserContext'

/**
 * userUserStatus is a react hook that tracks the user's login status and provides a "isLoggedIn" flag that can be checked in code.
 */
export interface UserStatus {
  user?: CognitoUser & { username: string }
  userAttributes?: UserAttributes
  loading: boolean
}

function useUserStatus(): UserStatus {
  let [user, setUser] = useState<CognitoUser & { username: string }>()
  let [userAttributes, setUserAttributes] = useState<UserAttributes>()

  const [loading, setLoading] = useState(false)
  const [fetchingAttributes, setFetchingAttributes] = useState(false)

  useEffect(() => {
    let initUser = async () => {
      setLoading(true)
      return new Promise<void>((initialized) => {
        Auth.currentAuthenticatedUser()
          .then((u) => {
            setUser({ ...u, username: u.getUsername() })
          })
          .catch((e) => {
            Logger.error(e)
            setUser(undefined)
          })
          .finally(() => {
            setLoading(false)
            initialized()
          })
      })
    }

    const handleHubEvent = (event: HubCapsule) => {
      const {
        payload: { event: type, data: hubUser },
      } = event

      switch (type) {
        case 'signIn':
          initUser()
          break

        case 'signOut':
          setUser(undefined)
          break

        default:
          if (!hubUser) {
            setUser(undefined)
          }
          break
      }
    }

    // we are not using async to wait for initUser, so there will be a flash of page where the user is assumed not to be logged in. If we use a flag
    // check manually the first time because we won't get a Hub event
    // If we have initUser return a promise we can then asign listener after user was initialized.
    initUser().finally(() => {
      Hub.listen('auth', handleHubEvent) // listen for login/signup events
    })
    return () => Hub.remove('auth', handleHubEvent) // cleanup
  }, [setUser, setLoading])

  useEffect(() => {
    if (!user) {
      return
    }

    const userAttributes = (user as any).attributes
    if (userAttributes) {
      if (userAttributes.identities) {
        userAttributes.identities = JSON.parse(userAttributes.identities)
        userAttributes.ssoProvider =
          userAttributes.identities && userAttributes.identities.length && userAttributes.identities[0].providerName
      }
      setUserAttributes((user as any).attributes)
      return
    }

    if (user && user.getUserAttributes) {
      setFetchingAttributes(true)
      user?.getUserAttributes((err, attrs) => {
        if (err) {
          Logger.error(err)
        }
        if (!attrs) return
        const attributeObj: any = attrs.reduce((obj, item) => Object.assign(obj, { [item.Name]: item.Value }), {})
        if (attributeObj.identities) {
          attributeObj.identities = JSON.parse(attributeObj.identities)

          attributeObj.ssoProvider =
            attributeObj.identities && attributeObj.identities.length && attributeObj.identities[0].providerName
        }
        setUserAttributes(attributeObj)
        setFetchingAttributes(false)
      })
    }
  }, [user, setFetchingAttributes, setUserAttributes])

  return { user, userAttributes, loading: loading || fetchingAttributes }
}

export default useUserStatus
