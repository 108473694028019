import React, { createContext, FC, ReactNode, useCallback, useContext, useMemo, useState } from 'react'
import qs from 'qs'
import { SearchPill } from '~common'
import { isExpertEcosystemRouter, useRouter } from '~common/hooks'

export interface ShallowRouterContextInterface {
  query: Record<string, string | string[] | unknown>
  shallowRedirect: (url: string) => void
  getQuery: () => Record<string, string | string[] | unknown>
}

const ShallowRouterContext = createContext<ShallowRouterContextInterface | null>(null)

export type ShallowRoutingProvider = {
  children: ReactNode
}

export const getCurrentShallowQuery = (
  location?: Location | URL | undefined,
): Record<string, string | string[] | unknown> => {
  if (!location) {
    if (typeof global !== 'undefined' && global.location) {
      location = global.location
    } else if (typeof window !== 'undefined' && window.location) {
      location = window.location
    }
  }

  if (location) {
    const queryStrings = qs.parse(location.search, { ignoreQueryPrefix: true })

    if (queryStrings.filters) {
      const filters: SearchPill[] = queryStrings.filters as unknown as SearchPill[]

      if (filters) {
        filters.forEach((filter: SearchPill, index: number) => {
          queryStrings[`filters[${index}][category]`] = filter.category
          queryStrings[`filters[${index}][value]`] = filter.value
          if (filter.synonyms) {
            queryStrings[`filters[${index}][synonyms]`] = filter.synonyms
          }
        })
        delete queryStrings.filters
      }
    }

    if (location.pathname.includes('expert-ecosystem/experts/expert-profile/scientific/')) {
      queryStrings.id = location.pathname.split('/')[8]
    }

    return location.search ? queryStrings : {}
  }
  return {}
}

export const pushShallowHistoryEntry = (url: string, replace = false): boolean => {
  let history: History | null = null
  if (typeof global !== 'undefined' && global.history) {
    history = global.history
  } else if (typeof window !== 'undefined' && window.history) {
    history = window.history
  }

  if (history) {
    replace ? history.replaceState(history.state, '', url) : history.pushState(history.state, '', url)
    return true
  }

  return false
}

const getCurrentSearchString = (): string => {
  if (typeof global !== 'undefined' && global.location) {
    return global.location.search
  } else if (typeof window !== 'undefined' && window.location) {
    return window.location.search
  }

  return ''
}

export const ShallowRoutingStateProvider: FC<ShallowRoutingProvider> = ({ children }) => {
  const router = useRouter()
  const [queryParameters, setQueryParameters] = useState<Record<string, string | string[] | unknown>>(() =>
    getCurrentShallowQuery(),
  )

  const shallowRedirect = useCallback(
    (url: string) => {
      if (pushShallowHistoryEntry(url, true)) {
        const changedQuery = getCurrentShallowQuery()
        const currentSearchString = getCurrentSearchString()
        if (currentSearchString && router && isExpertEcosystemRouter(router)) {
          router.saveQueryParameters && router.saveQueryParameters(currentSearchString)
        }
        setQueryParameters((curr) => {
          if (JSON.stringify(curr) !== JSON.stringify(changedQuery)) {
            return changedQuery
          }
          return curr
        })
      }
    },
    [router],
  )

  const shallowContextState: ShallowRouterContextInterface = useMemo(
    () => ({
      query: queryParameters,
      shallowRedirect,
      getQuery: getCurrentShallowQuery,
    }),
    [queryParameters, shallowRedirect],
  )

  return <ShallowRouterContext.Provider value={shallowContextState}>{children}</ShallowRouterContext.Provider>
}

export const useShallowRoutingState = (): ShallowRouterContextInterface => {
  const context = useContext<ShallowRouterContextInterface | null>(ShallowRouterContext)
  if (context) {
    return context
  }

  return {
    getQuery: getCurrentShallowQuery,
    shallowRedirect: pushShallowHistoryEntry,
    query: getCurrentShallowQuery(),
  }
}
